import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useIntl } from 'react-intl';
import { Popover, Progress, Tag, Tooltip } from 'antd';
import { CheckSquareFilled, InfoCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Cell, Legend, Pie, PieChart, Sector } from 'recharts';
import { nowEpoch, timeFormatFromUTCEpoch, fileSizeTransform } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import AttachInfoPopover from 'components/branch/attach/AttachInfoPopover';
import Loading from 'components/common/Loading';
import {
  getInfectionPc,
  getInfectionTarget,
  changeInfectionTargetState,
  getEduHistory,
  getExamTag,
} from 'store/exam';
import { getTemplateTarget } from 'store/template';
import CountInfo from 'components/branch/exam/CountInfo';
import ListStep from 'components/common/ListStep';
import ExamListExtra from 'components/branch/exam/ExamListExtra';

// import reserveImage from 'img/exam/send_reserve.png';
// import openImage from 'img/tag/ico_open.png';
// import closeImage from 'img/tag/ico_close.png';

// import targetImage from 'img/exam/step/target.png';
// import sendImage from 'img/exam/step/send.png';
// import readImage from 'img/exam/step/read.png';
// import phishingImage from 'img/exam/step/phishing.png';
// import accessImage from 'img/exam/step/access.png';
// import downImage from 'img/exam/step/down.png';
// import infectionImage from 'img/exam/step/infection.png';
// import cureImage from 'img/exam/step/cure.png';

// import targetActiveImage from 'img/exam/step/target_a.png';
// import sendActiveImage from 'img/exam/step/send_a.png';
// import sendFailImage from 'img/exam/step/send_fail_a.png';
// import sendReserveImage from 'img/exam/step/send_reserve_a.png';
// import sendStopImage from 'img/exam/step/send_stop_a.png';
// import readActiveImage from 'img/exam/step/read_a.png';
// import phishingActiveImage from 'img/exam/step/phishing_a.png';
// import accessActiveImage from 'img/exam/step/access_a.png';
// import downActiveImage from 'img/exam/step/down_a.png';
// import infectionActiveImage from 'img/exam/step/infection_a.png';
// import cureActiveImage from 'img/exam/step/cure_a.png';

// import lineSendImage from 'img/exam/step/line_send.png';
// import lineReadImage from 'img/exam/step/line_read.png';
// import lineDownImage from 'img/exam/step/line_down.png';
// import lineInfectionImage from 'img/exam/step/line_infection.png';
// import lineCureImage from 'img/exam/step/line_cure.png';

// import circleTargetImage from 'img/exam/step/circle_target.png';
// import circleSendImage from 'img/exam/step/circle_send.png';
// import circleReadImage from 'img/exam/step/circle_read.png';
// import circleDownImage from 'img/exam/step/circle_down.png';
// import circleInfectionImage from 'img/exam/step/circle_infection.png';
// import circleCureImage from 'img/exam/step/circle_cure.png';

interface detailProps {
  detailType: string;
  detailData: any;
  attachExamType: any;
}

function ExamDetail({ detailType, detailData, attachExamType }: detailProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(false);

  const selectedExamNo = useSelector((state: any) => state.exam.examSummary.selectedExamNo);
  const selectedRandomGroupNo = useSelector(
    (state: any) => state.exam.examSummary.selectedRandomGroupNo,
  );
  const selectedList = useSelector((state: any) => state.exam.examList.selectedList);
  const infectionTarget = useSelector((state: any) => {
    return {
      data: state.exam.infectionTarget.data,
      param: state.exam.infectionTarget.param,
      page: state.exam.infectionTarget.page,
      totalPage: state.exam.infectionTarget.totalPage,
      openList: state.exam.infectionTarget.openList,
    };
  }, shallowEqual);
  const infectionPc = useSelector((state: any) => {
    return {
      data: state.exam.infectionPc.data,
      param: state.exam.infectionPc.param,
      page: state.exam.infectionPc.page,
      totalPage: state.exam.infectionPc.totalPage,
    };
  }, shallowEqual);

  const eduHistory = useSelector((state: any) => {
    return {
      data: state.exam.eduHistory.data,
      param: state.exam.eduHistory.param,
      page: state.exam.eduHistory.page,
      totalPage: state.exam.eduHistory.totalPage,
    };
  }, shallowEqual);

  const examTag = useSelector((state: any) => {
    return {
      data: state.exam.examTag.data,
      param: state.exam.examTag.param,
      page: state.exam.examTag.page,
      totalPage: state.exam.examTag.totalPage,
    };
  }, shallowEqual);

  // 훈련결과 템플릿
  const templateList = useSelector((state: any) => {
    return {
      data: state.template.resultTemplate.data,
      dataByTemplateNo: state.template.resultTemplate.dataByTemplateNo,
      param: state.template.resultTemplate.param,
      needReload: state.template.resultTemplate.needReload,
      page: state.template.resultTemplate.page,
      totalPages: state.template.resultTemplate.totalPages,
      selectedList: state.template.resultTemplate.selectedList,
    };
  }, shallowEqual);

  // 템플릿별 대상자
  const templateTargetList = useSelector((state: any) => {
    return {
      data: state.template.resultTemplateTarget.data,
      param: state.template.resultTemplateTarget.param,
      needReload: state.template.resultTemplateTarget.needReload,
      page: state.template.resultTemplateTarget.page,
      totalPages: state.template.resultTemplateTarget.totalPages,
      selectedList: state.template.resultTemplateTarget.selectedList,
    };
  }, shallowEqual);

  useEffect(() => {
    if (detailType === 'exam') {
      onGetEduHistory(true);
      onGetInfectionTarget(true);
      onGetInfectionPc(true);
    } else if (detailType === 'tag') {
      onGetExamTag(true);
      onGetInfectionTarget(true);
      onGetInfectionPc(true);
    } else if (detailType === 'bytemplate') {
      onGetTemplateTarget(true);
    }
  }, [selectedExamNo, selectedList, templateList.selectedList]);

  // 감염 대상 정보 조회
  const onGetInfectionTarget = async (refresh = false) => {
    if (!loading) {
      setLoading(true);

      try {
        const selectNo = selectedList[0];
        const examNo =
          selectedExamNo === 'all' && selectedList.length > 0 ? selectNo : selectedExamNo;

        const params: any = {
          examNo: examNo,
          filter: JSON.stringify(infectionTarget.param.filter),
          sort: JSON.stringify(infectionTarget.param.sort),
          offset: infectionTarget.param.limit * infectionTarget.page,
          limit: infectionTarget.param.limit,
        };

        if (refresh) {
          params.offset = 0;
          params.refresh = true;
        }

        if (selectedExamNo !== 'all' && selectedList.length > 0) {
          if (pathname === '/exam/pi') {
            params.targetNo = selectNo;
          } else if (pathname === '/exam/tag') {
            params.tagNo = selectNo;
          }
        }

        await dispatch(getInfectionTarget(params));
        setLoading(false);
      } catch (error) {
        console.log('ExamDetail onGetInfectionTarget', error);
      }
    }
  };

  // 감염 PC 정보 조회
  const onGetInfectionPc = async (refresh = false) => {
    if (!loading) {
      setLoading(true);

      try {
        const selectNo = selectedList[0];
        const examNo =
          selectedExamNo === 'all' && selectedList.length > 0 ? selectNo : selectedExamNo;

        const params: any = {
          examNo: examNo,
          filter: JSON.stringify(infectionPc.param.filter),
          sort: JSON.stringify(infectionPc.param.sort),
          offset: infectionPc.param.limit * infectionPc.page,
          limit: infectionPc.param.limit,
        };

        if (refresh) {
          params.offset = 0;
          params.refresh = true;
        }

        if (selectedExamNo !== 'all' && selectedList.length > 0) {
          if (pathname === '/exam/pi') {
            params.targetNo = selectNo;
          } else if (pathname === '/exam/tag') {
            params.tagNo = selectNo;
          }
        }

        await dispatch(getInfectionPc(params));
        setLoading(false);
      } catch (error) {
        console.log('ExamDetail onGetInfectionPc', error);
      }
    }
  };

  // 교육 이력 정보 조회
  const onGetEduHistory = async (refresh = false) => {
    if (!loading) {
      setLoading(true);
      try {
        const selectNo = selectedList[0];
        const examNo =
          selectedExamNo === 'all' && selectedList.length > 0 ? selectNo : selectedExamNo;
        const params: any = {
          examNo: examNo,
          filter: JSON.stringify(eduHistory.param.filter),
          sort: JSON.stringify(eduHistory.param.sort),
          offset: eduHistory.param.limit * eduHistory.page,
          limit: eduHistory.param.limit,
        };
        if (refresh) {
          params.offset = 0;
          params.refresh = true;
        }
        if (selectedExamNo !== 'all' && selectedList.length > 0) {
          if (pathname === '/exam/pi') {
            params.targetNo = selectNo;
          } else if (pathname === '/exam/tag') {
            params.tagNo = selectNo;
          }
        }
        await dispatch(getEduHistory(params));
        setLoading(false);
      } catch (error) {
        console.log('ExamDetail onGetEduHistory', error);
      }
    }
  };

  // 태그 정보 조회
  const onGetExamTag = async (refresh = false) => {
    if (!loading) {
      setLoading(true);
      try {
        const selectNo = selectedList[0];
        const params: any = {
          examNo: selectedExamNo,
          filter: JSON.stringify(examTag.param.filter),
          sort: JSON.stringify(examTag.param.sort),
          offset: examTag.param.limit * examTag.page,
          limit: examTag.param.limit,
          tagNo: selectNo,
        };

        if (refresh) {
          params.offset = 0;
          params.refresh = true;
        }

        await dispatch(getExamTag(params));
        setLoading(false);
      } catch (error) {
        console.log('ExamDetail onGetExamTag', error);
      }
    }
  };

  // 템플릿별 대상자 조회
  const onGetTemplateTarget = async (refresh = false) => {
    if (!loading) {
      setLoading(true);

      try {
        const params: any = {
          templateNo: templateList.selectedList[0]?.info?.templateNo,
          sort: templateTargetList.param.sort,
          offset: templateTargetList.param.limit * templateTargetList.page,
          limit: templateTargetList.param.limit,
        };

        if (refresh) {
          params.offset = 0;
          params.refresh = true;
        }

        await dispatch(getTemplateTarget(params));
        setLoading(false);
      } catch (error) {
        console.log('ExamDetail onGetTemplateTarget', error);
      }
    }
  };

  // 감염 PC 상세정보 열기/닫기
  const changeOpenInfection = (targetNo: number) => {
    const list = [...infectionTarget.openList];
    const index = list.indexOf(targetNo);
    if (list.length > 0 && index > -1) {
      list.splice(index, 1);
    } else {
      list.push(targetNo);
    }

    dispatch(changeInfectionTargetState({ key: 'openList', value: list }));
  };

  const isPhishing = detailData.examType === 4;
  let examStatus = (
    <div className="color-brand-green">
      <span className="bold">{formatMessage({ id: 'Status_10', defaultMessage: '양호' })}</span>
    </div>
  );

  if (detailData.infection > 0) {
    let infectionPercent: any = (detailData.infection / detailData.target) * 100;
    if (detailData.infection > 0 && infectionPercent === 0) {
      infectionPercent = 1;
    }

    examStatus = (
      <div className="color-red">
        <span className="bold">{formatMessage({ id: 'Status_11', defaultMessage: '위험' })}</span>
        <span className="font-size-12">{` (${
          isPhishing
            ? formatMessage({ id: 'Infection_43', defaultMessage: '정보 유출률' })
            : formatMessage({ id: 'Infection_2', defaultMessage: '감염률' })
        } ${infectionPercent.toFixed(1)}%)`}</span>
      </div>
    );
  } else if (detailData.download > 0) {
    examStatus = (
      <div className="color-red">
        <span className="bold">
          {isPhishing
            ? formatMessage({ id: 'Status_12', defaultMessage: '경고 (피싱 접속 대상자 있음)' })
            : formatMessage({
                id: 'Status_13',
                defaultMessage: '경고 (파일을 다운로드한 대상자 있음)',
              })}
        </span>
      </div>
    );
  } else if (detailData.read > 0) {
    examStatus = (
      <div className="color-orange">
        <span className="bold">
          {formatMessage({ id: 'Status_14', defaultMessage: '주의 (메일 열람 대상자 있음)' })}
        </span>
      </div>
    );
  }

  // 상태 프로그레스바
  let progress = 0;
  // 예약 훈련
  let isReserve = false;
  if (detailData.examStartEpoch > nowEpoch()) {
    isReserve = true;
  } else {
    progress = Math.round(
      ((nowEpoch() - Number(detailData.examStartEpoch)) /
        (Number(detailData.examEndEpoch) - Number(detailData.examStartEpoch))) *
        100,
    );

    if (progress < 1) {
      progress = 1;
    } else if (progress > 100) {
      progress = 100;
    }
  }

  // 훈련 유형 태그
  let examTypeComponent = null;
  if (detailData.attachData) {
    const { attachExamType } = detailData.attachData;

    if (detailData.examType === 1) {
      if (attachExamType === 1) {
        examTypeComponent = (
          <Tag className="temp-type-label color-temp-warning">
            {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
          </Tag>
        );
      } else if (attachExamType === 2) {
        examTypeComponent = (
          <Tag className="temp-type-label color-temp-file">
            {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
          </Tag>
        );
      } else if (attachExamType === 3) {
        examTypeComponent = (
          <Tag className="temp-type-label color-temp-research">
            {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
          </Tag>
        );
      }
    } else if (detailData.examType === 4) {
      examTypeComponent = (
        <Tag className="temp-type-label color-temp-phishing">
          {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
        </Tag>
      );
    }
  }

  const fileSize = fileSizeTransform(detailData.leakFileSize);
  const certSize = fileSizeTransform(detailData.leakCertSize);
  const emailSize = fileSizeTransform(detailData.leakEmailSize);

  // 감염 가능 파일
  const circleChartArray = [
    {
      name: '',
      color: '',
      value: detailData.leakAllcount > 0 ? 0 : 100,
      file: '',
      label: '',
    },
    {
      name: 'pc',
      color: '#43cbaa',
      value: detailData.leakFileCount,
      file: `${fileSize.size}${fileSize.unit}`,
      label: formatMessage({ id: 'Infection_30', defaultMessage: 'pc 파일' }),
    },
    {
      name: 'cert',
      color: '#7b3faf',
      value: detailData.leakCertCount,
      file: `${certSize.size}${certSize.unit}`,
      label: formatMessage({ id: 'Infection_31', defaultMessage: '인증서' }),
    },
    {
      name: 'email',
      color: '#6697da',
      value: detailData.leakEmailCount,
      file: `${emailSize.size}${emailSize.unit}`,
      label: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
    },
  ];

  // 감염 대상 상세정보 데이터
  const infectionInnerData = [
    {
      title: formatMessage({ id: 'Target_8', defaultMessage: '대상자 정보' }),
      info: [
        {
          key: 'targetNo',
          text: formatMessage({ id: 'Exam_69', defaultMessage: '번호' }),
        },
        {
          key: 'targetName',
          text: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
        },
        {
          key: 'targetEmail',
          text: formatMessage({ id: 'Exam_70', defaultMessage: '메일' }),
        },
        {
          key: 'targetDivision',
          text: formatMessage({ id: 'Division_1', defaultMessage: '소속' }),
        },
        {
          key: 'targetPosition',
          text: formatMessage({ id: 'Position_1', defaultMessage: '직급' }),
        },
      ],
    },
    {
      title: formatMessage({ id: 'Date_1', defaultMessage: '일시' }),
      info: [
        {
          key: 'sendEpoch',
          text: formatMessage({ id: 'Send_2', defaultMessage: '메일 발송' }),
          type: 'epoch',
        },
        {
          key: 'readEpoch',
          text: formatMessage({ id: 'Send_15', defaultMessage: '메일 확인' }),
          type: 'epoch',
        },
        {
          key: 'downloadEpoch',
          text: formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' }),
          type: 'epoch',
        },
        {
          key: 'infectionEpoch',
          text: formatMessage({ id: 'Infection_11', defaultMessage: '감염 파일 실행' }),
          type: 'epoch',
        },
        {
          key: 'cureEpoch',
          text: formatMessage({ id: 'Cure_1', defaultMessage: '치료' }),
          type: 'epoch',
        },
      ],
    },
  ];

  // 감염 현황(파일, 인증서, 메일) 데이티
  const countData = [
    {
      type: 'all',
      countKey: 'leakAllcount',
      sizeKey: 'leakAllsize',
    },
    {
      type: 'file',
      countKey: 'leakFileCount',
      sizeKey: 'leakFileSize',
    },
    {
      type: 'cert',
      countKey: 'leakCertCount',
      sizeKey: 'leakCertSize',
    },
    {
      type: 'mail',
      countKey: 'leakEmailCount',
      sizeKey: 'leakEmailSize',
    },
  ];

  // 교육 현황 데이터
  const eduCountData = [
    {
      type: 'all',
      countKey: 'eduCount',
      tooltip: formatMessage({ id: 'Target_7', defaultMessage: '교육 대상자' }),
    },
    {
      type: 'wait',
      countKey: 'beforeCount',
      tooltip: formatMessage({ id: 'Edu_11', defaultMessage: '학습 진행전 대상자' }),
    },
    {
      type: 'ing',
      countKey: 'playCount',
      tooltip: formatMessage({ id: 'Edu_12', defaultMessage: '학습 진행중인 대상자' }),
    },
    {
      type: 'end',
      countKey: 'afterCount',
      tooltip: formatMessage({ id: 'Edu_13', defaultMessage: '학습 완료 대상자' }),
    },
  ];

  // 태그 대상자 목록
  const tagTargetComponent =
    examTag.data.length > 0 &&
    examTag.data.map((data: any) => {
      const isPhishing = data.examType === 4;
      const sendReserve = data.sendReserve && !data.send;
      const sendStop = data.isSuspend && !data.send;
      const isCure = data.examType === 1 && attachExamType === 2 && data.isCure;

      // 발송 툴팁 메시지
      let sendTooltip = formatMessage({ id: 'Send_1', defaultMessage: '발송' });
      let sendIcon = '/img/exam/step/send.png';
      let downTooltip = '';
      let infectionTooltip = '';

      let statusText = formatMessage({ id: 'Exam_62', defaultMessage: '준비' });
      let statusColor = '';

      if (data.send) {
        statusText = formatMessage({ id: 'Exam_61', defaultMessage: '안전' });
        sendTooltip = `${sendTooltip}: ${timeFormatFromUTCEpoch(data.send)}`;
      }
      if (sendStop) {
        statusText = formatMessage({ id: 'Send_8', defaultMessage: '발송 일시 정지' });
        sendTooltip = `${
          sendReserve
            ? `(${formatMessage({
                id: 'Send_8',
                defaultMessage: '발송 일시 정지',
              })}}) ${formatMessage({
                id: 'Send_4',
                defaultMessage: '발송 예약',
              })}: ${timeFormatFromUTCEpoch(data.sendReserve)}`
            : formatMessage({ id: 'Send_8', defaultMessage: '발송 일시 정지' })
        }`;
        sendIcon = sendReserve
          ? '/img/exam/step/send_reserve_a.png'
          : '/img/exam/step/send_stop_a.png';
      }
      if (sendReserve && !sendStop) {
        statusText = formatMessage({ id: 'Send_4', defaultMessage: '발송 예약' });
        sendTooltip = `${statusText}: ${timeFormatFromUTCEpoch(data.sendReserve)}`;
        sendIcon = '/img/exam/step/send_stop_a.png';
      }
      if (data.sendError) {
        statusText = formatMessage({ id: 'Send_5', defaultMessage: '발송 실패' });
        sendIcon = '/img/exam/step/send_fail_a.png';
        statusColor = 'color-red';
      }
      if (data.read) {
        statusText = formatMessage({ id: 'Read_1', defaultMessage: '열람' });
        statusColor = 'color-orange';
      }
      if (data.download) {
        statusText = isPhishing
          ? formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })
          : formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' });
        downTooltip = `${statusText}${
          data.download ? `: ${timeFormatFromUTCEpoch(data.download)}` : ''
        }`;
        statusColor = 'color-down';
      }
      if (data.infection) {
        statusText = isPhishing
          ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
          : formatMessage({ id: 'Infection_1', defaultMessage: '감염' });
        infectionTooltip = `${statusText}${
          data.infection ? `: ${timeFormatFromUTCEpoch(data.infection)}` : ''
        }`;
        statusColor = 'color-red';
      }
      if (isCure) {
        statusText = formatMessage({ id: 'Cure_1', defaultMessage: '치료' });
        statusColor = 'color-cure';
      }

      return (
        <div className="detail-tag-item" key={data.tagNo}>
          <Popover
            overlayClassName="between-popover full"
            placement="topLeft"
            content={
              <>
                <div className="popover-row">
                  <div className="text">
                    {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                  </div>
                  <div className="value">{data.targetNo}</div>
                </div>
                <div className="popover-row">
                  <div className="text">
                    {formatMessage({ id: 'Name_5', defaultMessage: '대상자 이름' })}
                  </div>
                  <div className="value">{data.targetName}</div>
                </div>
                <div className="popover-row">
                  <div className="text">
                    {formatMessage({ id: 'Email_10', defaultMessage: '대상자 이메일' })}
                  </div>
                  <div className="value">{data.targetEmail}</div>
                </div>
              </>
            }
          >
            <div>{`${data.targetName} (${data.targetEmail})`}</div>
          </Popover>
          <div className="detail-list-step">
            <div className={`status-detail-text ${statusColor}`}>{statusText}</div>
            <ListStep
              tooltipTitle={formatMessage({ id: 'Target_2', defaultMessage: '대상' })}
              icon={{
                stepIcon: '/img/exam/step/target.png',
                active: true,
                activeIcon: '/img/exam/step/target_a.png',
                circleIcon: '/img/exam/step/circle_target.png',
              }}
            />
            <ListStep
              tooltipTitle={sendTooltip}
              icon={{
                stepIcon: sendIcon,
                active: data.send || sendReserve || data.sendError,
                activeIcon: '/img/exam/step/send_a.png',
                lineIcon: '/img/exam/step/line_send.png',
                circleIcon: '/img/exam/step/circle_send.png',
              }}
              countColor={data.sendError ? 'color-red' : ''}
            />
            <ListStep
              tooltipTitle={`${formatMessage({ id: 'Read_1', defaultMessage: '열람' })}${
                data.read ? `: ${timeFormatFromUTCEpoch(data.read)}` : ''
              }`}
              icon={{
                stepIcon: '/img/exam/step/read.png',
                active: data.read,
                activeIcon: '/img/exam/step/read_a.png',
                lineIcon: '/img/exam/step/line_read.png',
                circleIcon: '/img/exam/step/circle_read.png',
              }}
              countColor="color-orange"
            />
            <ListStep
              tooltipTitle={downTooltip}
              icon={{
                stepIcon: isPhishing ? '/img/exam/step/access.png' : '/img/exam/step/down.png',
                active: data.download,
                activeIcon: isPhishing
                  ? '/img/exam/step/access_a.png'
                  : '/img/exam/step/down_a.png',
                lineIcon: '/img/exam/step/line_down.png',
                circleIcon: '/img/exam/step/circle_down.png',
              }}
              countColor="color-down"
            />
            <ListStep
              tooltipTitle={infectionTooltip}
              icon={{
                stepIcon: isPhishing
                  ? '/img/exam/step/phishing.png'
                  : '/img/exam/step/infection.png',
                active: data.infection,
                activeIcon: isPhishing
                  ? '/img/exam/step/phishing_a.png'
                  : '/img/exam/step/infection_a.png',
                lineIcon: '/img/exam/step/line_infection.png',
                circleIcon: '/img/exam/step/circle_infection.png',
              }}
              countColor="color-red"
            />
            {isCure && (
              <ListStep
                tooltipTitle={`${formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}${
                  data.cure ? `: ${timeFormatFromUTCEpoch(data.cure)}` : ''
                }`}
                icon={{
                  stepIcon: '/img/exam/step/cure.png',
                  active: true,
                  activeIcon: '/img/exam/step/cure_a.png',
                  lineIcon: '/img/exam/step/line_cure.png',
                  circleIcon: '/img/exam/step/circle_cure.png',
                }}
                countColor="color-cure"
              />
            )}
          </div>
        </div>
      );
    });

  return (
    <div className="detail-area exam-detail">
      {/* 훈련 정보 */}
      {detailType === 'exam' && (
        <>
          {/* 훈련 정보 */}
          <div className="detail-item">
            <div className="detail-title">
              {formatMessage({ id: 'Exam_67', defaultMessage: '훈련 정보' })}
            </div>
            <div className="detail-contents">
              {/* 훈련 번호 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
                <div className="detail-value">{detailData.examNo}</div>
              </div>

              {/* 훈련명 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
                <div className="detail-value">{detailData.examName}</div>
              </div>

              {/* 상태 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Status_1', defaultMessage: '상태' })}
                </div>
                <div className="detail-value">{examStatus}</div>
              </div>

              {/* 진행률 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Template_28', defaultMessage: '진행률' })}
                </div>
                <div className="detail-value">
                  {isReserve && (
                    <Tooltip
                      overlayClassName="black-tooltip"
                      placement="bottom"
                      title={formatMessage({ id: 'Exam_9', defaultMessage: '예약 훈련' })}
                    >
                      <div className="reserve-icon">
                        <img src="/img/exam/send_reserve.png" alt="reserve" />
                      </div>
                    </Tooltip>
                  )}
                  <div className="detail-progress">
                    <Progress
                      className={`exam-progress ${isReserve ? 'disabled' : ''}`}
                      percent={progress}
                      status="active"
                      showInfo={false}
                      strokeColor={progress === 100 ? '#14ae96' : '#ee4f4f'}
                      strokeWidth={13}
                    />
                    <div className="progress-status" style={{ width: `${progress}%` }}>
                      <div
                        className="status-text"
                        style={{ width: `${progress}%` }}
                      >{`${progress.toFixed(1)}%`}</div>
                    </div>
                  </div>
                </div>
              </div>

              {/* 훈련 기간 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Period_3', defaultMessage: '훈련 기간' })}
                </div>
                <div className="detail-value">{`${timeFormatFromUTCEpoch(
                  detailData.examStartEpoch,
                  3,
                )} ~ ${timeFormatFromUTCEpoch(detailData.examEndEpoch, 3)}`}</div>
              </div>

              {/* 템플릿 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Template_27', defaultMessage: '템플릿' })}
                </div>
                <div className="detail-value">
                  <div className="exam-tag">
                    {/* 사용자 / 기본 */}
                    {detailData.templatePick === 1 ? (
                      <Tag className="temp-type-label color-temp-user">
                        {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
                      </Tag>
                    ) : (
                      <Tag className="temp-type-label color-temp-system">
                        {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
                      </Tag>
                    )}
                    {/* 경고 안내 / 모의 악성파일 / 실태 조사 / 피싱 유도 */}
                    {examTypeComponent}
                    {/* 첨부 파일 / 링크 첨부 / 링크+첨부 */}
                    <div className="attach-name">
                      (
                      {detailData.linkAttach === 1 &&
                        formatMessage({ id: 'Template_15', defaultMessage: '첨부 파일' })}
                      {detailData.linkAttach === 2 &&
                        formatMessage({ id: 'Template_16', defaultMessage: '링크 첨부' })}
                      {detailData.linkAttach === 3 &&
                        formatMessage({ id: 'Template_17', defaultMessage: '링크 + 첨부' })}
                      ){` / ${detailData.templateName}`}
                    </div>
                  </div>
                </div>
              </div>

              {/* 첨부파일 정보 */}
              {!isPhishing && (
                <div className="detail-row">
                  <div className="detail-text">
                    <Popover
                      overlayClassName="full"
                      placement="topLeft"
                      content={formatMessage({
                        id: 'Attach_29',
                        defaultMessage: "부가 기능, 제약사항 설명 페이지 'Click!'",
                      })}
                    >
                      <a
                        href={`/attachRestrict_${localStorage.getItem('language')}.html`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatMessage({ id: 'Attach_2', defaultMessage: '첨부파일 정보' })}
                        <InfoCircleFilled />
                      </a>
                    </Popover>
                  </div>
                  <div className="detail-value">
                    {detailData.attachData && (
                      <AttachInfoPopover
                        data={detailData.attachData}
                        examType={detailData.examType}
                        target={detailData.attachData.subject}
                      />
                    )}
                  </div>
                </div>
              )}

              {/* 훈련 메일 나눔 발송 */}
              {!!detailData.sendReservePeriod && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Send_3', defaultMessage: '훈련 메일 나눔 발송' })}
                  </div>
                  <div className="detail-value">{`${timeFormatFromUTCEpoch(
                    detailData.examStartEpoch,
                    3,
                  )} ~ ${timeFormatFromUTCEpoch(
                    detailData.examStartEpoch + (detailData.sendReservePeriod - 1) * 86400,
                    3,
                  )} (${detailData.sendReserveStartHour}${formatMessage({
                    id: 'Time_1',
                    defaultMessage: '시',
                  })} ~ ${detailData.sendReserveEndHour}${formatMessage({
                    id: 'Time_1',
                    defaultMessage: '시',
                  })})`}</div>
                </div>
              )}

              {/* 화면 잠금 예약 일시 */}
              {!isPhishing && detailData.attachScreenLockEpoch && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Date_9', defaultMessage: '화면 잠금 예약 일시' })}
                  </div>
                  <div className="detail-value">{`${timeFormatFromUTCEpoch(
                    detailData.attachScreenLockEpoch,
                    2,
                  )}`}</div>
                </div>
              )}

              {/* 훈련 실시자 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Exam_68', defaultMessage: '훈련 실시자' })}
                </div>
                <div className="detail-value">{`${detailData.userName}(${detailData.userEmail})`}</div>
              </div>

              {/* 훈련 대상 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' })}
                </div>
                <div className="detail-value">{`${detailData.target} ${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}${
                  detailData.examTargetRandomPer < 100
                    ? ` (${formatMessage({
                        id: 'StartExam_48',
                        defaultMessage: '훈련 대상자 선택 비율',
                      })} ${detailData.examTargetRandomPer}%)`
                    : ''
                }`}</div>
              </div>

              {/* 발송 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Send_1', defaultMessage: '발송' })}
                </div>
                <div className="detail-value">
                  {detailData.isSuspend === 1 &&
                    `(${formatMessage({ id: 'Send_10', defaultMessage: '메일 발송 일시정지' })})`}
                  {` ${detailData.send} ${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}
                  {detailData.send + detailData.sendError < detailData.target &&
                    ` (${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })}: ${(
                      ((detailData.send + detailData.sendError) / detailData.target) *
                      100
                    ).toFixed(0)}%)`}
                </div>
              </div>

              {/* 발송 실패 */}
              {!!detailData.sendError && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Send_1', defaultMessage: '발송 실패' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.sendError} ${formatMessage({
                      id: 'StartExam_35',
                      defaultMessage: '명',
                    })}`}
                  </div>
                </div>
              )}

              {/* 열람 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Read_1', defaultMessage: '열람' })}
                </div>
                <div className="detail-value">
                  {`${detailData.read} ${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}
                </div>
              </div>

              {/* 접속/다운 */}
              {(detailData.linkAttach === 2 || isPhishing) && (
                <div className="detail-row">
                  <div className="detail-text">
                    {isPhishing
                      ? formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })
                      : formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.download} ${formatMessage({
                      id: 'StartExam_35',
                      defaultMessage: '명',
                    })}`}
                  </div>
                </div>
              )}

              {/* 유출/감염 */}
              <div className="detail-row">
                <div className="detail-text">
                  {isPhishing
                    ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
                    : formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}
                </div>
                <div className="detail-value">
                  {`${detailData.infection} ${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}
                </div>
              </div>

              {/* 감염 PC */}
              {!isPhishing && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.infectionPc} ${formatMessage({
                      id: 'Infection_29',
                      defaultMessage: '대',
                    })}`}
                  </div>
                </div>
              )}

              {/* 치료 */}
              {!isPhishing && detailData.examType === 1 && attachExamType === 2 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.cure} ${formatMessage({
                      id: 'StartExam_35',
                      defaultMessage: '명',
                    })}`}
                  </div>
                </div>
              )}

              {/* 신고 */}
              {Option.isDeclare === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Declare_1', defaultMessage: '신고' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.declare} ${formatMessage({
                      id: 'StartExam_35',
                      defaultMessage: '명',
                    })}`}
                  </div>
                </div>
              )}

              {Option.isEdu === 1 && (
                <>
                  {/* 연결된 교육 */}
                  <div className="detail-row">
                    <div className="detail-text">
                      {formatMessage({ id: 'Edu_2', defaultMessage: '연결된 교육' })}
                    </div>
                    <div className="detail-value">
                      {`${detailData.edu} ${formatMessage({
                        id: 'StartExam_21',
                        defaultMessage: '개',
                      })}`}
                    </div>
                  </div>

                  {/* 교육 대상자 */}
                  <div className="detail-row">
                    <div className="detail-text">
                      {formatMessage({ id: 'Target_7', defaultMessage: '교육 대상자' })}
                    </div>
                    <div className="detail-value">
                      {`${detailData.eduTarget} ${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })}`}
                    </div>
                  </div>
                </>
              )}

              {/* 등록일 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Date_19', defaultMessage: '등록일' })}
                </div>
                <div className="detail-value">{timeFormatFromUTCEpoch(detailData.regEpoch)}</div>
              </div>

              {/* 마지막 수정일 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Date_21', defaultMessage: '마지막 수정일' })}
                </div>
                <div className="detail-value">{timeFormatFromUTCEpoch(detailData.modifyEpoch)}</div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* 템플릿별 대상자 정보 */}
      {detailType === 'bytemplate' && (
        <div className="detail-item">
          <div className="detail-title">
            {formatMessage({ id: 'Target_8', defaultMessage: '대상자 정보' })}
          </div>
          <div className="detail-contents">
            <Loading loading={loading} />
            <div>
              {templateTargetList?.data?.length > 0 ? (
                templateTargetList.data?.map((item: any) => {
                  const templateInfo = templateList.dataByTemplateNo[templateList.selectedList[0]];
                  return (
                    <div className="detail-target-item" key={item.rnum}>
                      <div>{`${item.targetName} (${item.targetEmail})`}</div>
                      <div className="detail-row target-detail">
                        <Tooltip
                          overlayClassName="black-tooltip full"
                          placement="bottomLeft"
                          title={`${formatMessage({
                            id: 'License_11',
                            defaultMessage: '훈련 횟수',
                          })}: ${item.count}`}
                        >
                          <div className="exam-info">
                            <span className="exam-text">
                              <img src="/img/exam/exam_all.png" alt="exam" />
                            </span>
                            <span className="exam-value">{item.count}</span>
                          </div>
                        </Tooltip>

                        <Tooltip
                          overlayClassName="black-tooltip full"
                          placement="bottomLeft"
                          title={`${formatMessage({
                            id: 'Send_1',
                            defaultMessage: '발송',
                          })}: ${item.send}`}
                        >
                          <div className="exam-info">
                            <span className="exam-text">
                              <img src="/img/exam/step/send_a.png" alt="send" />
                            </span>
                            <span className="exam-value brand-color">{item.send}</span>
                          </div>
                        </Tooltip>

                        <Tooltip
                          overlayClassName="black-tooltip full"
                          placement="bottomLeft"
                          title={`${formatMessage({
                            id: 'Read_1',
                            defaultMessage: '열람',
                          })}: ${item.read}`}
                        >
                          <div className="exam-info">
                            <span className="exam-text">
                              <img src="/img/exam/step/read_a.png" alt="read" />
                            </span>
                            <span className="exam-value color-orange">{item.read}</span>
                          </div>
                        </Tooltip>

                        <Tooltip
                          overlayClassName="black-tooltip full"
                          placement="bottomLeft"
                          title={`${
                            templateInfo?.examType === 4
                              ? formatMessage({
                                  id: 'Download_8',
                                  defaultMessage: '피싱 접속',
                                })
                              : formatMessage({
                                  id: 'Download_4',
                                  defaultMessage: '파일 다운',
                                })
                          }: ${item.download}`}
                        >
                          <div className="exam-info">
                            <span className="exam-text">
                              {templateInfo?.examType === 4 ? (
                                <img src="/img/exam/step/access_a.png" alt="access" />
                              ) : (
                                <img src="/img/exam/step/down_a.png" alt="down" />
                              )}
                            </span>
                            <span className="exam-value color-down">{item.download}</span>
                          </div>
                        </Tooltip>

                        <Tooltip
                          overlayClassName="black-tooltip full"
                          placement="bottomLeft"
                          title={`${
                            templateInfo?.examType === 4
                              ? formatMessage({
                                  id: 'Infection_26',
                                  defaultMessage: '정보 유출',
                                })
                              : formatMessage({
                                  id: 'Infection_1',
                                  defaultMessage: '감염',
                                })
                          }: ${item.infection}`}
                        >
                          <div className="exam-info">
                            <span className="exam-text">
                              {templateInfo?.examType === 4 ? (
                                <img src="/img/exam/step/phishing_a.png" alt="phishing" />
                              ) : (
                                <img src="/img/exam/step/infection_a.png" alt="infection" />
                              )}
                            </span>
                            <span className="exam-value color-red">{item.infection}</span>
                          </div>
                        </Tooltip>

                        <Tooltip
                          overlayClassName="black-tooltip full"
                          placement="bottomLeft"
                          title={`${formatMessage({
                            id: 'Cure_1',
                            defaultMessage: '치료',
                          })}: ${item.cure}`}
                        >
                          {templateInfo?.examType === 1 && templateInfo?.attachExamType === 2 && (
                            // 모의악성파일 유형일 때만 치료 표시
                            <div className="exam-info">
                              <span className="exam-text">
                                <img src="/img/exam/step/cure_a.png" alt="cure" />
                              </span>
                              <span className="exam-value color-cure">{item.cure}</span>
                            </div>
                          )}
                        </Tooltip>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div>
                  {formatMessage({ id: 'Target_14', defaultMessage: '대상자가 없습니다.' })}
                </div>
              )}
            </div>
            {/* 더보기 */}
            {templateTargetList.totalPages > 1 &&
              templateTargetList.totalPages > templateTargetList.page && (
                <div
                  className="add-view-btn"
                  onClick={() => onGetTemplateTarget()}
                  aria-hidden="true"
                >
                  <PlusCircleFilled />
                  {formatMessage({ id: 'Button_17', defaultMessage: '더보기' })}
                </div>
              )}
          </div>
        </div>
      )}

      {/* 대상자 훈련 결과 */}
      {detailType === 'pi' && (
        <>
          <div className="detail-item">
            <div className="detail-title">
              {formatMessage({ id: 'Target_9', defaultMessage: '대상자 훈련 결과' })}
            </div>
            <div className="detail-contents">
              {/* 대상자 번호 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                </div>
                <div className="detail-value">{detailData.targetNo}</div>
              </div>
              {/* 대상자 이름 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Name_5', defaultMessage: '대상자 이름' })}
                </div>
                <div className="detail-value">{detailData.targetName}</div>
              </div>
              {/* 대상자 메일 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Email_24', defaultMessage: '대상자 메일' })}
                </div>
                <div className="detail-value">{detailData.targetEmail}</div>
              </div>
              {/* 대상자 연락처 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Contact_3', defaultMessage: '대상자 연락처' })}
                </div>
                <div className="detail-value">{detailData.targetPhone || '-'}</div>
              </div>
              {/* 대상자 소속 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Division_3', defaultMessage: '대상자 소속' })}
                </div>
                <div className="detail-value">{detailData.targetDivision || '-'}</div>
              </div>
              {/* 대상자 직급 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Position_3', defaultMessage: '대상자 직급' })}
                </div>
                <div className="detail-value">{detailData.targetPosition || '-'}</div>
              </div>
              {/* 훈련 번호 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
                <div className="detail-value">{detailData.examNo}</div>
              </div>
              {/* 훈련명 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
                <div className="detail-value">{detailData.examName}</div>
              </div>
              {/* 발송 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Send_1', defaultMessage: '발송' })}
                </div>
                <div className="detail-value">
                  {(detailData.send && timeFormatFromUTCEpoch(detailData.send)) || '-'}
                  {detailData.sendReserve &&
                    `(${formatMessage({
                      id: 'Date_10',
                      defaultMessage: '발송 예약 일시',
                    })}) ${timeFormatFromUTCEpoch(detailData.sendReserve)}`}
                </div>
              </div>
              {/* 열람 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Read_1', defaultMessage: '열람' })}
                </div>
                <div className="detail-value">{timeFormatFromUTCEpoch(detailData.read)}</div>
              </div>
              {/* 피싱 접속 / 파일 다운 */}
              {(isPhishing || detailData.linkAttach === 2) && (
                <div className="detail-row">
                  <div className="detail-text">
                    {isPhishing
                      ? formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })
                      : formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' })}
                  </div>
                  <div className="detail-value">{timeFormatFromUTCEpoch(detailData.download)}</div>
                </div>
              )}
              {/* 정보 유출 / 감염 */}
              <div className="detail-row">
                <div className="detail-text">
                  {isPhishing
                    ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
                    : formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}
                </div>
                <div className="detail-value">{timeFormatFromUTCEpoch(detailData.infection)}</div>
              </div>
              {/* 감염 PC */}
              {!isPhishing && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' })}
                  </div>
                  <div className="detail-value">
                    {infectionTarget.data[0]?.infectionPc
                      ? infectionTarget.data[0]?.infectionPc.length
                      : 0}
                    {formatMessage({ id: 'Infection_29', defaultMessage: '대' })}
                  </div>
                </div>
              )}
              {/* 모의 악성파일 */}
              {detailData.examType === 1 && attachExamType === 2 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
                  </div>
                  <div className="detail-value">{timeFormatFromUTCEpoch(detailData.cure)}</div>
                </div>
              )}
              {/* 신고 */}
              {Option.isDeclare === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Declare_1', defaultMessage: '신고' })}
                  </div>
                  <div className="detail-value">{timeFormatFromUTCEpoch(detailData.declare)}</div>
                </div>
              )}
              {/* 교육 개수 */}
              {Option.isEdu === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Edu_17', defaultMessage: '교육 개수' })}
                  </div>
                  <div className="detail-value">
                    {`${
                      detailData.eduBefore + detailData.eduPlay + detailData.eduAfter || 0
                    } ${formatMessage({ id: 'StartExam_21', defaultMessage: '개' })}`}
                  </div>
                </div>
              )}
              {/* 진행전인 교육 */}
              {Option.isEdu === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Edu_14', defaultMessage: '진행전인 교육' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.eduPlay} ${formatMessage({
                      id: 'StartExam_21',
                      defaultMessage: '개',
                    })}`}
                  </div>
                </div>
              )}
              {/* 진행중인 교육 */}
              {Option.isEdu === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Edu_15', defaultMessage: '진행중인 교육' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.eduPlay} ${formatMessage({
                      id: 'StartExam_21',
                      defaultMessage: '개',
                    })}`}
                  </div>
                </div>
              )}
              {/* 학습 완료 교육 */}
              {Option.isEdu === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Edu_16', defaultMessage: '학습 완료 교육' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.eduPlay} ${formatMessage({
                      id: 'StartExam_21',
                      defaultMessage: '개',
                    })}`}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* 태그별 훈련 결과 */}
      {detailType === 'tag' && (
        <>
          <div className="detail-item">
            <div className="detail-title">
              {formatMessage({ id: 'Tag_41', defaultMessage: '태그별 훈련 결과' })}
            </div>
            <div className="detail-contents">
              {/* 태그 번호 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Tag_13', defaultMessage: '태그 번호' })}
                </div>
                <div className="detail-value">{detailData.tagNo}</div>
              </div>
              {/* 태그명 */}
              <div className="detail-row align-center">
                <div className="detail-text">
                  {formatMessage({ id: 'Tag_2', defaultMessage: '태그명' })}
                </div>
                <div className="detail-value">
                  <Tag className={`color-tag tag-label-${detailData.color} small`}>
                    {detailData.tagName}
                  </Tag>
                </div>
              </div>
              {/* 훈련 번호 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
                <div className="detail-value">{detailData.examNo}</div>
              </div>
              {/* 훈련명 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
                <div className="detail-value">{detailData.examName}</div>
              </div>
              {/* 훈련 대상 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' })}
                </div>
                <div className="detail-value">
                  {`${detailData.target} ${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}
                  {detailData.examTargetRandomPer < 100
                    ? `(${formatMessage({
                        id: 'StartExam_48',
                        defaultMessage: '훈련 대상자 선택 비율',
                      })} ${detailData.examTargetRandomPer}%)`
                    : ''}
                </div>
              </div>
              {/* 발송 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Send_1', defaultMessage: '발송' })}
                </div>
                <div className="detail-value">
                  {detailData.isSuspend === 1 &&
                    `(${formatMessage({ id: 'Send_10', defaultMessage: '메일 발송 일시정지' })})`}
                  {`${detailData.send} ${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}
                  {detailData.send + detailData.sendError < detailData.target &&
                    `(${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })}: ${(
                      ((detailData.send + detailData.sendError) / detailData.target) *
                      100
                    ).toFixed(0)}%)`}
                </div>
              </div>
              {/* 열람 */}
              <div className="detail-row">
                <div className="detail-text">
                  {formatMessage({ id: 'Read_1', defaultMessage: '열람' })}
                </div>
                <div className="detail-value">{`${detailData.read} ${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}`}</div>
              </div>
              {/* 피싱 접속 / 파일 다운 */}
              {(isPhishing || detailData.linkAttach === 2) && (
                <div className="detail-row">
                  <div className="detail-text">
                    {isPhishing
                      ? formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })
                      : formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' })}
                  </div>
                  <div className="detail-value">{`${detailData.download} ${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
              )}
              {/* 정보 유출 / 감염 */}
              <div className="detail-row">
                <div className="detail-text">
                  {isPhishing
                    ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
                    : formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}
                </div>
                <div className="detail-value">{`${detailData.infection} ${formatMessage({
                  id: 'StartExam_35',
                  defaultMessage: '명',
                })}`}</div>
              </div>
              {/* 감염 PC */}
              {!isPhishing && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.infectionPc} ${formatMessage({
                      id: 'Infection_29',
                      defaultMessage: '대',
                    })}`}
                  </div>
                </div>
              )}
              {/* 치료 */}
              {detailData.examType === 1 && attachExamType === 2 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
                  </div>
                  <div className="detail-value">{`${detailData.cure} ${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
              )}
              {/* 신고 */}
              {Option.isDeclare === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Declare_1', defaultMessage: '신고' })}
                  </div>
                  <div className="detail-value">{`${detailData.declare} ${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}</div>
                </div>
              )}
              {/* 연결된 교육 */}
              {Option.isEdu === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Edu_17', defaultMessage: '연결된 교육' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.eduByTag} ${formatMessage({
                      id: 'StartExam_21',
                      defaultMessage: '개',
                    })}`}
                  </div>
                </div>
              )}
              {/* 교육 대상자 */}
              {Option.isEdu === 1 && (
                <div className="detail-row">
                  <div className="detail-text">
                    {formatMessage({ id: 'Target_7', defaultMessage: '교육 대상자' })}
                  </div>
                  <div className="detail-value">
                    {`${detailData.eduTarget} ${formatMessage({
                      id: 'StartExam_35',
                      defaultMessage: '명',
                    })}`}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* 태그 대상자 목록 */}
          <div className="detail-item">
            <div className="detail-title">
              {formatMessage({ id: 'Tag_12', defaultMessage: '태그 대상자 목록' })}
            </div>
            <div className="detail-contents">
              <Loading loading={loading} />
              {tagTargetComponent}
              {/* 더보기 */}
              {examTag.totalPage > 1 && examTag.totalPage > examTag.page && (
                <div className="add-view-btn" onClick={() => onGetExamTag()} aria-hidden="true">
                  <PlusCircleFilled />
                  {formatMessage({ id: 'Button_17', defaultMessage: '더보기' })}
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {/* 감염 가능 파일 */}
      {detailType !== 'bytemplate' && !isPhishing && !selectedRandomGroupNo && (
        <div className="detail-item">
          <div className="detail-title">
            {formatMessage({ id: 'Infection_15', defaultMessage: '감염 가능 파일' })}
          </div>
          <div className="detail-contents">
            <div className="bold">
              <div>{`${formatMessage({ id: 'Exam_25', defaultMessage: '총' })} ${
                detailData.leakAllcount
              }${formatMessage({ id: 'StartExam_21', defaultMessage: '개' })} (${
                fileSizeTransform(detailData.leakAllsize).common
              })`}</div>
            </div>

            <div className="chart-item exam-detail">
              <PieChart
                width={340}
                height={150}
                margin={{ top: 20, right: 10, bottom: 20, left: 10 }}
              >
                <Pie
                  dataKey="value"
                  data={[
                    {
                      name: '',
                      value: 100,
                    },
                  ]}
                  cx={60}
                  cy={60}
                  paddingAngle={0}
                  innerRadius={20}
                  outerRadius={55}
                  fill="#f7f7f7"
                />
                <Pie
                  dataKey="value"
                  data={circleChartArray}
                  cx={60}
                  cy={60}
                  innerRadius={25}
                  outerRadius={50}
                  startAngle={450}
                  endAngle={90}
                  paddingAngle={-1}
                  activeIndex={0}
                  activeShape={(e: any) => {
                    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = e;
                    return (
                      <g>
                        {/* 파일 */}
                        <defs>
                          <linearGradient id="43cbaa" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#39cbd2" />
                            <stop offset="50%" stopColor="#77eadf" />
                            <stop offset="100%" stopColor="#28c3a4" />
                          </linearGradient>
                        </defs>
                        {/* 인증서 */}
                        <defs>
                          <linearGradient id="7b3faf" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#753eaa" />
                            <stop offset="50%" stopColor="#a33ede" />
                            <stop offset="100%" stopColor="#6e19cc" />
                          </linearGradient>
                        </defs>
                        {/* 이메일 */}
                        <defs>
                          <linearGradient id="6697da" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#619dd8" />
                            <stop offset="100%" stopColor="#6649d2" />
                          </linearGradient>
                        </defs>
                        <Sector
                          cx={cx}
                          cy={cy}
                          innerRadius={innerRadius}
                          outerRadius={outerRadius}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          fill="#f7f7f7"
                        />
                        <Sector
                          cx={cx}
                          cy={cy}
                          innerRadius={outerRadius - 1}
                          outerRadius={outerRadius + 8}
                          startAngle={startAngle}
                          endAngle={endAngle}
                          fill="#f7f7f7"
                        />
                      </g>
                    );
                  }}
                >
                  {/* 퍼센트 표시 */}
                  {circleChartArray.map((entry: any) => (
                    <Cell key={entry.name} fill={`url(${entry.color})`} />
                  ))}
                </Pie>
                <Legend
                  iconSize={10}
                  width={190}
                  layout="vertical"
                  align="right"
                  wrapperStyle={{
                    top: 35,
                    left: 150,
                    lineHeight: '30px',
                  }}
                  content={({ payload }: any) => {
                    const legendItem = payload.filter(
                      (item: any) =>
                        item.value === 'pc' || item.value === 'cert' || item.value === 'email',
                    );
                    return (
                      <div className="chart-legend">
                        {legendItem.length > 0 &&
                          legendItem.map((data: any) => {
                            const { color, label, value, file } = data.payload.payload;
                            return (
                              <div className="flex" key={data.name}>
                                <div className="color" style={{ backgroundColor: color }} />
                                <div className="message">{label}</div>
                                <div className="value">{`${value}${formatMessage({
                                  id: 'StartExam_21',
                                  defaultMessage: '개',
                                })} (${file})`}</div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  }}
                />
              </PieChart>
            </div>
          </div>
        </div>
      )}

      {/* 감염 대상 */}
      {detailType !== 'bytemplate' && !isPhishing && (
        <div className="detail-item">
          <div className="detail-title">
            {formatMessage({ id: 'Infection_4', defaultMessage: '감염 대상' })}
          </div>
          <div className="detail-contents">
            <Loading loading={loading} />
            {infectionTarget.data?.length > 0 ? (
              <>
                <div className="infection-target-item">
                  {infectionTarget.data.map((target: any) => {
                    return (
                      <div key={target.targetNo}>
                        <div className="infection-target-text">
                          <Tooltip
                            overlayClassName="black-tooltip full"
                            placement="bottomLeft"
                            title={`${target.targetName} (${target.targetEmail})`}
                          >
                            <div>{`${target.targetName} (${target.targetEmail})`}</div>
                          </Tooltip>
                          <img
                            src={
                              infectionTarget.openList.length > 0 &&
                              infectionTarget.openList.includes(target.targetNo)
                                ? '/img/tag/ico_close.png'
                                : '/img/tag/ico_open.png'
                            }
                            alt="arrow"
                            onClick={() => changeOpenInfection(target.targetNo)}
                            aria-hidden="true"
                          />
                        </div>
                        <div className="infection-target-contents">
                          <div
                            className="target-company"
                            title={`${target.targetDivision || '-'}/${
                              target.targetPosition || '-'
                            }`}
                          >{`${target.targetDivision || '-'}/${target.targetPosition || '-'}`}</div>
                          <div className="flex">
                            <ExamListExtra
                              className="small"
                              data={{
                                infectionPc: target.infectionPc?.length || 0,
                                leakAllcount: target.leakAllcount,
                                leakAllsize: target.leakAllsize,
                              }}
                              useList={['infection']}
                            />
                            <CountInfo
                              type="all"
                              count={target.leakAllcount}
                              size={target.leakAllsize}
                            />
                          </div>
                        </div>

                        {/* 감염 대상 상세 */}
                        {infectionTarget.openList.includes(target.targetNo) && (
                          <div className="infecion-detail-box">
                            {/* 대상자 정보, 일시 */}
                            {infectionInnerData.map((inner: any) => (
                              <div className="inner-item" key={inner.title}>
                                <div className="inner-title">{inner.title}</div>
                                <div className="inner-contents">
                                  {inner.info.map((row: any) => {
                                    let value = target[row.key] || '-';
                                    if (row.type === 'epoch') {
                                      value = target[row.key]
                                        ? timeFormatFromUTCEpoch(target[row.key])
                                        : '-';
                                    }

                                    return (
                                      (row.key !== 'cureEpoch' ||
                                        (row.key === 'cureEpoch' &&
                                          target.examType === 1 &&
                                          attachExamType === 2)) && (
                                        <div className="inner-row" key={row.key}>
                                          <div className="inner-row-title">{row.text}</div>
                                          <div className="inner-row-value">{value}</div>
                                        </div>
                                      )
                                    );
                                  })}
                                </div>
                              </div>
                            ))}
                            {/* 감염 정보 */}
                            {target.infectionPc?.length > 0 && (
                              <div className="inner-item">
                                <div className="inner-title">
                                  {formatMessage({
                                    id: 'Infection_12',
                                    defaultMessage: '감염 정보',
                                  })}
                                </div>
                                <div className="inner-contents">
                                  <div className="inner-row">
                                    <ExamListExtra
                                      className="small"
                                      data={{
                                        infectionPc: target.infectionPc.length,
                                        leakAllcount: target.leakAllcount,
                                        leakAllsize: target.leakAllsize,
                                      }}
                                      useList={['infection']}
                                    />
                                  </div>
                                  <div className="inner-row count-row">
                                    {countData.map((data: any) => (
                                      <CountInfo
                                        type={data.type}
                                        count={target[data.countKey]}
                                        size={target[data.sizeKey]}
                                        key={data.type}
                                      />
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* 감염 PC 목록 */}
                            {target.infectionPc?.length > 0 && (
                              <div className="inner-item">
                                <div className="inner-title">
                                  {formatMessage({
                                    id: 'Infection_13',
                                    defaultMessage: '감염 PC 목록',
                                  })}
                                </div>
                                {target.infectionPc.map((data: any) => (
                                  <div className="inner-contents" key={data.hostname}>
                                    <div className="inner-row">
                                      <div
                                        className="dot-title"
                                        title={`${data.hostname}(${data.ip})`}
                                      >
                                        <div className="dot">·</div>
                                        <span>{data.hostname}</span>
                                        <span className="ml-5">{` (${data.ip})`}</span>
                                      </div>
                                    </div>
                                    <div className="inner-row">
                                      {formatMessage({
                                        id: 'Time_10',
                                        defaultMessage: 'PC 감염 시간',
                                      })}
                                      <div className="row-grey">
                                        {timeFormatFromUTCEpoch(data.regEpoch, 1)}
                                      </div>
                                    </div>
                                    <div className="inner-row count-row">
                                      {countData.map((data: any) => (
                                        <CountInfo
                                          type={data.type}
                                          count={target[data.countKey]}
                                          size={target[data.sizeKey]}
                                          key={data.type}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                {/* 더보기 */}
                {infectionTarget.totalPage > 1 && infectionTarget.totalPage > infectionTarget.page && (
                  <div
                    className="add-view-btn"
                    onClick={() => onGetInfectionTarget()}
                    aria-hidden="true"
                  >
                    <PlusCircleFilled />
                    {formatMessage({ id: 'Button_17', defaultMessage: '더보기' })}
                  </div>
                )}
              </>
            ) : (
              <div className="no-data color-brand-green">
                <CheckSquareFilled />
                {formatMessage({ id: 'Infection_9', defaultMessage: '감염 대상 없음' })}
              </div>
            )}
          </div>
        </div>
      )}

      {/* 감염 PC */}
      {detailType !== 'bytemplate' && !isPhishing && (
        <div className="detail-item">
          <div className="detail-title">
            {formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' })}
          </div>
          <div className="detail-contents">
            <Loading loading={loading} />
            {infectionPc.data?.length > 0 ? (
              <>
                <div className="inner-item">
                  <div className="inner-contents">
                    {infectionPc.data.map((data: any) => (
                      <>
                        <div className="inner-row">
                          <div className="dot-title">
                            <div className="dot">·</div>
                            <span className="bold font-size-14">{data.hostname}</span>
                            <span className="color-dark-grey ml-5">{` (${data.ip})`}</span>
                          </div>
                        </div>
                        <div className="inner-row">
                          {formatMessage({
                            id: 'Time_10',
                            defaultMessage: 'PC 감염 시간',
                          })}
                          <div className="row-grey">{timeFormatFromUTCEpoch(data.regEpoch, 1)}</div>
                        </div>
                        <div className="inner-row">
                          <Tooltip
                            overlayClassName="black-tooltip full"
                            placement="bottomLeft"
                            title={`${data.targetName} (${data.targetEmail})`}
                          >
                            <div>{`${data.targetName} (${data.targetEmail})`}</div>
                          </Tooltip>
                        </div>
                        <div className="inner-row count-row">
                          {countData.map((info: any) => (
                            <CountInfo
                              type={info.type}
                              count={data[info.countKey]}
                              size={data[info.sizeKey]}
                              key={info.type}
                            />
                          ))}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
                {/* 더보기 */}
                {infectionTarget.totalPage > 1 && infectionTarget.totalPage > infectionTarget.page && (
                  <div
                    className="add-view-btn"
                    onClick={() => onGetInfectionTarget()}
                    aria-hidden="true"
                  >
                    <PlusCircleFilled />
                    {formatMessage({ id: 'Button_17', defaultMessage: '더보기' })}
                  </div>
                )}
              </>
            ) : (
              <div className="no-data color-brand-green">
                <CheckSquareFilled />
                {formatMessage({ id: 'Infection_9', defaultMessage: '감염 대상 없음' })}
              </div>
            )}
          </div>
        </div>
      )}

      {/* 교육 이력 */}
      {detailType !== 'bytemplate' && Option.isEdu === 1 && detailType === 'exam' && (
        <div className="detail-item">
          <div className="detail-title">
            {formatMessage({ id: 'Edu_8', defaultMessage: '교육 이력' })}
          </div>
          <div className="detail-contents">
            <Loading loading={loading} />
            {eduHistory.data.length > 0 ? (
              <div className="inner-item">
                {eduHistory.data.map((edu: any) => (
                  <div className="inner-contents" key={edu.eduNo}>
                    <div className="inner-row">
                      <div className="dot-title">
                        <div className="dot">·</div>
                        <Tooltip
                          overlayClassName="black-tooltip full"
                          placement="bottom"
                          title={edu.eduName}
                        >
                          <span className="bold font-size-14">{edu.eduName}</span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="inner-row">
                      {formatMessage({
                        id: 'Edu_10',
                        defaultMessage: '교육 컨텐츠',
                      })}
                      <Tooltip
                        overlayClassName="black-tooltip full"
                        placement="bottom"
                        title={edu.attachName}
                      >
                        <div className="row-grey ellipsis">{edu.attachName}</div>
                      </Tooltip>
                    </div>
                    <div className="inner-row">
                      {formatMessage({
                        id: 'Period_4',
                        defaultMessage: '교육 기간',
                      })}
                      <div className="row-grey">
                        {`${timeFormatFromUTCEpoch(edu.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                          edu.endEpoch,
                          3,
                        )}`}
                      </div>
                    </div>
                    <div className="inner-row count-row">
                      {eduCountData.map((info: any) => (
                        <CountInfo
                          isEdu
                          type={info.type}
                          count={edu[info.countKey]}
                          tooltip={`${info.tooltip}: ${edu[info.countKey]}`}
                          key={info.type}
                        />
                      ))}
                    </div>
                  </div>
                ))}
                {/* 더보기 */}
                {eduHistory.totalPage > 1 && eduHistory.totalPage > eduHistory.page && (
                  <div
                    className="add-view-btn"
                    onClick={() => onGetEduHistory()}
                    aria-hidden="true"
                  >
                    <PlusCircleFilled />
                    {formatMessage({ id: 'Button_17', defaultMessage: '더보기' })}
                  </div>
                )}
              </div>
            ) : (
              <div className="no-data color-brand-green">
                <CheckSquareFilled />
                {formatMessage({ id: 'Edu_9', defaultMessage: '학습 대상 없음' })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ExamDetail;
