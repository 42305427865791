import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import { CheckOutlined, InfoCircleFilled } from '@ant-design/icons';
import './TransformInput.scss';

// import lockImage from "img/login/pass_off.png";
// import unlockImage from "img/login/pass_on.png";

/**
 * 항목명 위치가 변경되는 형태의 input
 */
export default function TransformInput(props: any) {
  const {
    name,
    type,
    label,
    value,
    error,
    register,
    validation,
    children,
    useCheck,
    useLock,
    tooltipText,
  } = props;
  const [lock, setLock] = useState(true);

  // 비밀번호 보이기/숨기기
  const toggleLock = () => {
    setLock(!lock);
  };

  return (
    <div className="transform-underline-input-box">
      {/* 입력창 */}
      <input
        name={name}
        className={`underline-input${value ? ' active' : ''}`}
        type={type === 'password' && lock ? 'password' : 'text'}
        placeholder={label}
        ref={register(validation)}
      />

      {/* 항목명 */}
      <label className="input-label">{label}</label>

      {/* 우측 영역 */}
      <div className="right-box">
        {/* 체크 */}
        {useCheck && value && !error && (
          <div className="check-icon">
            <CheckOutlined />
          </div>
        )}
        {/* 자물쇠 */}
        {useLock && (
          <div className="lock-icon" onClick={toggleLock} onKeyDown={toggleLock} aria-hidden="true">
            {lock ? (
              <img src="/img/login/pass_off.png" alt="" />
            ) : (
              <img src="/img/login/pass_on.png" alt="" />
            )}
          </div>
        )}
        {/* 툴팁 */}
        {!!tooltipText && (
          <Tooltip
            className="info-tooltip"
            overlayClassName="white-tooltip"
            placement="bottomLeft"
            title={tooltipText}
          >
            <div className="info-icon">
              <InfoCircleFilled />
            </div>
          </Tooltip>
        )}
      </div>
      {children}
      {/* 에러 메시지 */}
      {!!error && <div className="error-message">{error.message}</div>}
    </div>
  );
}
