import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import parse from 'html-react-parser';
import ModalTemplate from 'components/common/ModalTemplate';
import {
  getPrepare,
  getExam,
  getTag,
  getLicense,
  getTemplate,
  getAttach,
  getSystemUrl,
  startExam,
  setInitial,
} from 'store/startExam';
import { timeFormatFromUTCEpoch, epochFromDate, fitTwoDigit } from 'utils/commonFunctions';
import { saveForm, initForm } from 'store/form';
import StartExamStep1 from 'components/branch/exam/StartExamStep1';
import StartExamStep2 from 'components/branch/exam/StartExamStep2';
import StartExamStep3 from 'components/branch/exam/StartExamStep3';
import StartExamStep4 from 'components/branch/exam/StartExamStep4';
import StartExamStep5 from 'components/branch/exam/StartExamStep5';
import Loading from 'components/common/Loading';
import './StartExam.scss';

// import step1Image from 'img/exam/startExam/step1.png';
// import step2Image from 'img/exam/startExam/step2.png';
// import step3Image from 'img/exam/startExam/step3.png';
// import step4Image from 'img/exam/startExam/step4.png';
// import step5Image from 'img/exam/startExam/step5.png';

// import prevImage from 'img/exam/startExam/btn_prev.png';
// import nextImage from 'img/exam/startExam/btn_next.png';
// import failImage from 'img/exam/startExam/ico_fail_s.png';

function StartExam({ history, loadData, onCloseModal, type }: any) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const examPrepareData = useSelector((state: any) => state.startExam.prepare);
  const licenseData = useSelector((state: any) => state.startExam.license);
  const resultData = useSelector((state: any) => {
    return {
      success: state.startExam.result.success,
      error: state.startExam.result.error,
    };
  });
  const formStoreData = useSelector((state: any) => state.form.formStore);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [isFail, setIsFail] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [licenseError, setLicenseError] = useState(false);
  const [licenseRes, setLicenseRes]: any = useState({});
  const [resultOpenModal, setResultOpenModal] = useState(false);
  const [targetAllCount, setTargetAllCount] = useState(0);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    errors,
    control,
  } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    onPrepareExam();
    setShowModal(true);

    return () => {
      onInit();
    };
  }, []);

  useEffect(() => {
    // 에러 초기화
    clearErrors();
  }, [formStoreData]);

  useEffect(() => {
    setLicenseError(false);
  }, [step]);

  // STEP1 환경 검사
  const onPrepareExam = async () => {
    setLoading(true);

    try {
      const response: any = await dispatch(getPrepare());
      if (response?.data?.list) {
        const data = response?.data?.list[0];

        if (
          data?.mailSmtpTested === '1' &&
          data?.targetCount > 0 &&
          data?.tagCount > 0 &&
          data?.templateCheckCount > 0 &&
          data?.licenseDetailEnc
        ) {
          setIsFail(false);
        }
        setLoading(false);
      }
    } catch (error) {
      console.log('StartExam onPrepareExam', error);
    }
  };

  // STEP2 훈련명 중복 확인
  const onCheckExamName = async (formData: any, moveStep: any) => {
    setLoading(true);
    try {
      const response: any = await dispatch(
        getExam({ filter: { examNameExact: formData.examName } }),
      );
      if (response?.data?.list?.length > 0) {
        setError('examName', {
          type: 'duplicate',
          message: formatMessage({
            id: 'StartExam_45',
            defaultMessage: '이미 사용중인 훈련명입니다.',
          }),
        });
        setLoading(false);
      } else {
        onGetTag();
        setStep(moveStep);
      }
    } catch (error) {
      console.log('StartExam onCheckExamName', error);
    }
  };

  // STEP3 대상자 태그 조회
  const onGetTag = async () => {
    setLoading(true);

    try {
      await dispatch(getTag());
      setLoading(false);
    } catch (error) {
      console.log('StartExam onGetTag');
    }
  };

  // 라이선스 오류 안내
  const licenseContent = useMemo(() => {
    let licenseComp = <></>;
    if (licenseData && licenseError) {
      if (step === 3) {
        // 대상자 라이선스 체크
        if (licenseData.error === 'noLicense') {
          licenseComp = (
            <div>
              {formatMessage({
                id: 'License_3',
                defaultMessage: '라이선스가 존재하지 않습니다.',
              })}
            </div>
          );
        } else if (licenseData.error === 'noReserve') {
          licenseComp = (
            <div>
              {formatMessage({
                id: 'License_6',
                defaultMessage: '훈련 시작일을 라이선스 유효기간 이후로 예약할 수 없습니다.',
              })}
            </div>
          );
        } else {
          let periodCount = 0;
          let timesCount = 0;
          const { periodCnt, timesCnt } = licenseData;
          licenseData.list.forEach((license: any) => {
            const {
              licenseType,
              licenseNo,
              remainCount,
              licenseCount,
              startEpoch,
              endEpoch,
              examLimitCount,
            } = license;

            if (licenseType === 1 || licenseType === 2) {
              periodCount += 1;
            } else if (licenseType === 4) {
              timesCount += 1;
            }

            licenseComp = (
              <div>
                <div>
                  {formatMessage({
                    id: 'License_4',
                    defaultMessage: '라이선스의 수량이 부족합니다.',
                  })}
                </div>
                <div className="license-status-row">
                  {(licenseType === 1 || licenseType === 2) && (
                    <div>
                      {periodCount === 1 && (
                        <div className="license-name">
                          {`· ${formatMessage({
                            id: 'License_7',
                            defaultMessage: '기간 라이선스',
                          })} (${periodCnt}${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })} ${formatMessage({
                            id: 'License_9',
                            defaultMessage: '부족',
                          })})`}
                        </div>
                      )}
                      <div className="license-detail">
                        <div className="license-number">No.{licenseNo}</div>
                        <div className="license-target">{` ${
                          licenseCount - remainCount
                        }/${licenseCount}${formatMessage({
                          id: 'StartExam_35',
                          defaultMessage: '명',
                        })}`}</div>
                        <div className="license-period">{` ${timeFormatFromUTCEpoch(
                          startEpoch,
                          3,
                        )} ~ ${timeFormatFromUTCEpoch(endEpoch, 3)}`}</div>
                      </div>
                    </div>
                  )}

                  {licenseType === 4 && (
                    <div>
                      {timesCount === 1 && (
                        <div className="license-name">
                          {`· ${formatMessage({
                            id: 'License_8',
                            defaultMessage: '횟수 라이선스',
                          })} (${timesCnt}${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })} ${formatMessage({
                            id: 'License_9',
                            defaultMessage: '부족',
                          })})`}
                        </div>
                      )}
                      <div className="license-detail">
                        <div>No.{licenseNo}</div>
                        <div>
                          {` ${licenseCount}${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })}`}
                        </div>
                        <div>
                          {` ${examLimitCount - remainCount}/${examLimitCount}${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '회',
                          })}`}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          });
        }
      } else if (step === 4) {
        licenseData.list.forEach((license: any) => {
          const { licenseNo, remainCount, licenseCount, examLimitCount } = license;

          // 훈련 라이선스 체크
          licenseComp = (
            <div>
              {formatMessage({
                id: 'License_5',
                defaultMessage: '라이선스의 남은 횟수가 부족합니다.',
              })}
              <div className="license-status-row">
                <div className="license-item">No.{licenseNo}</div>
                <div className="license-item">
                  {`${formatMessage({
                    id: 'License_16',
                    defaultMessage: '수량',
                  })}: ${licenseCount}${formatMessage({
                    id: 'StartExam_35',
                    defaultMessage: '명',
                  })}`}
                </div>
                <div className="remain-count">
                  {`${examLimitCount - remainCount} / ${examLimitCount}${formatMessage({
                    id: 'License_13',
                    defaultMessage: '회',
                  })}`}
                  <span className="font-size-13 bold">
                    {` (${formatMessage({
                      id: 'License_10',
                      defaultMessage: '남은 횟수',
                    })}: ${remainCount}${formatMessage({
                      id: 'License_13',
                      defaultMessage: '회',
                    })})`}
                  </span>
                </div>
              </div>
            </div>
          );
        });
      }
    }
    return licenseComp;
  }, [licenseError]);

  // 라이선스 정보 조회
  const onGetExamLicense = async (moveStep: number) => {
    setLoading(true);

    const {
      startDate,
      isAllTarget,
      examTargetTag,
      exceptExamTargetTag,
      examTemplate,
    } = formStoreData;
    try {
      const params: any = {
        examStartEpoch: moment(startDate).unix().toString(),
      };

      if (isAllTarget) {
        params.isAllTarget = isAllTarget;
      } else {
        params.examTargetTagArray = examTargetTag.join(',');
      }

      if (exceptExamTargetTag.length > 0) {
        params.exceptExamTargetTagArray = exceptExamTargetTag.join(',');
      }

      if (step === 4) {
        const serviceTempArray: any = [];
        const examTempArray: any = [];
        examTemplate.forEach((value: any) => {
          const splitTempNo = value.split('+');
          if (splitTempNo[1]) {
            serviceTempArray.push(splitTempNo[1]);
          } else {
            examTempArray.push(splitTempNo[0]);
          }
        });

        params.examServiceTemplateNoArray = JSON.stringify(serviceTempArray);
        params.examTemplateNoArray = JSON.stringify(examTempArray);
      }

      const response: any = await dispatch(getLicense(params));
      if (response?.data) {
        const { data } = response;
        if (data.isValidLicense === 1) {
          if (step === 3) {
            if (data.targetAllCount > 0) {
              onGetTemplate(moveStep);
            } else {
              setError('examTargetTag', {
                type: 'required',
              });
              setErrorMessage(
                formatMessage({ id: 'Tag_24', defaultMessage: '태그에 할당된 대상자가 없습니다.' }),
              );
              setLoading(false);
            }

            // 랜덤발송일때 템플릿 수가 대상자수보다 많은지 체크하기 위해 총 대상자 수 저장
            setTargetAllCount(data.targetAllCount);
          } else if (step === 4) {
            setLoading(false);

            if (formStoreData.randomSend === '1') {
              const templateCount =
                JSON.parse(params.examServiceTemplateNoArray).length +
                JSON.parse(params.examTemplateNoArray).length;

              // 템플릿수가 총대상자수보다 많으면 에러 메시지 표시
              if (data.targetAllCount < templateCount) {
                setErrorMessage(
                  `${formatMessage({
                    id: 'StartExam_69',
                    defaultMessage: '템플릿 개수는 총 대상자 수보다 많을 수 없습니다.',
                  })} (${formatMessage({ id: 'Target_34', defaultMessage: '총 대상자 수' })}: ${
                    data.targetAllCount
                  })`,
                );
              } else {
                // 요약 정보 모달 띄움
                setResultOpenModal(true);
              }
            } else {
              // 요약 정보 모달 띄움
              setResultOpenModal(true);
            }
          }

          setLicenseRes(data);
        } else {
          setLoading(false);
          setLicenseError(true);
        }
      }
    } catch (error) {
      console.log('StartExam onGetExamLicense', error);
    }
  };

  // STEP4 템플릿, 첨부파일 정보 조회
  const onGetTemplate = async (moveStep: number) => {
    setLoading(true);
    try {
      const params = {
        type: 'startExam',
      };
      await dispatch(getTemplate(params));
      await dispatch(getAttach());
      await dispatch(getSystemUrl());

      setLoading(false);
      setStep(moveStep);
    } catch (error) {
      console.log('StartExam onGetTemplate', error);
    }
  };

  // 훈련 실행
  const onStartExam = async () => {
    setLoading(true);
    const {
      examName,
      startDate,
      endDate,
      sendReserveEnable,
      sendReserveHour,
      sendPeriod,
      examTargetRandomPer,
      isAllTarget,
      examTargetTag,
      exceptExamTargetTag,
      examTemplate,
      useExamSystemUrl,
      examSystemUrl,
      useAttachScreenLock,
      attachScreenLockDate,
      attachScreenLockHour,
      attachScreenLockMin,
      randomSend,
    } = formStoreData;

    try {
      const params: any = {
        examName: JSON.stringify(examName),
        // examStartEpoch: moment(startDate).unix().toString(),
        // examEndEpoch: moment(endDate).unix().toString(),
        examStartEpoch: epochFromDate(startDate),
        examEndEpoch: epochFromDate(endDate),
        examTargetRandomPer: examTargetRandomPer,
      };

      if (isAllTarget) {
        // 모든 대상자 선택 여부
        params.isAllTarget = true;
      } else {
        // 대상자 태그
        params.examTargetTagArray = examTargetTag.join(',');
      }

      // 예외 태그
      if (exceptExamTargetTag.length > 0) {
        params.exceptExamTargetTagArray = exceptExamTargetTag;
      }

      // 랜덤 발송
      if (randomSend === '1') {
        params.randomSend = randomSend;
      }

      // 훈련 메일 나눔 발송
      if (sendReserveEnable === '1') {
        // 나눔 발송 기간
        params.sendReservePeriod = sendPeriod;
        // 나눔 발송 시간
        const [start, end] = sendReserveHour;
        params.sendReserveStartHour = start;
        params.sendReserveEndHour = end;
      }

      // 잠금 화면 실행 시간
      if (useAttachScreenLock === '1') {
        params.attachScreenLockEpoch =
          moment(attachScreenLockDate).unix() + attachScreenLockHour + attachScreenLockMin;
      }

      // 훈련 템플릿
      if (examTemplate.length > 0) {
        const serviceTempArray: any = [];
        const examTempArray: any = [];
        examTemplate.forEach((value: any) => {
          const splitTempNo = value.split('+');
          if (splitTempNo[1]) {
            serviceTempArray.push(splitTempNo[1]);
          } else {
            examTempArray.push(splitTempNo[0]);
          }
        });

        params.examServiceTemplateNoArray = JSON.stringify(serviceTempArray);
        params.examTemplateNoArray = JSON.stringify(examTempArray);
      }

      // 훈련 url 사용
      if (useExamSystemUrl === '1' && !!examSystemUrl) {
        params.examUrl = examSystemUrl;
      }

      // 재훈련
      if (loadData) {
        params.retraining = true;
      }

      setResultOpenModal(false);
      await dispatch(startExam(params));
      setLoading(false);
    } catch (error) {
      console.log('StartExam onStartExam', error);
    }
  };

  // 단계 바뀔 시 수행 이벤트
  const changeStepAction = (formData: any, moveStep: number) => {
    const changedData: any = {};
    Object.keys(formData).map((key: any) => {
      if (formData[key]) {
        changedData[key] = formData[key];
      }
      return changedData;
    });
    // 변경된 데이터 저장
    onSaveForm(changedData);

    if (step === 1) {
      setStep(moveStep);
    } else if (step === 2) {
      onCheckExamName(formData, moveStep);
    } else if (step === 3) {
      if (formStoreData.examTargetTag?.length < 1 && !formStoreData.isAllTarget) {
        setError('examTargetTag', {
          type: 'required',
          message: 'required',
        });
      } else {
        onGetExamLicense(moveStep);
      }
    } else if (step === 4) {
      onGetExamLicense(moveStep);
    }
  };

  // 에러, 폼 값 초기화
  const onInit = async () => {
    clearErrors();
    setStep(1);
    await dispatch(initForm());
  };

  // 이전 클릭 이벤트
  const prevStep = (formData: any) => {
    // 이전 단계로 이동
    setStep(step - 1);
  };

  // 다음 클릭 이벤트
  const nextStep = (formData: any) => {
    if (Object.keys(errors).length > 0) return;
    // 다음 단계 이동 전 validation 체크
    // changeStepAction(formData, step + 1);
    changeStepAction(formStoreData, step + 1);
  };

  // 현재 입력 값 폼 스토어에 저장
  const onSaveForm = async (newForm: any) => {
    await dispatch(saveForm({ ...formStoreData, ...newForm }));
  };

  // 폼 입력 감지 이벤트
  const onChangeForm = (e: any) => {
    if (Object.keys(errors).length > 0) {
      // 에러 초기화
      clearErrors([...Object.keys(getValues())]);
    }

    onSaveForm({ [e.target.name]: e.target.value });
  };

  // 페이지 이동
  const moveRouter = (path: string) => {
    onCloseExamModal();
    history.push(path);
  };

  // 모달 닫기
  const onCloseExamModal = () => {
    if (onCloseModal) {
      onCloseModal();
      setShowModal(false);
    } else {
      setShowModal(false);
    }
  };

  // 단계별 header 내용
  const stepHeaderInfo = [
    {
      title: formatMessage({
        id: 'StartExam_2',
        defaultMessage: '환경 검사',
      }),
      subTitle: formatMessage({
        id: 'StartExam_7',
        defaultMessage: '훈련이 원활하게 수행될 수 있도록 관련 설정을 검사합니다.',
      }),
      // imageUrl: step1Image,
    },
    {
      title: formatMessage({
        id: 'StartExam_3',
        defaultMessage: '일반 설정',
      }),
      subTitle: formatMessage({
        id: 'StartExam_8',
        defaultMessage:
          '<div class="modal-sub-title"><span class="point">훈련명 및 훈련기간</span>을 입력합니다.</div>',
      }),
      // imageUrl: step2Image,
    },
    {
      title: formatMessage({
        id: 'StartExam_4',
        defaultMessage: '대상자 지정',
      }),
      subTitle: formatMessage({
        id: 'StartExam_9',
        defaultMessage:
          '<div class="modal-sub-title"><span class="point">태그</span>를 입력하여 훈련 대상자를 지정합니다.</div>',
      }),
      // imageUrl: step3Image,
    },
    {
      title: formatMessage({
        id: 'StartExam_5',
        defaultMessage: '훈련 양식',
      }),
      subTitle: formatMessage({
        id: 'StartExam_10',
        defaultMessage:
          '<div class="modal-sub-title"><span class="point">훈련 양식(메일 내용 및 첨부 종류 등)</span>을 선택합니다.</div>',
      }),
      // imageUrl: step4Image,
    },
    {
      title: formatMessage({
        id: 'StartExam_5',
        defaultMessage: '훈련 실행이 완료되었습니다.',
      }),
      subTitle: '',
      // imageUrl: step5Image,
    },
  ];

  // 3단계까지의 정보 요약
  const resultSummary: any = {};
  if (formStoreData) {
    const formatStartDate = moment(formStoreData.startDate).format('YYYY-MM-DD');
    const formatEndDate = moment(formStoreData.endDate).format('YYYY-MM-DD');

    Object.keys(formStoreData).forEach((key: any, index: number) => {
      if (key === 'examName') {
        resultSummary['0'] = {
          title: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
          text: formStoreData.examName,
        };
      } else if (key === 'startDate') {
        resultSummary['1'] = {
          title: formatMessage({ id: 'Period_3', defaultMessage: '훈련 기간' }),
          text: `${formatStartDate} ~ ${formatEndDate} ${
            moment(formStoreData.startDate) > moment()
              ? formatMessage({ id: 'StartExam_59', defaultMessage: '(예약됨)' })
              : ''
          }`,
        };
      } else if (key === 'sendReserveEnable' && formStoreData[key] === '1') {
        const endDate = moment(formStoreData.startDate)
          .add(formStoreData.sendPeriod - 1, 'd')
          .format('YYYY-MM-DD');
        const period =
          formStoreData.sendPeriod === 1 ? formatStartDate : `${formatStartDate} ~ ${endDate}`;

        resultSummary['3'] = {
          title: formatMessage({ id: 'Send_3', defaultMessage: '훈련 메일 나눔 발송' }),
          text: `${period} (${formStoreData.sendReserveHour[0]}${formatMessage({
            id: 'Time_1',
            defaultMessage: '시',
          })} ~ ${formStoreData.sendReserveHour[1]}${formatMessage({
            id: 'Time_1',
            defaultMessage: '시',
          })})`,
        };
      } else if (key === 'useAttachScreenLock' && formStoreData[key] === '1') {
        const date = moment(formStoreData.attachScreenLockDate).format('YYYY-MM-DD');

        resultSummary['4'] = {
          title: formatMessage({ id: 'StartExam_52', defaultMessage: 'PC 잠금 화면 실행 시간' }),
          text: `${date} ${
            formStoreData.attachScreenLockHour > 0
              ? fitTwoDigit(formStoreData.attachScreenLockHour / 3600)
              : 0
          }:${
            formStoreData.attachScreenLockMin > 0
              ? fitTwoDigit(formStoreData.attachScreenLockMin / 60)
              : 0
          }`,
        };
      }

      resultSummary['2'] = {
        title: formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' }),
        text: `${
          formStoreData.examTargetRandomPer < 100
            ? Math.ceil((licenseRes?.targetAllCount * formStoreData.examTargetRandomPer) / 100)
            : licenseRes?.targetAllCount
        }${formatMessage({
          id: 'StartExam_35',
          defaultMessage: '명',
        })}`,
      };
    });
  }

  return (
    <div>
      <ModalTemplate
        className="start-exam-modal modal-665"
        isCloseBlack
        visible={showModal}
        title={
          <div className="modal-title-area">
            {step !== 5 && (
              <>
                <div className="modal-main-title">{stepHeaderInfo[step - 1].title}</div>
                <div className="modal-sub-title">{parse(stepHeaderInfo[step - 1].subTitle)}</div>
                <div className="step-area">
                  <img src={`/img/exam/startExam/step${step}.png`} alt="step-progress" />
                </div>
              </>
            )}

            {step === 5 && (
              <>
                <div className="exam-complete">
                  <div className="step-area">
                    <img src={`/img/exam/startExam/step${step}.png`} alt="step-progress" />
                  </div>
                  <div className="modal-title">
                    {resultData.error && Object.keys(resultData.error)?.length > 0 ? (
                      <>
                        {formatMessage({
                          id: 'StartExam_61',
                          defaultMessage: '오류가 발생하여 훈련이 실행되지 않았습니다.',
                        })}
                        <div className="modal-text">{JSON.stringify(resultData.error)}</div>
                      </>
                    ) : (
                      <div className="flex">
                        {parse(
                          formatMessage({
                            id: 'StartExam_11',
                            defaultMessage:
                              "훈련 실행이 <div class='complete'><span class='text'>완료</span><span class='highlighter'/></div>되었습니다.",
                          }),
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        }
        onCancel={onCloseExamModal}
        footer={
          <>
            {step > 1 && step !== 5 && (
              <button key="cancel" className="footer-btn" type="submit" onClick={prevStep}>
                <img src="/img/exam/startExam/btn_prev.png" alt="prev" />
                {formatMessage({ id: 'Button_6', defaultMessage: '이 전' })}
              </button>
            )}
            {step !== 5 && (
              <button
                key="ok"
                className={`footer-btn ok ${
                  !examPrepareData[0]?.licenseDetailEnc || Object.keys(errors).length > 0 || loading
                    ? 'disabled'
                    : ''
                }`}
                type="submit"
                onClick={handleSubmit(nextStep)}
              >
                {step === 4
                  ? formatMessage({ id: 'StartExam_67', defaultMessage: '실 행' })
                  : formatMessage({ id: 'Button_7', defaultMessage: '다 음' })}
                <img src="/img/exam/startExam/btn_next.png" alt="next" />
              </button>
            )}
            {step === 5 && (
              <button
                key="ok"
                className={`footer-btn ok ${Object.keys(errors).length > 0 ? 'disabled' : ''}`}
                type="submit"
                onClick={onCloseExamModal}
              >
                {formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
              </button>
            )}
          </>
        }
      >
        {/* 로딩 */}
        <Loading loading={loading} />

        {/* STEP 1 */}
        {step === 1 && (
          <StartExamStep1
            prepareData={examPrepareData[0]}
            isFail={isFail}
            moveRouter={moveRouter}
          />
        )}

        <form autoComplete="off" onChange={onChangeForm}>
          {/* STEP 2 */}
          {step === 2 && (
            <StartExamStep2
              register={register}
              errors={errors}
              control={control}
              onSaveForm={onSaveForm}
              setValue={setValue}
              loadData={loadData}
              type={type}
            />
          )}

          {/* STEP 3 */}
          {step === 3 && (
            <StartExamStep3
              register={register}
              errors={errors}
              control={control}
              onSaveForm={onSaveForm}
              loading={loading}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              loadData={loadData}
            />
          )}

          {/* STEP 4 */}
          {step === 4 && (
            <StartExamStep4
              register={register}
              errors={errors}
              control={control}
              onSaveForm={onSaveForm}
              loading={loading}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              loadData={loadData}
            />
          )}

          {/* STEP 5 */}
          {step === 5 && resultData.success && resultData.success[0] && (
            <StartExamStep5
              register={register}
              errors={errors}
              control={control}
              onSaveForm={onSaveForm}
              loading={loading}
              result={resultData.success[0]}
              moveRouter={moveRouter}
            />
          )}
        </form>

        {/* 라이선스 오류 표시 */}
        {licenseError && (
          <div className="license-error">
            <div className="license-error-content">
              {step === 3 && (
                <div className="license-error-text">
                  <img src="/img/exam/startExam/ico_fail_s.png" alt="fail" />
                  {formatMessage({
                    id: 'StartExam_60',
                    defaultMessage: '선택한 대상자의 라이선스가 필요합니다.',
                  })}
                </div>
              )}
              <div className="license-error-popup">
                <div className="popup-header">
                  {formatMessage({
                    id: 'License_2',
                    defaultMessage: '라이선스 현황',
                  })}
                </div>
                <div className="popup-content">{licenseContent}</div>
              </div>
            </div>
          </div>
        )}
      </ModalTemplate>

      {/* 훈련실시전 요약내용 출력 모달 */}
      {resultOpenModal && (
        <ModalTemplate
          className="result-summary-modal modal-464"
          visible={resultOpenModal}
          title={formatMessage({ id: 'StartExam_62', defaultMessage: '훈련 내용 요약' })}
          onCancel={() => setResultOpenModal(false)}
          onOk={() => {
            onStartExam();
            setStep(5);
          }}
          okText={formatMessage({ id: 'StartExam_67', defaultMessage: '실 행' })}
          cancelText={formatMessage({ id: 'Button_5', defaultMessage: '닫 기' })}
        >
          {Object.keys(resultSummary)
            .sort()
            .map((key: any) => {
              return (
                <div className="result-summary-row" key={resultSummary[key].title}>
                  <div className="result-summary-title">· {resultSummary[key].title}</div>
                  <div className="result-summary-text">{resultSummary[key].text}</div>
                </div>
              );
            })}
        </ModalTemplate>
      )}
    </div>
  );
}

export default withRouter(StartExam);
