import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'target/SET_INITIAL';
const GET_TARGET = 'target/GET_TARGET';
const PUT_TARGET = 'target/PUT_TARGET';
const ADD_TARGET = 'target/ADD_TARGET';
const DELETE_TARGET = 'target/DELETE_TARGET';
const PUT_TAG_TARGET = 'target/PUT_TAG_TARGET';
const POST_TAG_TARGET = 'target/POST_TAG_TARGET';
const DELETE_TAG_TARGET = 'target/DELETE_TAG_TARGET';
const GET_MERGE_DETAIL = 'target/GET_MERGE_DETAIL';
const GET_EXAM_HISTORY = 'target/GET_EXAM_HISTORY';
const GET_EDU_HISTORY = 'target/GET_EDU_HISTORY';
const CHANGE_PARAM = 'target/CHANGE_PARAM';
const CHANGE_TARGET_STATE = 'target/CHANGE_TARGET_STATE';
const CHANGE_TARGET_EXAM_STATE = 'target/CHANGE_TARGET_EXAM_STATE';
const GET_RESULT_TARGET = 'template/GET_RESULT_TARGET';
const CHANGE_RESULT_TARGET_STATE = 'template/CHANGE_RESULT_TARGET_STATE';

// Actions
// 대상자 리스트 조회
export const getTarget = (params?: { [key: string]: any }) =>
  actionFormat(GET_TARGET, params, 'get', `${apiUrl}Target`);
// 대상자 정보, 메모 수정
export const putTarget = (params: { [key: string]: any }) =>
  actionFormat(PUT_TARGET, params, 'put', `${apiUrl}Target`);
// 대상자 등록
export const addTarget = (params: { [key: string]: any }) =>
  actionFormat(ADD_TARGET, params, 'post', `${apiUrl}Target`);
// 대상자 삭제
export const deleteTarget = (params: { [key: string]: any }) =>
  actionFormat(DELETE_TARGET, params, 'delete', `${apiUrl}Target`);
// 대상자 태그 수정
export const putTagTarget = (params: { [key: string]: any }) =>
  actionFormat(PUT_TAG_TARGET, params, 'put', `${apiUrl}TagTarget`);
// 대상자 태그 일괄 등록
export const postTagTarget = (params: { [key: string]: any }) =>
  actionFormat(POST_TAG_TARGET, params, 'post', `${apiUrl}TagTarget`);
// 대상자 태그 일괄 삭제
export const deleteTagTarget = (params: { [key: string]: any }) =>
  actionFormat(DELETE_TAG_TARGET, params, 'delete', `${apiUrl}TagTarget`);
// 대상자 일괄 등록(엑셀파일 업로드)
export const uploadTargetExcel = (params: { [key: string]: any }) =>
  actionFormat(null, params, 'post', `${apiUrl}Target/Upload`, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
// 대상자 내보내기(엑셀파일 다운로드)
export const exportTarget = (params: { [key: string]: any }) =>
  actionFormat(null, params, 'post', `${apiUrl}Target/Export`, {
    headers: { 'Content-Type': 'application/vnd.openxmlformats' },
    responseType: 'arraybuffer',
  });
// 다중 대상자 훈련 상세
export const getMergeDetail = (params: { [key: string]: any }) =>
  actionFormat(GET_MERGE_DETAIL, params, 'get', `${apiUrl}Target/MergeDetail`);
// 개별 대상자 훈련 이력
export const getExamHistory = (params: { [key: string]: any }) =>
  actionFormat(GET_EXAM_HISTORY, params, 'get', `${apiUrl}Target/ExamHistory`);
// 개별 대상자 교육 이력
export const getEduHistory = (params: { [key: string]: any }) =>
  actionFormat(GET_EDU_HISTORY, params, 'get', `${apiUrl}Edu/EduHistory`);
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');
export const changeTargetState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_TARGET_STATE, params, null, '');
export const changeTargetExamState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_TARGET_EXAM_STATE, params, null, '');
export const changeResultTargetState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_RESULT_TARGET_STATE, params, null, '');

// 훈련결과 전체 훈련 - 대상자탭 리스트 조회
export const getResultTarget = (params?: { [key: string]: any }) =>
  actionFormat(GET_RESULT_TARGET, params, 'get', `${apiUrl}Target`);

// initial state
interface stateType {
  data: { [key: string]: any };
  dataByTargetNo: any;
  targetFilter: any;
  param: { [key: string]: any };
  needReload: boolean;
  tagReload: boolean;
  page: number;
  totalPages: number;
  totalCount: number;
  selectType: string;
  selectedList: any;
  editInfo: any;
  isAllTargetCheck: boolean;

  mergeDetail: any;
  targetExam: any;
  targetEdu: any;

  resultTarget: {
    data: Array<any>;
    param: { [key: string]: any };
    needReload: boolean;
    page: number;
    totalPages: number;
    totalCount: number;
  };
}

const initialState: stateType = {
  data: [],
  dataByTargetNo: {},
  targetFilter: {},
  param: {
    filter: {},
    sort: [{ field: 'targetNo', order: 'DESC' }],
    limit: 30,
  },
  needReload: false,
  tagReload: false,
  page: 1,
  totalPages: 0,
  totalCount: 0,
  selectType: 'select',
  selectedList: [],
  editInfo: { editTarget: '', editValue: '' },
  isAllTargetCheck: false,

  // 다중 대상자 상세 정보
  mergeDetail: {},

  // 대상자 훈련 이력
  targetExam: {
    data: [],
    param: {
      filter: {},
      sort: [{ field: 'examNo', order: 'DESC' }],
      limit: 5,
    },
    needReload: false,
    page: 1,
    totalPages: 0,
    totalCount: 0,
    openList: [],
    chartData: {},
  },

  // 대상자 교육 이력
  targetEdu: {
    data: [],
    param: {
      filter: {},
      sort: [{ field: 'eduNo', order: 'DESC' }],
      limit: 5,
    },
    needReload: false,
    page: 1,
    totalPages: 0,
    totalCount: 0,
  },

  // 훈련결과 전체훈련-대상자 데이터
  resultTarget: {
    data: [],
    // dataByTargetNo: {},
    // targetFilter: {},
    param: {
      filter: {},
      sort: [{ field: 'targetNo', order: 'DESC' }],
      limit: 30,
      offset: 0,
    },
    needReload: false,
    // tagReload: false,
    page: 1,
    totalPages: 0,
    totalCount: 0,
  },
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_TARGET:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit, refresh } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        let curPage = 1;
        let curDataList: any = [];
        let curDataObj: any = {};

        if (data.list?.length > 0) {
          if (refresh) {
            state.totalPages = totalPages;
            state.totalCount = totalCount;
            curDataList = data.list;
            data.list.forEach((item: any) => {
              curDataObj[item.targetNo] = item;
            });

            state.tagReload = false;
            state.selectType = 'select';
            state.selectedList = [];
            state.editInfo = { editTarget: '', editValue: '' };
            state.isAllTargetCheck = 0;
          } else {
            // 무한스크롤
            curPage = state.page + 1;
            curDataList = state.data.concat(data.list);
            data.list.forEach((item: any) => {
              curDataObj[item.targetNo] = item;
            });
            curDataObj = { ...state.dataByTargetNo, ...curDataObj };
          }
        } else {
          state.totalPages = 0;
          state.totalCount = 0;
          state.isAllTargetCheck = 0;
        }

        // 소속/직급 필터
        if (data.filter) {
          state.targetFilter = data.filter;
        }

        state.data = curDataList;
        state.dataByTargetNo = curDataObj;
        state.page = curPage;
        state.needReload = false;

        // if (!data.error && data.list && Array.isArray(data.list)) {
        //   state.data = {
        //     result: 1,
        //     success: data.list,
        //     fail: [],
        //   };
        // } else if (data.error && Array.isArray(data.error)) {
        //   state.data = {
        //     result: 0,
        //     success: [],
        //     fail: data.errror,
        //   };
        // }

        // state.needReload = false;
        // state.totalPages = totalPages;
        // state.totalCount = totalCount;
      }
      return state;
    case GET_MERGE_DETAIL:
      if (action.payload) {
        const { list } = action.payload.data;
        const [first] = list;
        state.mergeDetail = first;
      }
      return state;
    case PUT_TARGET:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case ADD_TARGET:
      if (!action.payload.data.error) {
        state.needReload = true;
        state.tagReload = true;
      }
      return state;
    case DELETE_TARGET:
      if (action.payload) {
        state.needReload = true;
        state.tagReload = true;
      }
      return state;
    case GET_EXAM_HISTORY:
      if (action.payload) {
        const { list, totalData } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataList = [];

        if (list?.length > 0) {
          if (refresh) {
            state.targetExam.totalPages = Math.ceil(list[0].rnum / limit);
            state.targetExam.totalCount = list[0].rnum;
            curDataList = list;
          } else {
            // 무한스크롤
            curPage = state.targetExam.page + 1;
            curDataList = state.targetExam.data.concat(list);
          }
        } else {
          state.targetExam.totalPages = 0;
          state.targetExam.totalCount = 0;
        }

        state.targetExam.data = curDataList;
        state.targetExam.page = curPage;
        state.targetExam.needReload = false;
        const [first] = totalData;
        state.targetExam.chartData = first;
      }
      return state;
    case GET_EDU_HISTORY:
      if (action.payload) {
        const { list } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataList = [];

        if (list?.length > 0) {
          if (refresh) {
            state.targetEdu.totalPages = Math.ceil(list[0].rnum / limit);
            state.targetEdu.totalCount = list[0].rnum;
            curDataList = list;
          } else {
            // 무한스크롤
            curPage = state.targetEdu.page + 1;
            curDataList = state.targetEdu.data.concat(list);
          }
        } else {
          state.targetEdu.totalPages = 0;
          state.targetEdu.totalCount = 0;
        }

        state.targetEdu.data = curDataList;
        state.targetEdu.page = curPage;
        state.targetEdu.needReload = false;
      }
      return state;
    case PUT_TAG_TARGET:
      if (action.payload) {
        state.needReload = true;
        state.tagReload = true;
      }
      return state;
    case POST_TAG_TARGET:
      if (action.payload) {
        state.needReload = true;
        state.tagReload = true;
      }
      return state;
    case DELETE_TAG_TARGET:
      if (action.payload) {
        state.needReload = true;
        state.tagReload = true;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    case CHANGE_TARGET_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state[key] = value;
      }
      return state;
    case CHANGE_TARGET_EXAM_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state.targetExam[key] = value;
      }
      return state;
    case CHANGE_RESULT_TARGET_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state.resultTarget[key] = value;
      }
      return state;
    case GET_RESULT_TARGET:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit, refresh } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        let curPage = 1;
        let curDataList: any = [];

        if (data.list?.length > 0) {
          if (refresh) {
            state.resultTarget.totalPages = totalPages;
            state.resultTarget.totalCount = totalCount;
            curDataList = data.list;
          } else {
            // 무한스크롤
            curPage = state.resultTarget.page + 1;
            curDataList = state.resultTarget.data.concat(data.list);
          }
        } else {
          state.resultTarget.totalPages = 0;
          state.resultTarget.totalCount = 0;
        }

        state.resultTarget.data = curDataList;
        state.resultTarget.page = curPage;
        state.resultTarget.needReload = false;
      }
      return state;
    default:
      return state;
  }
}
