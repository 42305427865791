import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { changeListState, getExamList, getExamChart } from 'store/exam';
import ContentList from 'components/common/ContentList';
import SearchFilter from 'components/common/SearchFilter';
import ExamItemEach from 'components/branch/exam/ExamItemEach';
import ExamChart from 'components/branch/exam/ExamChart';

function Tag() {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { errors, register, handleSubmit } = useForm();
  const examSummary = useSelector((state: any) => {
    return {
      data: state.exam.examSummary.data,
      dataByExamNo: state.exam.examSummary.dataByExamNo,
      param: state.exam.examSummary.param,
      selectedExamNo: state.exam.examSummary.selectedExamNo,
      needReload: state.exam.examSummary.needReload,
    };
  }, shallowEqual);
  const examList = useSelector((state: any) => {
    return {
      data: state.exam.examList.data,
      dataByExamNo: state.exam.examList.dataByExamNo,
      param: state.exam.examList.param,
      needReload: state.exam.examList.needReload,
      page: state.exam.examList.page,
      totalPage: state.exam.examList.totalPage,
      selectedList: state.exam.examList.selectedList,
      selectType: state.exam.examList.selectType,
    };
  }, shallowEqual);

  useEffect(() => {
    onGetExamList(true);
    onGetExamChart();
  }, [examSummary.param, examSummary.selectedExamNo, examSummary.needReload, examList.param]);

  // 훈련 리스트 조회
  const onGetExamList = async (refresh = false) => {
    if (loading) return;
    setLoading(true);

    try {
      const params: any = {
        examNo: examSummary.selectedExamNo,
        subTab: 'tag',
        filter: JSON.stringify(examList.param.filter),
        sort: JSON.stringify(examList.param.sort),
        offset: 0,
        limit: examList.param.limit,
      };

      if (Object.keys(examSummary?.param?.filter).length > 0) {
        params.examFilter = JSON.stringify(examSummary.param.filter);
      }

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = examList.param.limit * examList.page;
      }

      await dispatch(getExamList(params));
      setLoading(false);
    } catch (error) {
      console.log('Tag onGetExamList', error);
    }
  };

  // 차트 데이터 조회
  const onGetExamChart = async () => {
    try {
      setLoading(true);
      const params = {
        examNo: examSummary.selectedExamNo,
        subTab: 'tag',
      };

      await dispatch(getExamChart(params));
      setLoading(false);
    } catch (error) {
      console.log('Tag onExamChart', error);
    }
  };

  // 선택한 훈련 요약 데이터
  const examData = examSummary.dataByExamNo[examSummary.selectedExamNo];

  // 훈련 리스트 데이터 키 목록
  const dataKeyList: number[] = [];
  Object.keys(examList.dataByExamNo).forEach((key: any) => {
    dataKeyList.push(parseInt(key, 10));
  });

  // 훈련 선택
  const onSelectItem = useCallback(
    (no: number) => {
      let select = [...examList.selectedList];
      if (examList.selectedList.includes(no)) {
        select = [];
      } else if (no) {
        select = [no];
      }
      dispatch(changeListState({ key: 'selectType', value: 'select' }));
      dispatch(changeListState({ key: 'selectedList', value: select }));
    },
    [examList.selectedList],
  );

  // 훈련 요약에 있는 데이터와 비교하여 같은 것만 리스트에 추가
  const examDataList = useMemo(
    () =>
      examList.data.filter((item: any) =>
        Object.keys(examSummary.dataByExamNo).includes(item.examNo.toString()),
      ),
    [examList.data, examSummary.dataByExamNo],
  );

  // 메시지 데이터
  const messageData: any = {
    infection: formatMessage({ id: 'Target_4', defaultMessage: '위험 대상자' }),
    send: formatMessage({ id: 'Send_1', defaultMessage: '발송' }),
    sendReserve: formatMessage({ id: 'Send_4', defaultMessage: '발송 예약' }),
    sendError: formatMessage({ id: 'Send_5', defaultMessage: '발송 실패' }),
    read: formatMessage({ id: 'Read_1', defaultMessage: '열람' }),
    connect: formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' }),
    phishing: formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' }),
    fileDown: formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' }),
    fileInfection: formatMessage({ id: 'Infection_1', defaultMessage: '감염' }),
    infectionPc: formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' }),
    cure: formatMessage({ id: 'Cure_1', defaultMessage: '치료' }),
    targetEmail: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
    targetName: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
    leakFileCount: formatMessage({ id: 'Infection_30', defaultMessage: 'PC 파일' }),
    leakCertCount: formatMessage({ id: 'Infection_31', defaultMessage: '인증서' }),
    leakEmailCount: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
  };

  // 개별 훈련 선택 - 태그 탭 정렬
  const targetSort =
    examData?.examType === 4
      ? {
          send: messageData.send,
          sendReserve: messageData.sendReserve,
          read: messageData.read,
          connect: messageData.connect,
          phishing: messageData.phishing,
          targetEmail: messageData.targetEmail,
          targetName: messageData.targetName,
        }
      : {
          send: messageData.send,
          sendReserve: messageData.sendReserve,
          read: messageData.read,
          fileDown: messageData.fileDown,
          fileInfection: messageData.fileInfection,
          infectionPc: messageData.infectionPc,
          cure: messageData.cure,
          targetEmail: messageData.targetEmail,
          targetName: messageData.targetName,
        };

  // 개별 훈련 선택 - 태그 탭 필터
  const targetFilter =
    examData?.examType === 4
      ? {
          targetExamArray: {
            name: formatMessage({ id: 'Exam_37', defaultMessage: '훈련 결과' }),
            child: [
              { label: messageData.send, value: 'send' },
              { label: messageData.sendReserve, value: 'sendReserve' },
              { label: messageData.sendError, value: 'sendError' },
              { label: messageData.read, value: 'read' },
              { label: messageData.connect, value: 'connect' },
              { label: messageData.phishing, value: 'phishing' },
            ],
          },
        }
      : {
          targetExamArray: {
            name: formatMessage({ id: 'Exam_37', defaultMessage: '훈련 결과' }),
            child: [
              { label: messageData.send, value: 'send' },
              { label: messageData.sendReserve, value: 'sendReserve' },
              { label: messageData.sendError, value: 'sendError' },
              { label: messageData.read, value: 'read' },
              { label: messageData.fileDown, value: 'fileDown' },
              { label: messageData.fileInfection, value: 'fileInfection' },
              { label: messageData.cure, value: 'cure' },
            ],
          },
          infectionArray: {
            name: formatMessage({ id: 'Infection_12', defaultMessage: '감염 정보' }),
            child: [
              { label: messageData.infectionPc, value: 'infectionPc' },
              { label: messageData.leakFileCount, value: 'leakFileCount' },
              { label: messageData.leakCertCount, value: 'leakCertCount' },
              { label: messageData.leakEmailCount, value: 'leakEmailCount' },
            ],
          },
        };

  return (
    <div className="exam-content-wrap pi">
      {/* 차트 영역 */}
      <ExamChart data={examList.data} chartType="tag" />

      {/* 검색 영역 */}
      <SearchFilter
        sortMenu={targetSort}
        filterMenu={targetFilter}
        filterType="array"
        param={examList.param}
        paramAction={changeListState}
        dataList={dataKeyList}
      />

      {/* 내용 리스트 */}
      <ContentList
        className="half"
        dataList={examDataList}
        onLoadData={onGetExamList}
        loading={loading}
        page={examList.page}
        totalPage={examList.totalPage}
        noContent={{
          title: formatMessage({ id: 'Exam_35', defaultMessage: '훈련이 없습니다.' }),
          subTitle: formatMessage({ id: 'Exam_36', defaultMessage: '훈련을 실행해 보세요!' }),
        }}
      >
        {examDataList?.length > 0 &&
          examDataList.map((item: any, index: number) => {
            const rowData = examList.data[index];
            return (
              <div
                className={`content-list-item ${
                  examList.selectType === 'select' && examList.selectedList.includes(rowData.tagNo)
                    ? 'selected'
                    : ''
                }`}
                key={rowData.tagNo}
                onClick={() => onSelectItem(rowData.tagNo)}
                aria-hidden="true"
              >
                <ExamItemEach
                  data={rowData}
                  summaryData={examSummary.dataByExamNo[rowData.examNo.toString()]}
                  type="examTag"
                />
              </div>
            );
          })}
      </ContentList>
    </div>
  );
}

export default Tag;
