import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { changeState } from 'store/declare';
import ContentList from 'components/common/ContentList';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Popover } from 'antd';
import SearchFilter from 'components/common/SearchFilter';

function DeclareMail({ onGetDeclareMail, declareData, selectedTag, loading, onSelectItem }: any) {
  const { formatMessage } = useIntl();

  useEffect(() => {
    onGetDeclareMail(true);
  }, [selectedTag, declareData.needReload, declareData.param]);

  return (
    <div className="declare-content-wrap pi">
      {/* 검색 영역 */}
      <SearchFilter param={declareData.param} paramAction={changeState} />

      {/* 리스트 */}
      <ContentList
        dataList={declareData.dataMail}
        onLoadData={onGetDeclareMail}
        loading={loading}
        page={declareData.page}
        totalPage={declareData.totalPages}
        noContent={{
          title: formatMessage({ id: 'Declare_4', defaultMessage: '신고 이력이 없습니다.' }),
          subTitle: formatMessage({
            id: 'Declare_5',
            defaultMessage: '신고한 훈련 대상이 없습니다.',
          }),
        }}
        showNoImage
      >
        {declareData?.dataMail.map((rowData: any) => {
          return (
            <div
              className={`content-list-item ${
                declareData.selectedNo === rowData.declareNo ? 'selected' : ''
              }`}
              key={rowData.declareNo}
              onClick={() => onSelectItem(rowData.declareNo)}
              aria-hidden="true"
            >
              <div className="list-item">
                <div className="list-column">
                  <span
                    className={`declare-label small ${
                      rowData.declareFlag === 2 ? 'external' : 'exam'
                    }`}
                  >
                    {rowData.declareFlag === 2
                      ? formatMessage({ id: 'Declare_6', defaultMessage: '외부' })
                      : formatMessage({ id: 'Exam_27', defaultMessage: '훈련' })}
                  </span>
                </div>
                <div className="name-column" title={rowData.subject}>
                  <span className="ellipsis">{rowData.subject}</span>
                </div>
                <div className="name-column">
                  <Popover
                    overlayClassName="between-popover full"
                    placement="topLeft"
                    content={
                      <>
                        <div className="popover-row">
                          <div className="text">
                            {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                          </div>
                          <div className="value">{rowData.declareNo}</div>
                        </div>
                        <div className="popover-row">
                          <div className="text">
                            {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}
                          </div>
                          <div className="value">{rowData.declareName}</div>
                        </div>
                        <div className="popover-row">
                          <div className="text">
                            {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                          </div>
                          <div className="value">{rowData.declareEmail}</div>
                        </div>
                      </>
                    }
                  >
                    <span className="ellipsis">{`${rowData.declareName} (${rowData.declareEmail})`}</span>
                  </Popover>
                </div>
                <div className="list-column">
                  <span>{timeFormatFromUTCEpoch(rowData.declareEpoch)}</span>
                </div>
              </div>
            </div>
          );
        })}
      </ContentList>
    </div>
  );
}

export default DeclareMail;
