import { apiUrl, execUrl, Option } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'exam/SET_INITIAL';
const GET_EXAM_SUMMARY = 'exam/GET_EXAM_SUMMARY';
const PUT_EXAM_SUMMARY = 'exam/PUT_EXAM_SUMMARY';
const DELETE_EXAM_SUMMARY = 'exam/DELETE_EXAM_SUMMARY';
const CHANGE_SUMMARY_PARAM = 'exam/CHANGE_SUMMARY_PARAM';
const PUT_EXAM_SUSPEND = 'exam/PUT_EXAM_SUSPEND';
const PUT_EXAM_RESEND = 'exam/PUT_EXAM_RESEND';
const GET_EXAM_LIST = 'exam/GET_EXAM_LIST';
const GET_EXAM_RANDOM_LIST = 'exam/GET_EXAM_RANDOM_LIST';
const GET_EXAM_CHART = 'exam/GET_EXAM_CHART';
const CHANGE_SUMMARY_STATE = 'exam/CHANGE_SUMMARY_STATE';
const CHANGE_LIST_STATE = 'exam/CHANGE_LIST_STATE';
const CHANGE_RANDOM_LIST_STATE = 'exam/CHANGE_RANDOM_LIST_STATE';
const GET_INFECTION_PC_LIST = 'exam/GET_INFECTION_PC_LIST';
const CHANGE_INFECTION_PC_PARAM = 'exam/CHANGE_INFECTION_PC_PARAM';
const GET_LEAK_FILE = 'exam/GET_LEAK_FILE';
const CHANGE_LEAK_FILE_PARAM = 'exam/CHANGE_LEAK_FILE_PARAM';
const GET_INFECTION_TARGET = 'exam/GET_INFECTION_TARGET';
const CHANGE_INFECTION_TARGET_STATE = 'exam/CHANGE_INFECTION_TARGET_STATE';
const GET_INFECTION_PC = 'exam/GET_INFECTION_PC';
const GET_EDU_HISTORY = 'exam/GET_EDU_HISTORY';
const GET_EXAM_TAG = 'exam/GET_EXAM_TAG';
const GET_EXAM_TARGET = 'exam/GET_EXAM_TARGET';

// Actions
export const setInitial = () => actionFormat(SET_INITIAL);
export const changeSummaryState = (params: any) => actionFormat(CHANGE_SUMMARY_STATE, params);
export const changeListState = (params: any) => actionFormat(CHANGE_LIST_STATE, params);
export const changeRandomListState = (params: any) =>
  actionFormat(CHANGE_RANDOM_LIST_STATE, params);
export const getExamSummary = (params: any) =>
  actionFormat(GET_EXAM_SUMMARY, params, 'get', `${apiUrl}Exam`);
export const putExamSummary = (params: any) =>
  actionFormat(PUT_EXAM_SUMMARY, params, 'put', `${apiUrl}Exam`);
export const deleteExamSummary = (params: any) =>
  actionFormat(DELETE_EXAM_SUMMARY, params, 'delete', `${apiUrl}Exam`);
export const changeSummaryParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_SUMMARY_PARAM, params, null, '');
export const putExamSuspend = (params: any) =>
  actionFormat(PUT_EXAM_SUSPEND, params, 'post', `${apiUrl}Exam/Suspend`);
export const putExamResend = (params: any) =>
  actionFormat(PUT_EXAM_RESEND, params, 'put', `${apiUrl}Exam/ExamResend`);
export const getExamList = (params: any) =>
  actionFormat(GET_EXAM_LIST, params, 'get', `${apiUrl}Exam/ExamList`);
export const getExamRandomList = (params: any) =>
  actionFormat(GET_EXAM_RANDOM_LIST, params, 'get', `${apiUrl}Exam/ExamRandomList`);
export const getExamChart = (params: any) =>
  actionFormat(GET_EXAM_CHART, params, 'get', `${apiUrl}Exam/ExamChart`);
export const getInfectionPcList = (params: any) =>
  actionFormat(GET_INFECTION_PC_LIST, params, 'get', `${apiUrl}Exam/InfectionPC`);
export const addTag = (params: any) => actionFormat(null, params, 'post', `${apiUrl}Exam/AddTag`);
export const infectionPcChangeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_INFECTION_PC_PARAM, params, null, '');
export const getLeakFile = (params: any) =>
  actionFormat(GET_LEAK_FILE, params, 'get', `${apiUrl}Exam/LeakFile`);
export const leakFileChangeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_LEAK_FILE_PARAM, params, null, '');
export const getInfectionTarget = (params: any) =>
  actionFormat(GET_INFECTION_TARGET, params, 'get', `${apiUrl}Exam/InfectionTarget`);
export const changeInfectionTargetState = (params: any) =>
  actionFormat(CHANGE_INFECTION_TARGET_STATE, params);
export const getInfectionPc = (params: any) =>
  actionFormat(GET_INFECTION_PC, params, 'get', `${apiUrl}Exam/InfectionPC`);
export const getEduHistory = (params: any) =>
  actionFormat(GET_EDU_HISTORY, params, 'get', `${apiUrl}Edu/EduHistory`);
export const getExamTag = (params: any) =>
  actionFormat(GET_EXAM_TAG, params, 'get', `${apiUrl}Exam/TargetListByTag`);
export const getExamTarget = (params: any) =>
  actionFormat(null, params, 'get', `${apiUrl}Exam/ExamTarget`);

// 백신 다운로드
export const downloadVaccine = () =>
  actionFormat(null, null, 'get', `${execUrl}Cure`, {
    headers: { 'Content-Type': 'application/vnd.openxmlformats' },
    responseType: 'arraybuffer',
  });

// 백신 메일 발송
export const sendVaccine = (params: any) =>
  actionFormat(
    null,
    params,
    'get',
    `${execUrl}${Option.isKNBank ? 'SendVaccineKNBank' : 'SendVaccine'}`,
  );

// 보고서 다운로드
export const downloadReport = (params: any) =>
  actionFormat(null, params, 'post', `${apiUrl}Exam/DownloadExamReport`, {
    headers: { 'Content-Type': 'application/vnd.openxmlformats' },
    responseType: 'arraybuffer',
  });

// Initial state
const initialState: any = () => {
  return {
    examSummary: {
      data: [],
      dataByExamNo: {},
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'examNo', order: 'DESC' }],
      },
      openList: [],
      isAllOpen: false,
      selectedExamNo: 'all',
      selectedRandomGroupNo: 0,
      randomData: [],
    },
    examList: {
      data: [],
      dataByExamNo: {},
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'examNo', order: 'DESC' }],
        offset: 0,
        limit: 30,
      },
      page: 1,
      totalPage: 0,
      totalItem: 0,
      selectedList: [],
      selectType: 'select',
    },
    randomList: {
      data: [],
      dataByExamNo: {},
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'examNo', order: 'DESC' }],
        offset: 0,
        limit: 30,
      },
      page: 1,
      totalPage: 0,
      totalItem: 0,
      selectedList: [],
    },
    examChart: {
      data: [],
      needReload: false,
    },
    infectionPcList: {
      data: {
        result: -1,
        success: [],
        fail: [],
      },
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'infectionNo', order: 'DESC' }],
        offset: 0,
        limit: 12,
      },
      page: 1,
      totalPages: 0,
      totalCount: 0,
    },
    leakFile: {
      data: {
        result: -1,
        success: [],
        fail: [],
      },
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'leakFileNo', order: 'DESC' }],
        offset: 0,
        limit: 12,
      },
      page: 1,
      totalPages: 0,
      totalCount: 0,
    },
    infectionTarget: {
      data: [],
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'examNo', order: 'DESC' }],
        offset: 0,
        limit: 5,
      },
      page: 1,
      totalPage: 0,
      totalCount: 0,
      openList: [],
    },
    infectionPc: {
      data: [],
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'examNo', order: 'DESC' }],
        offset: 0,
        limit: 5,
      },
      page: 1,
      totalPage: 0,
      totalCount: 0,
    },
    eduHistory: {
      data: [],
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'eduNo', order: 'DESC' }],
        offset: 0,
        limit: 5,
      },
      page: 1,
      totalPage: 0,
      totalCount: 0,
    },
    examTag: {
      data: [],
      needReload: false,
      param: {
        filter: {},
        sort: [{ field: 'examNo', order: 'DESC' }],
        offset: 0,
        limit: 5,
      },
      page: 1,
      totalPage: 0,
      totalCount: 0,
    },
  };
};

// Reducers
export default function (state = initialState(), action: any) {
  switch (action.type) {
    case SET_INITIAL:
      state = initialState();
      return state;
    case CHANGE_SUMMARY_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state.examSummary[key] = value;
      }
      return state;
    case CHANGE_LIST_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state.examList[key] = value;
      }
      return state;
    case CHANGE_RANDOM_LIST_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state.randomList[key] = value;
      }
      return state;
    case GET_EXAM_SUMMARY:
      if (action.payload) {
        const { list } = action.payload.data;
        const { offset, limit } = action.payload.config.params;
        state.examSummary.data = list;

        // 전체 목록 수
        const totalCount = list.length > 0 ? list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        const examData: any = {};
        list.forEach((item: any) => {
          examData[item.examNo] = item;
        });
        state.examSummary.dataByExamNo = examData;
        state.examSummary.needReload = false;

        state.examSummary.totalPages = totalPages;
        state.examSummary.totalCount = totalCount;

        // state.examSummary.selectedExamNo = 'all';
        // state.examSummary.selectedRandomGroupNo = 0;
      }
      return state;
    case PUT_EXAM_SUMMARY:
      if (action.payload) {
        state.examSummary.needReload = true;
      }
      return state;
    case CHANGE_SUMMARY_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.examSummary.param[name] = value;
      }
      return state;
    case PUT_EXAM_SUSPEND:
      if (action.payload) {
        state.examSummary.needReload = true;
      }
      return state;
    case PUT_EXAM_RESEND:
      if (action.payload) {
        state.examSummary.needReload = true;
      }
      return state;
    case DELETE_EXAM_SUMMARY:
      if (action.payload) {
        state.examSummary.needReload = true;
      }
      return state;
    case GET_EXAM_LIST:
      if (action.payload) {
        const { list } = action.payload.data;
        const { examNo, subTab, refresh, limit } = action.payload.config.params;

        let type = '';
        if (examNo === 'all') {
          type = 'examNo';
        } else if (subTab === 'pi') {
          type = 'targetNo';
        } else if (subTab === 'tag') {
          type = 'tagNo';
        }

        let curPage = 1;
        let curDataList = [];
        let curDataObj: any = {};

        if (list?.length > 0) {
          if (refresh) {
            state.examList.totalPage = Math.ceil(list[0].rnum / limit);
            state.examList.totalItem = list[0].rnum;
            curDataList = list;
            list.forEach((item: any) => {
              curDataObj[item[type]] = item;
            });
          } else {
            // 무한스크롤
            curPage = state.examList.page + 1;
            curDataList = state.examList.data.concat(list);
            list.forEach((item: any) => {
              curDataObj[item[type]] = item;
            });
            curDataObj = { ...state.examList.dataByExamNo, ...curDataObj };
          }
        } else {
          state.examList.totalPage = 0;
          state.examList.totalItem = 0;
        }

        state.examList.data = curDataList;
        state.examList.dataByExamNo = curDataObj;
        state.examList.page = curPage;
        state.examList.needReload = false;
      }
      return state;

    case GET_EXAM_RANDOM_LIST:
      if (action.payload) {
        const { list } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataList = [];
        let curDataObj: any = {};

        if (list?.length > 0) {
          if (refresh) {
            state.randomList.totalPage = Math.ceil(list[0].rnum / limit);
            state.randomList.totalItem = list[0].rnum;
            curDataList = list;
            list.forEach((item: any) => {
              curDataObj[item.parentsExamNo] = item;
            });
          } else {
            // 무한스크롤
            curPage = state.randomList.page + 1;
            curDataList = state.randomList.data.concat(list);
            list.forEach((item: any) => {
              curDataObj[item.parentsExamNo] = item;
            });
            curDataObj = { ...state.randomList.dataByExamNo, ...curDataObj };
          }
        } else {
          state.randomList.totalPage = 0;
          state.randomList.totalItem = 0;
        }

        state.randomList.data = curDataList;
        state.randomList.dataByExamNo = curDataObj;
        state.randomList.page = curPage;
        state.randomList.needReload = false;
      }
      return state;

    case GET_EXAM_CHART:
      if (action.payload) {
        const { list } = action.payload.data;
        state.examChart.data = list;
        state.examChart.needReload = false;
      } else {
        state.examChart.data = [];
      }
      return state;
    case GET_INFECTION_PC_LIST:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.infectionPcList.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.infectionPcList.data = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        state.infectionPcList.needReload = false;
        state.infectionPcList.totalPages = totalPages;
        state.infectionPcList.totalCount = totalCount;
      }
      return state;

    case CHANGE_INFECTION_PC_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.infectionPcList.param[name] = value;
      }
      return state;

    case GET_LEAK_FILE:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.leakFile.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.leakFile.data = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        state.leakFile.needReload = false;
        state.leakFile.totalPages = totalPages;
        state.leakFile.totalCount = totalCount;
      }
      return state;

    case CHANGE_LEAK_FILE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.leakFile.param[name] = value;
      }
      return state;

    case GET_INFECTION_TARGET:
      if (action.payload) {
        const { list } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataList = [];

        if (list?.length > 0) {
          if (refresh) {
            state.infectionTarget.totalPage = Math.ceil(list[0].rnum / limit);
            state.infectionTarget.totalCount = list[0].rnum;
            curDataList = list;
          } else {
            // 무한스크롤
            curPage = state.infectionTarget.page + 1;
            curDataList = state.infectionTarget.data.concat(list);
          }
        } else {
          state.infectionTarget.totalPage = 0;
          state.infectionTarget.totalCount = 0;
        }

        state.infectionTarget.data = curDataList;
        state.infectionTarget.page = curPage;
        state.infectionTarget.needReload = false;
        state.infectionTarget.openList = [];
      }
      return state;
    case CHANGE_INFECTION_TARGET_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state.infectionTarget[key] = value;
      }
      return state;
    case GET_INFECTION_PC:
      if (action.payload) {
        const { list } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataList = [];

        if (list?.length > 0) {
          if (refresh) {
            state.infectionPc.totalPage = Math.ceil(list[0].rnum / limit);
            state.infectionPc.totalCount = list[0].rnum;
            curDataList = list;
          } else {
            // 무한스크롤
            curPage = state.infectionPc.page + 1;
            curDataList = state.infectionPc.data.concat(list);
          }
        } else {
          state.infectionPc.totalPage = 0;
          state.infectionPc.totalCount = 0;
        }

        state.infectionPc.data = curDataList;
        state.infectionPc.page = curPage;
        state.infectionPc.needReload = false;
      }
      return state;
    case GET_EDU_HISTORY:
      if (action.payload) {
        const { list } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataList = [];

        if (list?.length > 0) {
          if (refresh) {
            state.eduHistory.totalPage = Math.ceil(list[0].rnum / limit);
            state.eduHistory.totalCount = list[0].rnum;
            curDataList = list;
          } else {
            // 무한스크롤
            curPage = state.eduHistory.page + 1;
            curDataList = state.eduHistory.data.concat(list);
          }
        } else {
          state.eduHistory.totalPage = 0;
          state.eduHistory.totalCount = 0;
        }

        state.eduHistory.data = curDataList;
        state.eduHistory.page = curPage;
        state.eduHistory.needReload = false;
      }
      return state;
    case GET_EXAM_TAG:
      if (action.payload) {
        const { list } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataList = [];

        if (list?.length > 0) {
          if (refresh) {
            state.examTag.totalPage = Math.ceil(list[0].rnum / limit);
            state.examTag.totalCount = list[0].rnum;
            curDataList = list;
          } else {
            // 무한스크롤
            curPage = state.examTag.page + 1;
            curDataList = state.examTag.data.concat(list);
          }
        } else {
          state.examTag.totalPage = 0;
          state.examTag.totalCount = 0;
        }

        state.examTag.data = curDataList;
        state.examTag.page = curPage;
        state.examTag.needReload = false;
      }
      return state;
    default:
      return state;
  }
}
