import { Popover, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAttach } from 'store/attach';
import { getPhishing } from 'store/phishing';
import { changeParam, getTemplate } from 'store/template';
import { SettingOutlined, InfoCircleFilled } from '@ant-design/icons';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import TableDatePicker from 'components/common/TableDatePicker';
import ExamTemplateTableCheck from 'components/branch/examTemplate/ExamTemplateTableCheck';
import ExamTemplateSettings from 'components/branch/examTemplate/ExamTemplateSettings';

export default function ExamTemplateList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false);
  const [fitRowCount, setFitRowCount] = useState(tableRowCount);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const [allChecked, setAllChecked] = useState(false);
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.template.param.sort,
      filter: state.template.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.template.needReload);
  const respData = useSelector((state: any) => state.template.data);
  const totalCount = useSelector((state: any) => state.template.totalCount);
  const totalPages = useSelector((state: any) => state.template.totalPages);
  const phishingData = useSelector((state: any) => state.phishing.data.success);
  const attachData = useSelector((state: any) => state.attach.data.success);
  const attachIndexList = useSelector((state: any) => state.attach.listIndexByNo);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);
  const examTemplateArray = useSelector(
    (state: any) => state.template.selectedTemplate.examTemplateNoArray,
  );
  const serviceTemplateArray = useSelector(
    (state: any) => state.template.selectedTemplate.serviceTemplateNoArray,
  );

  useEffect(() => {
    dispatch(getPhishing());
    dispatch(getAttach());
  }, [respData]);

  useEffect(() => {
    getTemplateData();
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  const getTemplateData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize,
        };

        await dispatch(getTemplate(params));
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  };

  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    serviceNo: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
    serviceName: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
    attachSubject: formatMessage({ id: 'Attach_2', defaultMessage: '첨부파일 정보' }),
    templateName: formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' }),
    senderName: formatMessage({ id: 'Name_10', defaultMessage: '발송자명' }),
    senderEmail: formatMessage({ id: 'Email_16', defaultMessage: '발송자 이메일' }),
    subject: formatMessage({ id: 'Email_20', defaultMessage: '메일 제목' }),
  };

  // 필터
  const filterOption: { [key: string]: any } = {
    isServiceTemplateArray: {
      name: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
      child: [
        {
          label: formatMessage({ id: 'Template_14', defaultMessage: '기 본' }),
          value: '0',
        },
        {
          label: formatMessage({ id: 'User_1', defaultMessage: '사용자' }),
          value: '1',
        },
      ],
    },
    examTypeArray: {
      name: formatMessage({ id: 'Template_23', defaultMessage: '훈련 유형' }),
      child: [
        {
          label: formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' }),
          value: '2',
        },
        {
          label: formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' }),
          value: '3',
        },
        {
          label: formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' }),
          value: '4',
        },
      ],
    },
    linkAttachArray: {
      name: formatMessage({ id: 'Download_3', defaultMessage: '다운로드 유형' }),
      child: [
        {
          label: formatMessage({ id: 'Template_15', defaultMessage: '첨부 파일' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'Template_16', defaultMessage: '링크 첨부' }),
          value: '2',
        },
      ],
    },
    templateReceiveArray: {
      name: formatMessage({ id: 'Email_21', defaultMessage: '수신 성공' }),
      child: [
        {
          label: formatMessage({ id: 'Email_22', defaultMessage: '수신 실패' }),
          value: '0',
        },
        {
          label: formatMessage({ id: 'Email_21', defaultMessage: '수신 성공' }),
          value: '1',
        },
      ],
    },
  };
  // 테이블
  const baseColumns = [
    {
      Header: (
        <ExamTemplateTableCheck
          examTemplateArray={examTemplateArray}
          serviceTemplateArray={serviceTemplateArray}
          examData={respData.success}
          setAllChecked={setAllChecked}
          allChecked={allChecked}
        />
      ),
      accessor: 'test',
      resizable: false,
      sortable: false,
      width: 70,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return (
          <ExamTemplateTableCheck
            examTemplateArray={examTemplateArray}
            serviceTemplateArray={serviceTemplateArray}
            examData={respData.success}
            templateInfo={props.row.original}
            allChecked={allChecked}
          />
        );
      },
    },
    {
      Header: <SettingOutlined />,
      accessor: 'setting',
      resizable: false,
      sortable: false,
      width: 114,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return <ExamTemplateSettings isAdmin={isAdmin} templateInfo={props.row.original} />;
      },
    },
    {
      Header: 'No',
      accessor: 'serviceTemplateNo',
      resizable: true,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
      accessor: 'templatePick',
      resizable: true,
      sortable: true,
      width: 83,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = (
          <Tag className="temp-type-label color-temp-system no-margin">
            {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
          </Tag>
        );

        if (props.value) {
          printValue = (
            <Tag className="temp-type-label color-temp-user no-margin">
              {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
            </Tag>
          );
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_23', defaultMessage: '훈련 유형' }),
      accessor: 'examType',
      resizable: true,
      sortable: true,
      width: 131,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue: any = '-';
        if (props.value === 1 && props.row?.original?.attachExamType === 1) {
          printValue = (
            <Tag className="temp-type-label color-temp-warning">
              {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
            </Tag>
          );
        } else if (props.value === 1 && props.row?.original?.attachExamType === 2) {
          printValue = (
            <Tag className="temp-type-label color-temp-file">
              {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
            </Tag>
          );
        } else if (props.value === 1 && props.row?.original?.attachExamType === 3) {
          printValue = (
            <Tag className="temp-type-label color-temp-research">
              {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
            </Tag>
          );
        } else if (props.value === 4) {
          printValue = (
            <Tag className="temp-type-label color-temp-phishing">
              {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
            </Tag>
          );
        }
        return printValue;
      },
    },
    {
      Header: (
        <Popover
          overlayClassName="full"
          placement="topLeft"
          content={formatMessage({
            id: 'Attach_29',
            defaultMessage: "부가 기능, 제약사항 설명 페이지 'Click!'",
          })}
        >
          <a
            href={`/attachRestrict_${localStorage.getItem('language')}.html`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatMessage({ id: 'Attach_2', defaultMessage: '첨부파일 정보' })}
            <InfoCircleFilled className="ml-5" />
          </a>
        </Popover>
      ),
      accessor: 'attachSubject',
      resizable: true,
      sortable: false,
      width: 150,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        const attachListInFo = props.row.original.attachInfoNo;
        const { examType } = props.row.original;
        let printValue: any = null;

        if (
          attachListInFo &&
          Object.keys(attachData).length > 0 &&
          Object.keys(attachIndexList).length > 0
        ) {
          const aData = attachData[String(attachIndexList[String(attachListInFo)])];

          if (aData) {
            const attachFunctionKey = Object.keys(JSON.parse(aData.attachFunction));
            const attachFunction =
              attachFunctionKey.length > 0
                ? attachFunctionKey.map((keys, i) => {
                    return (
                      <Tag key={String(i)} className="color-bg-attachFunction">
                        {keys}
                      </Tag>
                    );
                  })
                : '-';

            const attachRestrictKey = Object.keys(JSON.parse(aData.attachRestrict));
            const attachRestrict =
              attachRestrictKey.length > 0
                ? attachRestrictKey.map((keys, i) => {
                    return (
                      <Tag key={String(i)} className="color-bg-attachRestrict">
                        {keys}
                      </Tag>
                    );
                  })
                : '-';

            printValue = (
              <Popover
                placement="right"
                content={
                  <div className="attach-cell-popup">
                    <div className="attach-cell-popup-each">
                      <span className="attach-cell-popup-title">No</span>
                      <span className="attach-cell-popup-content">{aData.attachInfoNo}</span>
                    </div>
                    <div className="attach-cell-popup-each">
                      <span className="attach-cell-popup-title">
                        {formatMessage({ id: 'Template_22', defaultMessage: '구분' })}
                      </span>
                      <span className="attach-cell-popup-content">
                        {aData.attachCategory === 1 && (
                          <Tag className="color-attach-exe">
                            {formatMessage({ id: 'Attach_7', defaultMessage: '실행파일' })}
                          </Tag>
                        )}
                        {aData.attachCategory === 2 && (
                          <Tag className="color-attach-lnk">
                            {formatMessage({ id: 'Attach_8', defaultMessage: '바로가기' })}
                          </Tag>
                        )}
                        {aData.attachCategory === 3 && (
                          <Tag className="color-attach-doc">
                            {formatMessage({ id: 'Attach_9', defaultMessage: '문서파일' })}
                          </Tag>
                        )}
                        {aData.attachCategory === 4 && (
                          <Tag className="color-attach-html">
                            {formatMessage({ id: 'Attach_10', defaultMessage: '웹문서' })}
                          </Tag>
                        )}
                      </span>
                    </div>
                    <div className="attach-cell-popup-each">
                      <span className="attach-cell-popup-title">
                        {formatMessage({ id: 'Template_23', defaultMessage: '훈련 유형' })}
                      </span>
                      <span className="attach-cell-popup-content">
                        {aData.attachExamType === 1 && (
                          <Tag className="color-temp-warning">
                            {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
                          </Tag>
                        )}
                        {aData.attachExamType === 2 && (
                          <Tag className="color-temp-file">
                            {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
                          </Tag>
                        )}
                        {aData.attachExamType === 3 && (
                          <Tag className="color-temp-research">
                            {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
                          </Tag>
                        )}
                      </span>
                    </div>
                    <div className="attach-cell-popup-each">
                      <span className="attach-cell-popup-title">
                        {formatMessage({ id: 'Attach_11', defaultMessage: '아이콘' })}
                      </span>
                      <span className="attach-cell-popup-content">
                        <img
                          alt=""
                          className="attach-fileIcon"
                          src={`/fdata/attachFile/${aData.attachInfoNo}/${aData.fileIcon}`}
                        />
                      </span>
                    </div>
                    <div className="attach-cell-popup-each">
                      <span className="attach-cell-popup-title">
                        {formatMessage({ id: 'Attach_12', defaultMessage: '확장자' })}
                      </span>
                      <span className="attach-cell-popup-content">{aData.fileExt}</span>
                    </div>
                    <div className="attach-cell-popup-each">
                      <span className="attach-cell-popup-title">
                        {formatMessage({ id: 'Attach_13', defaultMessage: '부가 기능' })}
                      </span>
                      <span className="attach-cell-popup-content">{attachFunction}</span>
                    </div>
                    <div className="attach-cell-popup-each">
                      <span className="attach-cell-popup-title">
                        {formatMessage({ id: 'Attach_14', defaultMessage: '제약 사항' })}
                      </span>
                      <span className="attach-cell-popup-content">{attachRestrict}</span>
                    </div>
                  </div>
                }
              >
                <div>{aData.attachSubject}</div>
              </Popover>
            );
          }
        }

        return examType === 1 && attachListInFo ? printValue : '-';
      },
    },
    {
      Header: formatMessage({ id: 'Phishing_1', defaultMessage: '피싱 페이지' }),
      accessor: 'phishingName',
      resizable: true,
      sortable: true,
      width: 261,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        const { phishingNo, examType } = props?.row?.original;
        let phishingArray: Array<any> = [];
        let phishingInfo: { [key: string]: any } = {};
        phishingArray = phishingData;

        if (phishingNo && phishingArray.length > 0) {
          phishingArray.map((item, index) => {
            if (examType === 4 && item.phishingNo === phishingNo) {
              phishingInfo = item;
            }
            return phishingInfo;
          });
        }

        return examType === 4 && phishingInfo && phishingInfo.phishingName
          ? phishingInfo.phishingName
          : '-';
        // return '-'
      },
    },
    {
      Header: formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' }),
      accessor: 'templateName',
      resizable: true,
      sortable: true,
      width: 261,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Email_20', defaultMessage: '메일 제목' }),
      accessor: 'subject',
      resizable: true,
      sortable: true,
      width: 261,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Header_1', defaultMessage: '훈련결과' }),
      accessor: 'statistics',
      resizable: true,
      sortable: true,
      width: 140,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        if (props.value) {
          if (props.value === 'serviceTemplate') {
            return '-';
          }
          return props.value.value;
        }
        return formatMessage({ id: 'StartExam_55', defaultMessage: '훈련 결과 없음' });
      },
    },
    {
      Header: formatMessage({ id: 'Name_10', defaultMessage: '발송자명' }),
      accessor: 'senderName',
      resizable: true,
      sortable: true,
      width: 106,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Email_16', defaultMessage: '발송자 이메일' }),
      accessor: 'senderEmail',
      resizable: true,
      sortable: true,
      width: 199,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Download_3', defaultMessage: '다운로드 유형' }),
      accessor: 'linkAttach',
      resizable: false,
      sortable: true,
      width: 145,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = formatMessage({ id: 'Template_15', defaultMessage: '첨부 파일' });
        } else if (props.value === 2) {
          printValue = formatMessage({ id: 'Template_16', defaultMessage: '링크 첨부' });
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Attach_3', defaultMessage: '첨부 유형' }),
      accessor: 'attachType',
      resizable: false,
      sortable: true,
      width: 143,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = formatMessage({ id: 'Attach_4', defaultMessage: '원본' });
        } else if (props.value === 2) {
          printValue = formatMessage({ id: 'Attach_5', defaultMessage: '압축' });
        } else if (props.value === 3) {
          printValue = formatMessage({ id: 'Attach_6', defaultMessage: '비번압축' });
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Email_21', defaultMessage: '수신 성공' }),
      accessor: 'templateReceive',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = 'O';
        }
        return printValue;
      },
    },
    {
      Header: (
        <TableDatePicker
          dateType="RegEpoch"
          title={formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
          filter={filter}
          actionParam={changeParam}
        />
      ),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
  ];

  if (isAdmin === 1) {
    baseColumns.splice(1, 0, {
      Header: formatMessage({ id: 'Template_25', defaultMessage: '템플릿No' }),
      accessor: 'templateNo',
      resizable: true,
      sortable: true,
      width: 100,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
    });

    baseColumns.splice(
      13,
      0,
      {
        Header: formatMessage({ id: 'Template_26', defaultMessage: '공개' }),
        accessor: 'templatePublic',
        sortable: true,
        resizable: false,
        width: 83,
        headerClassName: 'react-table-th overflow-visible',
        className: 'react-table-td text-align-center',
        Cell: (props: { [key: string]: any }) => {
          let printValue = '-';
          if (props.value === 1) {
            printValue = 'O';
          }
          return printValue;
        },
      },
      {
        Header: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
        accessor: 'serviceNo',
        resizable: false,
        sortable: true,
        width: 110,
        headerClassName: 'react-table-th',
        className: 'react-table-td text-align-center number-text',
        Cell: (props: { [key: string]: any }) => {
          let printValue = '-';
          if (props.value) {
            printValue = props.value;
          }
          return printValue;
        },
      },
      {
        Header: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
        accessor: 'serviceName',
        resizable: true,
        sortable: true,
        width: 143,
        headerClassName: 'react-table-th',
        className: 'react-table-td sub-text',
      },
    );
  }

  const columns: Array<any> = useMemo(() => baseColumns, [
    respData,
    allChecked,
    phishingData,
    attachIndexList,
    attachData,
    serviceTemplateArray,
    examTemplateArray,
  ]);

  if (
    respData?.result === 1 &&
    respData.success &&
    Array.isArray(respData.success) &&
    respData.success.length > 0
  ) {
    respData.success.map((tempData: any, index: number) => {
      // 피싱 템플릿
      const isPhishing = tempData.examType === 4 ? true : false;

      // 사용자 템플릿
      if (tempData.serviceTemplateNo) {
        respData.success[index].statistics = 'serviceTemplate';
        // 기본 템플릿
      } else if (tempData.statisticsTarget > 0) {
        respData.success[index].statistics = {
          value: `${
            isPhishing
              ? formatMessage({
                  id: 'Phishing_2',
                  defaultMessage: '유출률',
                })
              : formatMessage({
                  id: 'Infection_2',
                  defaultMessage: '감염률',
                })
          }: ${((tempData.statisticsInfection / tempData.statisticsTarget) * 100).toFixed(0)}% (${
            tempData.statisticsInfection
          }${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })})`,
        };
      }
      return true;
    });
  }

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={changeParam}
        loading={loading}
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
