import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Tooltip } from 'antd';
import { Option } from 'utils/commonValues';
import { changeListState } from 'store/exam';
import ListStep from 'components/common/ListStep';
import ExamListExtra from './ExamListExtra';

// import targetImage from 'img/exam/list_target.png';
// import sendStopImage from 'img/exam/send_stop.png';
// import sendFailImage from 'img/exam/list_send_fail.png';
// import sendImage from 'img/exam/list_send.png';
// import readImage from 'img/exam/list_read.png';
// import accessImage from 'img/exam/list_access.png';
// import downImage from 'img/exam/list_down.png';
// import downGreyImage from 'img/exam/list_down_grey.png';
// import phishingImage from 'img/exam/list_phishing.png';
// import infectionImage from 'img/exam/list_infection.png';
// import cureImage from 'img/exam/list_cure.png';
// import cureGreyImage from 'img/exam/list_cure_grey.png';

function ExamItemAll(props: any) {
  const { data, summaryData, isChecked, onSelectCheck, selectedType } = props;
  const { formatMessage } = useIntl();

  const isPhishing = data.examType === 4;
  const isCure = data.examType === 1 && summaryData?.attachData.attachExamType === 2;

  // 발송 tooltip
  let sendTitle = `${formatMessage({ id: 'Send_1', defaultMessage: '발송' })}: ${data.send}`;
  let sendIcon = '/img/exam/list_send.png';
  if (data.sendError) {
    sendTitle += ` / ${formatMessage({ id: 'Send_14', defaultMessage: '실패' })}: ${
      data.sendError
    }`;
    sendIcon = '/img/exam/list_send_fail.png';
  }
  if (data.sendReserve) {
    sendTitle += ` / ${formatMessage({ id: 'Send_4', defaultMessage: '발송 예약' })}: ${
      data.sendReserve
    }`;
  }
  if (data.isSuspend) {
    sendTitle = `(${formatMessage({
      id: 'Send_10',
      defaultMessage: '메일 발송 일시정지',
    })}) ${sendTitle}`;
    sendIcon = '/img/exam/send_stop.png';
  }

  // 파일 다운 / 피싱 접속
  let downTitle = '';
  let downIcon = null;

  if (isPhishing) {
    downTitle = `${formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })}: ${
      data.download
    }`;
    downIcon = '/img/exam/list_access.png';
  } else {
    downTitle = `${formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' })}: `;

    if (data.linkAttach === 2) {
      downTitle += data.download;
      downIcon = '/img/exam/list_down.png';
    } else {
      downTitle += formatMessage({ id: 'Download_9', defaultMessage: '사용 안함' });
      downIcon = '/img/exam/list_down_grey.png';
    }
  }

  // 훈련 상태 표시 데이터
  const stepData = [
    {
      view: true,
      icon: {
        stepIcon: '/img/exam/list_target.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Target_2', defaultMessage: '대상' })}: ${data.target}`,
      count: data.target,
    },
    {
      view: true,
      icon: {
        stepIcon: sendIcon,
      },
      tooltipTitle: sendTitle,
      count: data.send,
      countColor: data.sendError ? 'color-red' : '',
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/exam/list_read.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Read_1', defaultMessage: '열람' })}: ${data.read}`,
      count: data.read,
      countColor: 'color-orange',
    },
    {
      view: true,
      icon: {
        stepIcon: downIcon,
      },
      tooltipTitle: downTitle,
      count: data.examType === 4 || data.linkAttach === 2 ? data.download : '-',
      countColor: data.examType === 4 || data.linkAttach === 2 ? 'color-down' : '',
    },
    {
      view: true,
      icon: {
        stepIcon: isPhishing ? '/img/exam/list_phishing.png' : '/img/exam/list_infection.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}: ${
        data.infection
      }`,
      count: data.infection,
      countColor: 'color-red',
    },
    {
      view: true,
      icon: {
        stepIcon: isCure ? '/img/exam/list_cure.png' : '/img/exam/list_cure_grey.png',
      },
      tooltipTitle: `${formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}: ${
        data.cure || formatMessage({ id: 'Download_9', defaultMessage: '사용 안함' })
      }`,
      count: isCure ? data.cure : '-',
      countColor: isCure ? 'color-cure' : '',
    },
  ];

  return (
    <div className="list-item exam-list-item">
      {/* <Checkbox
        className="list-check"
        checked={isChecked && selectedType === 'check'}
        onClick={(e) => {
          e.stopPropagation();
          onSelectCheck(data.examNo);
        }}
      /> */}
      <div
        className="check-div"
        onClick={(e) => {
          e.stopPropagation();
          onSelectCheck(data.examNo);
        }}
        aria-hidden="true"
      >
        {isChecked && selectedType === 'check' ? (
          <img src="/img/exam/list_check_on.png" alt="check" className="list-check image" />
        ) : (
          <Checkbox className="list-check" checked={false} />
        )}
      </div>

      {/* 훈련명 */}
      <div className="name-column">
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={`${formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}: ${data.examName}`}
        >
          <div className="exam-name ellipsis">{data.examName}</div>
        </Tooltip>
      </div>

      {/* 훈련 기간 */}
      <div className="period-column">
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={`${formatMessage({ id: 'Period_3', defaultMessage: '훈련 기간' })}: ${
            data.examStartDate?.split(' ')[0]
          } ~ ${data.examEndDate?.split(' ')[0]}`}
        >
          <div className="exam-period ellipsis">
            {`${data.examStartDate?.split(' ')[0]} ~ ${data.examEndDate?.split(' ')[0]}`}
          </div>
        </Tooltip>
      </div>

      {/* 훈련 상태 */}
      <div className="status-column">
        {/* step 영역 */}
        {stepData.map(
          (item: any) =>
            item.view && (
              <ListStep
                tooltipTitle={item.tooltipTitle}
                icon={item.icon}
                count={item.count}
                countColor={item.countColor}
                key={item.tooltipTitle}
              />
            ),
        )}
      </div>

      {/* 감염pc, 신고, 교육 */}
      <ExamListExtra data={data} />
    </div>
  );
}

export default ExamItemAll;
