import React, { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { postRegister, checkPassword } from 'store/register';
import TransformInput from 'components/common/TransformInput';
import { Checkbox } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Option } from 'utils/commonValues';
import * as valid from 'utils/validation';
import '../Login.scss';
import moment from 'moment';

// import mfLogoImage from 'img/logo/mf_logo_white.png';

function Register(props: any) {
  const { register, handleSubmit, getValues, setError, watch, errors, control } = useForm({
    mode: 'onBlur',
  });
  const dispatch = useDispatch();
  const [complate, setComplate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const registerData = useSelector((state: any) => state.register);
  const {
    userEmail,
    userPasswd,
    repeatPasswd,
    userName,
    userPhone,
    serviceName,
    storeId,
    customerId,
  } = getValues();
  const watchValues = watch();

  // 서비스 가입
  const onRegister = async (formData: any) => {
    setLoading(true);

    try {
      const params = {
        userEmail: formData.userEmail,
        userName: formData.userName,
        userPasswd: formData.userPasswd,
        userPhone: formData.userPhone,
        serviceName: formData.serviceName,
        startEpoch: moment().startOf('day').unix(),
        storeId: formData.storeId,
        customerId: formData.customerId,
      };

      const response: any = await dispatch(postRegister(params));
      const errorMessage = response?.data?.error && response.data.error[0]?.errInfo;
      if (response?.data?.list) {
        setComplate(true);
      } else if (errorMessage === 'Duplicate User Exist') {
        // 중복 유저
        setError('userEmail', {
          type: 'duplicate',
          message: formatMessage({ id: 'Email_8', defaultMessage: '중복된 이메일이 있습니다.' }),
        });
      } else if (Option.isJapan === 1 && errorMessage === 'Restricted domain') {
        // 일본 버전은 특정 서비스 도메인 제한
        setError('userEmail', { type: 'domain', message: 'フリーメールはご利用できません。' });
      } else if (response?.data?.error?.[0]) {
        // 기타 에러 표시
        Object.entries(response.data.error[0])?.forEach(([key, value]: any) => {
          setError(key, { type: 'error', message: value[0] });
        });
      } else {
        props.history.push('/login/register');
      }
      setLoading(false);
    } catch (error) {
      console.log('onRegister', error);
    }
  };

  // 비밀번호 규칙 체크
  const checkPasswordRule = async (formData: any) => {
    try {
      const param = {
        userEmailId: userEmail.split('@')[0],
        userPasswd: userPasswd,
      };

      const response: any = await dispatch(checkPassword(param));
      if (response?.data?.list && response.data.list[0]?.error?.bit) {
        // 에러가 있을 경우
        setError('userPasswd', {
          type: 'passwordRule',
          message: valid.password(response.data.list[0].error),
        });
      } else {
        // 에러가 없을 경우
        onRegister(formData);
      }
    } catch (error) {
      console.log('Register checkPassword', error);
    }
  };

  // 이용약관 파일 URL
  const tosURL = useMemo(() => {
    if (Option.customer === 'saxa') {
      return 'https://www.saxa.co.jp/product/terms/pdf/aemt_policy.pdf';
    }
    return `/tos_${localStorage.getItem('language')}.html`;
  }, [Option.isJapan, Option.customer]);

  return (
    <div className="register-page login-wrapper column">
      <img className="logo-white" src="/img/logo/mf_logo_white.png" alt="" />
      {complate ? (
        // 서비스 가입 완료 페이지
        <div className="register-complate login-item">
          <div className="content-box">
            <div className="info-text">
              <div className="main-text">
                {formatMessage(
                  {
                    id: 'Register_1',
                    defaultMessage: '{service} 가입을 환영합니다 !',
                  },
                  {
                    service:
                      Option.customer === 'saxa' ? 'SAXAメートレ' : Option.serviceName,
                  },
                )}
              </div>
              <div className="sub-text">
                <div>
                  {formatMessage(
                    {
                      id: 'Register_2',
                      defaultMessage: '가입한 이메일로 본인 확인 메일이 전송되었습니다.',
                    },
                    {
                      service:
                        Option.customer === 'saxa' ? 'SAXAメートレ' : Option.serviceName,
                    },
                  )}
                </div>
                <div>
                  {formatMessage(
                    {
                      id: 'Register_3',
                      defaultMessage: '인증 메일은 가입 신청일로부터 3일 이내에 확인해주세요.',
                    },
                    {
                      service:
                        Option.customer === 'saxa' ? 'SAXAメートレ' : Option.serviceName,
                    },
                  )}
                </div>
              </div>
            </div>
            {/* 등록된 메일 주소 */}
            <div className="border-box">
              <span className="text">
                {formatMessage({ id: 'NoActive_3', defaultMessage: '등록된 메일 주소' })}
              </span>
              <span className="bold">{registerData ? registerData.userEmail : ''}</span>
            </div>
            {/* 버튼 */}
            <div className="button-box">
              <button
                type="button"
                className="login-btn"
                onClick={() => props.history.push('/login/basic')}
              >
                {formatMessage({ id: 'Button_3', defaultMessage: '확인' })}
                {loading && (
                  <span className="button-loading">
                    <LoadingOutlined />
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="content-box">
            <form
              className="register-form login-item"
              onSubmit={handleSubmit(checkPasswordRule)}
              autoComplete="off"
            >
              {/* 이메일 */}
              <TransformInput
                name="userEmail"
                label={formatMessage({ id: 'Email_2', defaultMessage: '이메일*' })}
                value={userEmail}
                error={errors.userEmail}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) =>
                      valid.required(
                        value,
                        formatMessage({ id: 'Email_6', defaultMessage: '이메일을 입력해 주세요.' }),
                      ),
                    email: (value: any) => valid.email(value),
                  },
                }}
                useCheck
              />
              {/* 비밀번호 */}
              <TransformInput
                name="userPasswd"
                label={formatMessage({ id: 'Password_2', defaultMessage: '비밀번호*' })}
                type="password"
                value={userPasswd}
                error={errors.userPasswd}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) =>
                      valid.required(
                        value,
                        formatMessage({
                          id: 'Password_19',
                          defaultMessage: '비밀번호를 입력해 주세요.',
                        }),
                      ),
                  },
                }}
                useCheck
                useLock
              />
              {/* 비밀번호 확인 */}
              <TransformInput
                name="repeatPasswd"
                label={formatMessage({ id: 'Password_4', defaultMessage: '비밀번호 재확인*' })}
                type="password"
                value={repeatPasswd}
                error={errors.repeatPasswd}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) =>
                      valid.required(
                        value,
                        formatMessage({
                          id: 'Password_20',
                          defaultMessage: '비밀번호를 다시 입력해주세요.',
                        }),
                      ),
                    checkPassword: (value: any) =>
                      value === watchValues.userPasswd ||
                      formatMessage({
                        id: 'Password_15',
                        defaultMessage: '비밀번호가 일치하지 않습니다.',
                      }),
                  },
                }}
                useCheck
                useLock
              />
              {/* 이름 */}
              <TransformInput
                name="userName"
                label={formatMessage({ id: 'Name_2', defaultMessage: '이름*' })}
                value={userName}
                error={errors.userName}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) =>
                      valid.required(
                        value,
                        formatMessage({
                          id: 'Name_12',
                          defaultMessage: '사용자명을 입력해 주세요.',
                        }),
                      ),
                    name: (value: any) => valid.name(value),
                  },
                }}
                useCheck
              />
              {/* 연락처 */}
              <TransformInput
                name="userPhone"
                label={`${formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}${
                  Option.customer === 'saxa' ? '*' : ''
                }`}
                value={userPhone}
                error={errors.userPhone}
                register={register}
                useCheck
                validation={{
                  validate:
                    Option.customer === 'saxa'
                      ? {
                          required: (value: any) => valid.required(value),
                          phone: (value: any) => valid.userPhone(value),
                        }
                      : { phone: (value: any) => valid.userPhone(value) },
                }}
              />
              {/* 판매점ID */}
              <TransformInput
                name="storeId"
                label={`${formatMessage({ id: 'User_23', defaultMessage: '판매점ID' })}*`}
                value={storeId}
                error={errors.storeId}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                    engNumber: (value: any) => valid.engNumber(value),
                    range: (value: any) => valid.range(value, 0, 20),
                  },
                }}
                useCheck
              />
              {/* 고객ID */}
              <TransformInput
                name="customerId"
                label={`${formatMessage({ id: 'User_24', defaultMessage: '고객ID' })}${
                  Option.customer === 'saxa' ? '*' : ''
                }`}
                value={customerId}
                error={errors.customerId}
                register={register}
                validation={{
                  validate:
                    customerId || Option.customer === 'saxa'
                      ? {
                          engNumber: (value: any) => valid.engNumber(value),
                          range: (value: any) => valid.range(value, 0, 20),
                        }
                      : {},
                }}
                useCheck
              />
              {/* 서비스명 */}
              <TransformInput
                name="serviceName"
                label={formatMessage({ id: 'Service_2', defaultMessage: '서비스명*' })}
                value={serviceName}
                error={errors.serviceName}
                register={register}
                validation={{
                  required: formatMessage({
                    id: 'Service_3',
                    defaultMessage: '서비스명을 입력해 주세요.',
                  }),
                  validate: { serviceName: (value: any) => valid.name(value) },
                }}
                useCheck
                tooltipText={
                  Option.customer === 'saxa'
                    ? null
                    : formatMessage({
                        id: 'Service_15',
                        defaultMessage:
                          '서비스의 관리 및 공유를 위한 서비스명은 필수 입력 사항입니다.',
                      })
                }
              >
                {Option.customer !== 'saxa' && (
                  <div className="explain-text">
                    {!errors.serviceName &&
                      formatMessage({
                        id: 'Service_13',
                        defaultMessage:
                          '회사명, 소속, 부서 등 원하는 서비스명을 자유롭게 입력하세요.',
                      })}
                  </div>
                )}
              </TransformInput>
              {/* 약관 동의 */}
              <div className="service-checkbox">
                <div className="check-box">
                  <Controller
                    control={control}
                    name="serviceCheck"
                    rules={{ required: true }}
                    render={({ onChange, onBlur, value, name, ref }) => (
                      <Checkbox onChange={(e) => onChange(e.target.checked)} checked={value}>
                        {Option.customer === 'saxa' ? (
                          <>
                            販売店工事者が設定代行する場合、契約者様に利用規約をご確認頂き、
                            <br />
                            内容に同意を得た上で、契約者様自身が「登録する」ボタン押下をお願いします。
                          </>
                        ) : (
                          formatMessage({
                            id: 'Terms_4',
                            defaultMessage: '약관을 읽었으며 이에 동의합니다.',
                          })
                        )}
                      </Checkbox>
                    )}
                  />
                  <a href={tosURL} target="_blank" rel="noreferrer">
                    {formatMessage({ id: 'Terms_2', defaultMessage: '이용약관' })}
                  </a>
                </div>
                <div className="error-message">
                  {errors.serviceCheck && (
                    <span>
                      {formatMessage({
                        id: 'Terms_3',
                        defaultMessage: '서비스 이용약관에 동의해 주세요.',
                      })}
                    </span>
                  )}
                </div>
              </div>
              {/* 버튼 */}
              <div className="button-box">
                <button
                  className="login-btn ok"
                  type="submit"
                  style={{ background: 'url(/img/login/login_button.png) no-repeat center' }}
                >
                  {formatMessage({ id: 'Login_8', defaultMessage: '가입하기' })}
                  {loading && (
                    <span className="button-loading">
                      <LoadingOutlined />
                    </span>
                  )}
                </button>
                <div className="to-login">
                  {formatMessage({ id: 'Login_3', defaultMessage: '이미 계정이 있으신가요?' })}
                  <Link to="/login/basic">
                    {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
}

export default withRouter(Register);
