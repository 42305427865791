import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { checkPassword } from 'store/register';
import { initSetting } from 'store/user';
import TransformInput from 'components/common/TransformInput';
import * as valid from 'utils/validation';
import { Redirect, withRouter } from 'react-router-dom';
import { Option } from 'utils/commonValues';

// import mLogoImage from 'img/logo/m_logo.png';
// import sLogoImage from 'img/logo/s_logo.png';

function FirstLogin(props: any) {
  const { register, handleSubmit, getValues, setError, errors } = useForm({
    mode: 'onBlur',
  });
  const userData = useSelector((state: any) => state.login.userData);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { userPasswd, userPasswdCheck } = getValues();

  // 최초 로그인 시 비밀번호 변경
  const onChangePasswd = async (formData: any) => {
    const params = {
      userPasswd: formData.userPasswd,
    };

    const response: any = await dispatch(initSetting(params));
    if (response?.data?.list) {
      setSuccess(true);
    } else if (response?.data?.error[0]?.errInfo) {
      setError('userPasswd', { type: 'passwordValid', message: response.data.error[0].errInfo });
    }
    setLoading(false);
  };

  // 비밀번호 규칙 체크
  const checkPasswordRule = async (formData: any) => {
    if (!userData) return;
    setLoading(true);

    try {
      const param = {
        userEmailId: userData.userEmail.split('@')[0],
        userPasswd: userPasswd,
      };

      const response: any = await dispatch(checkPassword(param));
      if (response?.data?.list[0]?.error?.bit) {
        // 에러가 있을 경우
        setError('userPasswd', {
          type: 'passwordRule',
          message: valid.password(response.data.list[0].error),
        });
      } else {
        // 에러가 없을 경우
        onChangePasswd(formData);
      }
    } catch (error) {
      console.log('FirstLogin checkPassword', error);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="first-login login-item">
        {/* 비밀번호 변경 안내 */}
        <div className="login-info">
          <img
            className="logo"
            src="/img/logo/m_logo.png" // {Option.isSaxa === 1 ? sLogoImage : mLogoImage}
            alt="logo"
          />
          <div className="info-text">
            <div className="main-text">
              {formatMessage({
                id: 'Password_7',
                defaultMessage: '비밀번호 변경 (관리자에 의해 지정됨)',
              })}
            </div>
            <div className="sub-text">
              <div>
                {formatMessage({
                  id: 'FirstLogin_3',
                  defaultMessage: '관리자가 지정한 비밀번호입니다.',
                })}
              </div>
              <div>
                {formatMessage({ id: 'Password_11', defaultMessage: '새 비밀번호로 변경하세요.' })}
              </div>
            </div>
          </div>
        </div>

        {/* 입력 폼 */}
        <form
          className="first-login-form login-item"
          onSubmit={handleSubmit(checkPasswordRule)}
          autoComplete="off"
        >
          {success ? (
            <>
              <div className="success-content">
                {formatMessage({ id: 'FirstLogin_4', defaultMessage: '변경 완료!' })}
              </div>
              <div className="button-box">
                <button
                  type="button"
                  className="first-login-btn brand"
                  onClick={() => {
                    window.location.replace('/');
                  }}
                >
                  {formatMessage({ id: 'Login_1', defaultMessage: '로그인' })}
                </button>
              </div>
            </>
          ) : (
            <>
              {/* 새 비밀번호 */}
              <TransformInput
                name="userPasswd"
                label={formatMessage({ id: 'Password_9', defaultMessage: '새 비밀번호' })}
                type="password"
                value={userPasswd}
                error={errors.userPasswd}
                register={register}
                validation={{
                  validate: { required: (value: any) => valid.required(value) },
                }}
                useLock
              />

              {/* 새 비밀번호 확인 */}
              <TransformInput
                name="userPasswdCheck"
                label={formatMessage({ id: 'Password_10', defaultMessage: '새 비밀번호 확인' })}
                type="password"
                value={userPasswdCheck}
                error={errors.userPasswdCheck}
                register={register}
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                    matched: (value: any) =>
                      value === userPasswd ||
                      formatMessage({
                        id: 'Password_15',
                        defaultMessage: '비밀번호가 일치하지 않습니다.',
                      }),
                  },
                }}
                useLock
              />

              {/* 버튼 */}
              <div className="button-box">
                <button className="first-login-btn brand" type="submit">
                  {formatMessage({ id: 'Button_1', defaultMessage: '변경' })}
                  {loading && (
                    <span className="button-loading">
                      <LoadingOutlined />
                    </span>
                  )}
                </button>
                <button
                  type="button"
                  className="first-login-btn"
                  onClick={() => props.history.push('/login')}
                >
                  {formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                </button>
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
}

export default withRouter(FirstLogin);
