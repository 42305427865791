import { apiUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const INIT_FORM = 'form/INIT_FORM';
const SAVE_FORM = 'form/SAVE_FORM';

// Actions
export const initForm = () => actionFormat(INIT_FORM);
export const saveForm = (params: any) => actionFormat(SAVE_FORM, params);

// Initial state
const initialState = {
  formStore: {},
};

// Reducers
export default function (state = initialState, action: any) {
  switch (action.type) {
    case INIT_FORM:
      state.formStore = {};
      return state;
    case SAVE_FORM:
      if (action.payload) {
        state.formStore = { ...action.payload };
      }
      return state;
    default:
      return state;
  }
}
