import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'template/SET_INITIAL';
const GET_TEMPLATE = 'template/GET_TEMPLATE';
const ADD_TEMPLATE = 'template/ADD_TEMPLATE';
const EDIT_TEMPLATE = 'template/EDIT_TEMPLATE';
const DELETE_TEMPLATE = 'template/DELETE_TEMPLATE';
const COPY_TEMPLATE = 'template/COPY_TEMPLATE';
const CHANGE_PARAM = 'template/CHANGE_PARAM';
const SELECT_TEMPLATE = 'template/SELECT_TEMPLATE';
const GET_RESULT_TEMPLATE = 'template/GET_RESULT_TEMPLATE';
const GET_RESULT_TEMPLATE_TARGET = 'template/GET_RESULT_TEMPLATE_TARGET';
const CHANGE_SUB_STATE = 'template/CHANGE_SUB_STATE';

// Actions
// 템플릿 조회
export const getTemplate = (params?: { [key: string]: any }) =>
  actionFormat(GET_TEMPLATE, params, 'get', `${apiUrl}Template`);
// 템플릿 등록
export const addTemplate = (params?: { [key: string]: any }) =>
  actionFormat(ADD_TEMPLATE, params, 'post', `${apiUrl}Template`);
// 템플릿 수정
export const editTemplate = (params?: { [key: string]: any }) =>
  actionFormat(EDIT_TEMPLATE, params, 'put', `${apiUrl}Template`);
// 템플릿 삭제
export const deleteTemplate = (params?: { [key: string]: any }) =>
  actionFormat(DELETE_TEMPLATE, params, 'delete', `${apiUrl}Template`);
// 템플릿 복제
export const copyTemplate = (params?: { [key: string]: any }) =>
  actionFormat(COPY_TEMPLATE, params, 'post', `${apiUrl}Template/Copy`);
// 템플릿 번호 생성
export const makeTemplateNo = () => actionFormat(null, null, 'post', `${apiUrl}Template`);
// 템플릿 번호 삭제
export const deleteTemplateNo = (params: { [key: string]: any }) =>
  actionFormat(null, params, 'delete', `${apiUrl}Template/InsertCancle`);

export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');
export const selectTemplate = (params: { [key: string]: any }) =>
  actionFormat(SELECT_TEMPLATE, params, null, '');
export const postReceiveTest = (params: { [key: string]: any }) =>
  actionFormat(null, params, 'post', `${apiUrl}Template/ReceiveTest`);

// 훈련결과 템플릿탭 리스트 조회
export const getResultTemplate = (params?: { [key: string]: any }) =>
  actionFormat(GET_RESULT_TEMPLATE, params, 'get', `${apiUrl}Template`);
// 훈련결과 탭플릿별 대상자 상세조회
export const getTemplateTarget = (params?: { [key: string]: any }) =>
  actionFormat(GET_RESULT_TEMPLATE_TARGET, params, 'get', `${apiUrl}Template/TargetList`);

export const changeSubState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_SUB_STATE, params, null, '');

// 이미지 업로드
export const uploadExamTemplateBodyImage = (params: any) =>
  actionFormat(null, params, 'post', `${apiUrl}Template/UploadImage`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

// initial state
interface stateType {
  data: { [key: string]: any };
  param: { [key: string]: any };
  needReload: boolean;
  totalPages: number;
  totalCount: number;
  selectedTemplate: { [key: string]: any };

  resultTemplate: {
    data: Array<any>;
    dataByTemplateNo: { [key: string]: any };
    param: { [key: string]: any };
    totalPages: number;
    totalCount: number;
    needReload: boolean;
    selectedList: Array<number>;
  };

  resultTemplateTarget: {
    data: Array<any>;
    param: { [key: string]: any };
  };
}

const initialState: stateType = {
  data: {
    result: -1,
    success: [],
    fail: [],
  },
  param: {
    filter: {},
    sort: [{ field: 'serviceTemplateNo', order: 'DESC' }],
  },
  needReload: false,
  totalPages: 0,
  totalCount: 0,
  selectedTemplate: {
    examTemplateNoArray: [],
    serviceTemplateNoArray: [],
  },
  resultTemplate: {
    data: [],
    dataByTemplateNo: {},
    param: {
      filter: {},
      sort: [{ field: 'serviceTemplateNo', order: 'DESC' }],
      limit: 30,
      offset: 0,
    },
    totalPages: 0,
    totalCount: 0,
    needReload: false,
    selectedList: [],
  },
  resultTemplateTarget: {
    data: [],
    param: {
      filter: {},
      sort: [{ field: 'targetNo', order: 'DESC' }],
      limit: 10,
      offset: 0,
    },
  },
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_TEMPLATE:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit, type } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.data = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        state.needReload = false;
        state.totalPages = totalPages;
        state.totalCount = totalCount;
      }
      return state;
    case ADD_TEMPLATE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case EDIT_TEMPLATE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case DELETE_TEMPLATE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case COPY_TEMPLATE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    case SELECT_TEMPLATE:
      if (action.payload) {
        const { name, value } = action.payload;
        state.selectedTemplate[name] = value;
      }
      return state;
    case GET_RESULT_TEMPLATE:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit, refresh } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        let curPage = 1;
        let curDataList: any = [];
        let curDataObj: any = {};

        if (data.list?.length > 0) {
          if (refresh) {
            state.resultTemplate.totalPages = totalPages;
            state.resultTemplate.totalCount = totalCount;
            curDataList = data.list;
            data.list.forEach((item: any) => {
              const no = item.templatePick ? item.serviceTemplateNo : item.templateNo;
              curDataObj[no] = item;
            });
          } else {
            // 무한스크롤
            curPage = state.resultTemplate.page + 1;
            curDataList = state.resultTemplate.data.concat(data.list);
            data.list.forEach((item: any) => {
              const no = item.templatePick ? item.serviceTemplateNo : item.templateNo;
              curDataObj[no] = item;
            });
            curDataObj = { ...state.resultTemplate.dataByTemplateNo, ...curDataObj };
          }
        } else {
          state.resultTemplate.totalPages = 0;
          state.resultTemplate.totalCount = 0;
        }

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.resultTemplate.data = curDataList;
          state.resultTemplate.dataByTemplateNo = curDataObj;
        } else if (data.error && Array.isArray(data.error)) {
          state.resultTemplate.data = [];
          state.resultTemplate.dataByTemplateNo = {};
        }

        state.resultTemplate.needReload = false;
        state.resultTemplate.page = curPage;
      }
      return state;
    case GET_RESULT_TEMPLATE_TARGET:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit, refresh } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list?.length > 0 ? data.list[0]?.rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        let curPage = 1;
        let curDataList: any = [];
        let curDataObj: any = {};

        if (data.list?.length > 0) {
          if (refresh) {
            state.resultTemplateTarget.totalPages = totalPages;
            state.resultTemplateTarget.totalCount = totalCount;
            curDataList = data.list;
            data.list.forEach((item: any) => {
              curDataObj[item.targetNo] = item;
            });
          } else {
            // 무한스크롤
            curPage = state.resultTemplateTarget.page + 1;
            curDataList = state.resultTemplateTarget.data.concat(data.list);
            data.list.forEach((item: any) => {
              curDataObj[item.targetNo] = item;
            });
            curDataObj = { ...state.resultTemplateTarget.dataByTargetNo, ...curDataObj };
          }
        } else {
          state.resultTemplateTarget.totalPages = 0;
          state.resultTemplateTarget.totalCount = 0;
        }

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.resultTemplateTarget.data = curDataList;
          state.resultTemplateTarget.dataByTemplateNo = curDataObj;
        } else if (data.error && Array.isArray(data.error)) {
          state.resultTemplateTarget.data = [];
          state.resultTemplateTarget.dataByTemplateNo = {};
        }
        state.resultTemplateTarget.page = curPage;
      }
      return state;
    case CHANGE_SUB_STATE:
      if (action.payload) {
        const { subStore, key, value } = action.payload;
        state[subStore][key] = value;
      }
      return state;
    default:
      return state;
  }
}
