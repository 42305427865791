import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { getCheckLogin } from 'store/login';
import { changeOptions, Option } from 'utils/commonValues';
import { nowEpoch } from 'utils/commonFunctions';
import './App.scss';
import Header from 'components/branch/Header';
import { setUser } from 'store/user';

function App() {
  const dispatch = useDispatch();
  const loginData = useSelector((state: any) => state.login);
  const eduData = useSelector((state: any) => state.edumain);
  const [loginChecked, setLoginChecked] = useState(false);
  const [isEduPage, setIsEduPage] = useState(false);
  const [cookies, setCookie] = useCookies();
  const auth = loginData.userData ? (loginData.userData.isAdmin === 0 ? 1 : 2) : 0; // eslint-disable-line
  const eduAuth = eduData.userData ? 1 : 0;

  useEffect(() => {
    setCookie('lang', localStorage.getItem('language'), { path: '/' });

    // 교육페이지인지 확인
    const edumain: boolean = window.location.pathname?.includes('edumain');
    setIsEduPage(edumain);

    if (edumain) {
      setLoginChecked(true);
    } else {
      checkLogin();
    }
  }, [dispatch]);

  // 로그인 여부 확인
  const checkLogin = async () => {
    try {
      const response: any = await dispatch(getCheckLogin());
      if (response && response.data && response.data.list) {
        const {
          isSaas,
          isGs,
          isJapan,
          isEdu,
          isDeclare,
          customer,
          userData,
          serviceNo,
          sessionTimeout,
          serviceName,
        } = response.data.list;

        // config 옵션 설정
        changeOptions({ isSaas, isGs, isJapan, isEdu, isDeclare, customer, serviceName });

        if (userData) {
          if (userData.isAdmin === 1) {
            localStorage.setItem('loginAdmin', userData.userEmail);
            localStorage.removeItem('loginUser');
          } else {
            localStorage.removeItem('loginAdmin');
            localStorage.setItem('loginUser', userData.userEmail);
          }
          sessionStorage.setItem('accessTime', nowEpoch());

          userData.serviceNo = serviceNo;
          dispatch(setUser({ user: userData, sessionTimeout: sessionTimeout }));
        }

        // 부가인증 정보 초기화
        if (customer === 'hyundai') {
          localStorage.setItem('otpUser', '');
        }

        // 모바일 확인 (옵션에 따라 다르게 처리하기 위해 내부에서 판단)
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Opera Mini|IEMobile|Mobile|Tablet/i.test(
            window.navigator.userAgent,
          )
        ) {
          if (customer === 'saxa') {
            window.location.href = 'https://www.saxa.co.jp/product/mail_training';
          } else if (isJapan) {
            window.location.href = 'https://www.jsecurity.co.jp/mudfix';
          } else {
            window.location.href = 'https://www.jiransecurity.com/products/mudfix';
          }
        }
      }
    } catch (error) {
      console.log('checkLogin', error);
    }
    setLoginChecked(true);
  };

  // 권한에 따라 반환할 컴포넌트 결정 (authLevel: 1: 사용자, 2: 관리자)
  const checkAuth = (renderItem: any, authLevel: any, isEduPage: boolean) => {
    // 필요한 권한과 로그인한 권한이 일치할 경우 해당 컴포넌트 반환
    // 권한이 일치하지 않을 경우 로그인 화면으로 이동

    // 교육 페이지 권한 체크
    if (isEduPage) {
      return eduAuth === authLevel ? renderItem : <Redirect to="/edumain/edulogin" />;
    }

    // 기본 페이지 권한 체크
    const loginPath = authLevel === 1 ? <Redirect to="/login" /> : <Redirect to="/mudlogin" />;
    return auth === authLevel ? renderItem : loginPath;
  };

  const RouterComponent = [];

  let initalPath =
    Option.isJapan || localStorage.getItem('language') === 'ja' ? '/login' : '/intro';
  const loginAuth = isEduPage ? eduAuth : auth;
  if (loginAuth) {
    // 로그인한 경우
    if (isEduPage) {
      initalPath = '/edumain/eduplay';
    } else {
      initalPath = auth === 1 ? '/dashboard' : '/mudmanage';
    }
  }

  RouterComponent.push(
    <Route key="/" exact path="/" render={() => <Redirect to={initalPath} />} />,
  );

  // 로그인 인증 필요없는 component
  const req = require.context('components/standard', false, /^(?!.\/index).*.tsx$/);
  req.keys().forEach((key) => {
    const componentName = key.substring(2).replace('.tsx', '').toLowerCase();
    const component = req(key);

    RouterComponent.push(
      component?.authLevel === 0 ? (
        <Route key={componentName} path={`/${componentName}`} component={component.default} />
      ) : (
        <Route
          key={componentName}
          path={`/${componentName}`}
          render={() => checkAuth(<component.default />, component?.authLevel, isEduPage)}
        />
      ),
    );
  });

  // console.log(RouterComponent);

  return (
    <div className="App">
      <BrowserRouter>
        {loginChecked && (
          <>
            <Header auth={auth} />
            <div className={`content ${isEduPage ? 'full' : ''}`}>
              <Switch>
                {RouterComponent}
                <Redirect path="*" to="/" />
              </Switch>
            </div>
          </>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
