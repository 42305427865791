import React from 'react';
import { useIntl } from 'react-intl';
import { Popover, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Checkbox from 'antd/lib/checkbox/Checkbox';

interface targetProps {
  data: any;
  dataByTagNo: any;
  isChecked: boolean;
  selectedType: string;
  isAllTargetCheck: boolean;
  onSelectCheck: (type: string, targetNo: number) => void;
}

function TargetScrollItem({
  data,
  dataByTagNo,
  isChecked,
  selectedType,
  isAllTargetCheck,
  onSelectCheck,
}: targetProps) {
  const { formatMessage } = useIntl();

  // 훈련 결과
  let statusText = formatMessage({ id: 'Infection_28', defaultMessage: '없음' });
  let statusColor = '';
  if (data.examInfo) {
    const { examType, cure, infection, download, read } = data.examInfo;
    statusText = formatMessage({ id: 'Status_10', defaultMessage: '양호' });
    statusColor = 'color-brand-green';
    if (cure) {
      statusText = formatMessage({ id: 'Cure_1', defaultMessage: '치료' });
      statusColor = 'color-cure';
    } else if (infection) {
      statusText =
        examType === 4
          ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
          : formatMessage({ id: 'Infection_24', defaultMessage: 'PC 감염' });
      statusColor = 'color-red';
    } else if (download) {
      statusText =
        examType === 4
          ? formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })
          : formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' });
      statusColor = 'color-down';
    } else if (read) {
      statusText = formatMessage({ id: 'Read_1', defaultMessage: '열람' });
      statusColor = 'color-orange';
    }
  }

  // 태그 리스트
  const tagComponent =
    data.tagList &&
    data.tagList.sort().map((tagNo: any) => {
      if (tagNo && dataByTagNo[tagNo]) {
        return (
          <Tag
            className={`color-tag tag-label-${dataByTagNo[tagNo].color} small`}
            key={dataByTagNo[tagNo].tagNo}
          >
            {dataByTagNo[tagNo].tagName}
          </Tag>
        );
      }
      return <></>;
    });

  return (
    <div className="target-scroll-item list-item">
      {/* <Checkbox
        className="list-check"
        checked={isChecked && selectedType === 'check'}
        onClick={(e) => {
          e.stopPropagation();
          onSelectCheck('check', data.targetNo);
        }}
      /> */}
      <table>
        <td className="target-info">
          {/* 체크박스 */}
          <div
            className="check-div"
            onClick={(e) => {
              e.stopPropagation();
              onSelectCheck('check', data.targetNo);
            }}
            aria-hidden="true"
          >
            {isChecked && selectedType === 'check' ? (
              <img src="/img/exam/list_check_on.png" alt="check" className="list-check" />
            ) : (
              <Checkbox className="list-check" checked={false} />
            )}
          </div>

          {/* 대상자 */}
          <div className="email-column">
            <Popover
              overlayClassName="between-popover full"
              placement="topLeft"
              content={
                <>
                  <div className="popover-row">
                    <div className="text">
                      {formatMessage({ id: 'Exam_72', defaultMessage: '최근 훈련 결과' })}
                    </div>
                    <div className={`value ${statusColor}`}>{statusText}</div>
                  </div>
                  <div className="popover-row">
                    <div className="text">
                      {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                    </div>
                    <div className="value">{data.targetNo}</div>
                  </div>
                  <div className="popover-row">
                    <div className="text">
                      {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}
                    </div>
                    <div className="value">{data.targetName}</div>
                  </div>
                  <div className="popover-row">
                    <div className="text">
                      {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                    </div>
                    <div className="value">{data.targetEmail}</div>
                  </div>
                  {!!data.targetPhone && (
                    <div className="popover-row">
                      <div className="text">
                        {formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}
                      </div>
                      <div className="value">{data.targetPhone}</div>
                    </div>
                  )}
                </>
              }
            >
              <div className='ellipsis'>
                <span className={statusColor}>
                  <UserOutlined />
                </span>
                <span>{`${data.targetName} (${data.targetEmail})`}</span>
              </div>
            </Popover>
          </div>
        </td>

        {/* 소속/직급 */}
        <td>
          <div className="list-column">
            <Popover
              overlayClassName="between-popover division-popover full"
              placement="topLeft"
              content={
                <div className="popover-row">
                  <div className="text">
                    {formatMessage({ id: 'Division_1', defaultMessage: '소속' })}
                  </div>
                  <div className="value">{data.targetDivision || '-'}</div>
                </div>
              }
            >
              <div className="round-border ellipsis">{data.targetDivision || '-'}</div>
            </Popover>

            <Popover
              overlayClassName="between-popover division-popover full"
              placement="topLeft"
              content={
                <div className="popover-row">
                  <div className="text">
                    {formatMessage({ id: 'Position_1', defaultMessage: '직급' })}
                  </div>
                  <div className="value">{data.targetPosition || '-'}</div>
                </div>
              }
            >
              <div className="round-border ellipsis">{data.targetPosition || '-'}</div>
            </Popover>
          </div>
        </td>

        {/* 태그 */}
        <td>
          <div className="list-column">
            {data.tagList ? (
              <Popover
                overlayClassName="between-popover tag-popover"
                placement="topLeft"
                content={
                  <div className="tag-popover-content">
                    <div className="title">
                      <span>{formatMessage({ id: 'Tag_1', defaultMessage: '태그' })}</span>
                      <span className="color-dark-grey">{`(${formatMessage({
                        id: 'Exam_25',
                        defaultMessage: '총',
                      })} ${data.tagList.length}${formatMessage({
                        id: 'Exam_26',
                        defaultMessage: '개',
                      })})`}</span>
                    </div>
                    <div className="content">{tagComponent}</div>
                  </div>
                }
              >
                {/* {tagComponent} */}
                <div className="list-overflow">{tagComponent}</div>
              </Popover>
            ) : (
              <div className="no-tag-text">
                {formatMessage({ id: 'Tag_15', defaultMessage: '태그를 등록하세요.' })}
              </div>
            )}
          </div>
        </td>
      </table>
    </div>
  );
}

export default TargetScrollItem;
