import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  accessUserPut,
  addUser,
  changeEmailCancel,
  changeEmailResend,
  updateUser,
} from 'store/user';
import * as valid from 'utils/validation';
import { Option } from 'utils/commonValues';
import Loading from 'components/common/Loading';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import FormSelectField from 'components/common/FormSelectField';
import FormSwitchField from 'components/common/FormSwitchField';
import './UserAddEdit.scss';

function UserAddEdit({ visible, isAdmin, isAddMode, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { errors, control, register, watch, setValue, setError, handleSubmit } = useForm({
    mode: 'all',
    defaultValues: {
      userName: dataInfo.userName,
      userEmail: dataInfo.userEmail,
      userPasswd: null,
      userPhone: dataInfo.userPhone,
      serviceNo: dataInfo.serviceNo,
      serviceName: dataInfo.serviceName,
      userActived: isAddMode ? 1 : dataInfo.userActived,
      userEnabled: dataInfo.userEnabled,
      loginFailCount: dataInfo.loginFailCount,
      passwdUpdateDateNow: dataInfo.passwdUpdateDateNow,
      isEdu: isAddMode ? 1 : dataInfo.isEdu,
      storeId: dataInfo.storeId,
      customerId: dataInfo.customerId,
    },
  });

  useEffect(() => {
    if (isAdmin && !isAddMode) {
      onWriteUserLog();
    }
  }, []);

  // 사용자 개인정보 접근 로그 기록
  const onWriteUserLog = async () => {
    try {
      const params = {
        userNo: dataInfo.userNo,
      };
      await dispatch(accessUserPut(params));
    } catch (error) {
      console.log('UserAddEdit writeUserLog', error);
    }
  };

  // 이메일 변경 후 승인이 이루어지지 않았을 시 재전송/취소
  const onChangeEmail = async (type: string) => {
    try {
      const params = {
        userNo: dataInfo.userNo,
      };
      await dispatch(type === 'resend' ? changeEmailResend(params) : changeEmailCancel(params));
    } catch (error) {
      console.log('userAddEdit onChangeEmail', error);
    }
  };

  // 사용자 정보 저장
  const onSaveUser = async (formData: any) => {
    try {
      const params: any = {};

      // 값 변경 여부
      let changed = isAddMode ? true : false;
      Object.keys(formData).forEach((key: any) => {
        let value = formData[key];
        if (
          key === 'serviceNo' ||
          key === 'userActived' ||
          key === 'userEnabled' ||
          key === 'loginFailCount'
        ) {
          value = parseInt(formData[key], 10);
        }

        // 변경된 항목만 파라미터에 넣어줌
        if (value !== '' && value !== dataInfo[key]) {
          changed = true;
          params[key] = value;
        }

        // 수정일 경우 사용자번호 추가
        if (!isAddMode) {
          params.userNo = dataInfo.userNo;
        }
      });

      if (changed) {
        setLoading(true);
        const response: any = await dispatch(isAddMode ? addUser(params) : updateUser(params));
        if (response?.data?.error && response?.data?.error[0]?.result === 'Duplicate User Exist') {
          setError('userEmail', {
            type: 'duplicate',
            message: formatMessage({ id: 'Email_9', defaultMessage: '이미 등록된 이메일입니다.' }),
          });
          setLoading(false);
        } else {
          setLoading(false);
          toggleModal(false);
        }
      } else {
        toggleModal(false);
      }
    } catch (error) {
      console.log('UserAddEdit onSaveUser', error);
    }
  };

  return (
    <ModalTemplate
      className="user-add-edit-modal modal-464"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {isAddMode
              ? formatMessage({ id: 'User_15', defaultMessage: '사용자 등록' })
              : `${formatMessage({ id: 'User_11', defaultMessage: '사용자 수정' })} (No.${
                  dataInfo.userNo
                })`}
          </div>
        </div>
      }
      onOk={handleSubmit(onSaveUser)}
      onCancel={() => toggleModal(false)}
      okText={
        isAddMode
          ? formatMessage({ id: 'Button_10', defaultMessage: '등 록' })
          : formatMessage({ id: 'Button_14', defaultMessage: '수 정' })
      }
      cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      loading={loading}
    >
      <Loading loading={loading} />
      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        {/* 이름 */}
        <div className="input-title">
          {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}*
        </div>
        <FormTextField
          name="userName"
          error={errors.userName}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              name: (value: any) => valid.name(value),
            },
          }}
        />

        {/* 이메일 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}*
        </div>
        <FormTextField
          name="userEmail"
          error={errors.userEmail}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              email: (value: any) => valid.email(value),
            },
          }}
        />

        {dataInfo.userNewEmail && (
          <div className="email-message-area">
            <div className="brand-color">
              {`${formatMessage({ id: 'User_9', defaultMessage: '현재' })} '${
                dataInfo.userNewEmail
              }'${formatMessage({ id: 'User_10', defaultMessage: '이 승인 대기중입니다.' })}`}
            </div>

            <button type="button" className="resend-btn" onClick={() => onChangeEmail('resend')}>
              {formatMessage({ id: 'Resend_1', defaultMessage: '재전송' })}
            </button>
            <button type="button" onClick={() => onChangeEmail('cancel')}>
              {formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
            </button>
          </div>
        )}

        {/* 비밀번호 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Password_1', defaultMessage: '비밀번호' })}
          {isAddMode ? '*' : ''}
        </div>
        <FormTextField
          name="userPasswd"
          error={errors.userPasswd}
          register={register}
          type="password"
          showPassword
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => (isAddMode ? valid.required(value) : undefined),
              gsPassword: (value: any) => valid.gsPassword(value),
            },
          }}
        />

        {/* 연락처 */}
        <div className="text-field-title">
          {`${formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}${
            Option.customer === 'saxa' ? '*' : ''
          }`}
        </div>
        <FormTextField
          name="userPhone"
          error={errors.userPhone}
          register={register}
          arrowPosition="top"
          validation={{
            validate:
              Option.customer === 'saxa'
                ? {
                    required: (value: any) => valid.required(value),
                    phone: (value: any) => valid.userPhone(value),
                  }
                : { phone: (value: any) => valid.userPhone(value) },
          }}
        />

        {/* 서비스No */}
        {!isAddMode && (
          <>
            <div className="text-field-title">
              {formatMessage({ id: 'Service_8', defaultMessage: '서비스No' })}
            </div>
            <FormTextField
              className="disabled"
              name="serviceNo"
              error={errors.serviceNo}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
            />
          </>
        )}

        {/* 판매점ID */}
        <div className="text-field-title">
          {formatMessage({ id: 'User_23', defaultMessage: '판매점ID' })}*
        </div>
        <FormTextField
          name="storeId"
          error={errors.storeId}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              engNumber: (value: any) => valid.engNumber(value),
              range: (value: any) => valid.range(value, 0, 20),
            },
          }}
        />

        {/* 고객ID */}
        <div className="text-field-title">
          {`${formatMessage({ id: 'User_24', defaultMessage: '고객ID' })}${
            Option.customer === 'saxa' ? '*' : ''
          }`}
        </div>
        <FormTextField
          name="customerId"
          error={errors.customerId}
          register={register}
          arrowPosition="top"
          validation={{
            validate:
              watch('customerId') || Option.customer === 'saxa'
                ? {
                    engNumber: (value: any) => valid.engNumber(value),
                    range: (value: any) => valid.range(value, 0, 20),
                  }
                : {},
          }}
        />

        {/* 서비스명 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Service_1', defaultMessage: '서비스명' })}*
        </div>
        <FormTextField
          name="serviceName"
          error={errors.serviceName}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
              name: (value: any) => valid.name(value),
            },
          }}
        />

        <div className="admin-menu">
          <div className="menu-title">
            {formatMessage({ id: 'User_21', defaultMessage: '관리자 메뉴' })}
          </div>
          <div className="account-row">
            {/* 계정 활성화 */}
            <div className="activate">
              <div className="input-title">
                {formatMessage({ id: 'User_17', defaultMessage: '계정 활성화' })}
              </div>
              <FormSelectField
                name="userActived"
                control={control}
                error={errors.userActived}
                menuList={[
                  {
                    key: 1,
                    value: 1,
                    text: formatMessage({ id: 'User_4', defaultMessage: '정상' }),
                  },
                  {
                    key: 0,
                    value: 0,
                    text: formatMessage({ id: 'User_16', defaultMessage: '비활성' }),
                  },
                ]}
                defaultValue={isAddMode ? 1 : dataInfo.userActived}
                showArrow
              />
            </div>
            {/* 계정 삭제 */}
            {!isAddMode && (
              <div className="enable">
                <div className="input-title">
                  {formatMessage({ id: 'User_18', defaultMessage: '계정 삭제' })}
                </div>
                <FormSelectField
                  name="userEnabled"
                  control={control}
                  error={errors.userEnabled}
                  menuList={[
                    {
                      key: 1,
                      value: 1,
                      text: formatMessage({ id: 'User_4', defaultMessage: '정상' }),
                    },
                    {
                      key: 0,
                      value: 0,
                      text: formatMessage({ id: 'User_19', defaultMessage: '삭제' }),
                    },
                  ]}
                  defaultValue={isAddMode ? 1 : dataInfo.userEnabled}
                  showArrow
                />
              </div>
            )}
          </div>

          {/* 로그인 실패 횟수 */}
          {!isAddMode && (
            <>
              <div className="text-field-title">
                {formatMessage({ id: 'User_20', defaultMessage: '로그인 실패 횟수' })}
              </div>
              <FormTextField
                name="loginFailCount"
                error={errors.loginFailCount}
                register={register}
                arrowPosition="top"
                validation={{
                  validate: {
                    required: (value: any) => valid.required(value),
                    number: (value: any) => valid.number(value),
                    range: (value: any) => valid.range(value, 0, 50),
                  },
                }}
              />
            </>
          )}

          {/* 최근 비밀번호 수정일을 현재로 변경 */}
          {!isAddMode && (
            <div className="switch-wrap">
              <FormSwitchField
                // className="input-switch"
                control={control}
                name="passwdUpdateDateNow"
                handleOnChange={(name: string, data: number) => setValue(name, data.toString())}
              />
              <div className="input-title">
                {formatMessage({
                  id: 'Password_23',
                  defaultMessage: '최근 비밀번호 수정일을 현재로 변경',
                })}
              </div>
            </div>
          )}

          {/* 교육관리 사용 */}
          {Option.isEdu === 1 && (
            <>
              <div className="text-field-title">
                {formatMessage({ id: 'User_22', defaultMessage: '교육관리 사용' })}
              </div>
              <FormSelectField
                name="isEdu"
                control={control}
                error={errors.isEdu}
                menuList={[
                  {
                    key: 1,
                    value: 1,
                    text: formatMessage({ id: 'Phishing_8', defaultMessage: '사용' }),
                  },
                  {
                    key: 0,
                    value: 0,
                    text: formatMessage({ id: 'User_16', defaultMessage: '비활성' }),
                  },
                ]}
                defaultValue={isAddMode ? 1 : dataInfo.isEdu}
                showArrow
              />
            </>
          )}
        </div>
      </form>
    </ModalTemplate>
  );
}

export default UserAddEdit;
