import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Tooltip } from 'antd';
import {
  getTag,
  changeTagState,
  putTag,
  addTag,
  deleteTag,
  deleteAllTag,
  checkTag,
} from 'store/tag';
import { initForm } from 'store/form';
import { getInitialTag, openNotification, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import TagItem from 'components/branch/tag/TagItem';
import TagColor from 'components/branch/tag/TagColor';
import * as valid from 'utils/validation';
import './Tag.scss';

// import icoSearchImage from 'img/tag/ico_search.png';
// import tagOrImage from 'img/tag/ico_tag_or.png';
// import tagAndImage from 'img/tag/ico_tag_and.png';
// import addImage from 'img/tag/ico_make.png';
// import editImage from 'img/tag/ico_modify.png';
// import closeImage from 'img/tag/ico_close.png';
// import openImage from 'img/tag/ico_open.png';
// import searchImage from 'img/exam/search.png';
// import cancelImage from 'img/exam/search_cancel.png';

function TagSidebar({ type }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  // 선택한 옵션 버튼
  const [selectBtn, setSelectBtn] = useState('');
  // 모달 대상
  const [openModal, setOpenModal] = useState('');
  // 검색어 필터
  const [searchText, setSearchText] = useState('');
  const [editData, setEditData]: any = useState({});
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('red');
  const tagData = useSelector((state: any) => {
    return {
      data: state.tag.data,
      tagInitialData: state.tag.tagInitialData,
      isAllOpen: state.tag.isAllOpen,
      openList: state.tag.openList,
      dataByTagNo: state.tag.dataByTagNo,
      param: state.tag.param,
      selectedTag: state.tag.selectedTag,
      needReload: state.tag.needReload,
      multiType: state.tag.multiType,
    };
  }, shallowEqual);
  const tagReload = useSelector((state: any) => state.target.tagReload);
  const { errors, register, getValues, handleSubmit, setError } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    onGetTag();
  }, [tagData.param, tagData.needReload, tagReload]);

  // 태그 정보 조회
  const onGetTag = async () => {
    try {
      const params: any = { filter: tagData.param.filter };
      await dispatch(getTag(params));
    } catch (error) {
      console.log('TagSidebar onGetTag', error);
    }
  };

  // 태그 버튼 클릭 이벤트(검색, 조건 선택, 추가, 편집)
  const clickHeaderBtn = (type: string) => {
    setSelectBtn(selectBtn === type ? '' : type);

    if (type === 'or' || type === 'and') {
      dispatch(
        changeTagState({ key: 'multiType', value: tagData.multiType === type ? 'or' : type }),
      );

      // 여러 개 선택 버튼 해제 시
      if (selectBtn === type) {
        // 마지막에 선택한 태그만 추출
        const lastItem = tagData.selectedTag.pop();
        dispatch(changeTagState({ key: 'selectedTag', value: [lastItem] }));
      }
    }
  };

  // 태그 선택
  const clickTag = (tag: any) => {
    let selectTagList = [...tagData.selectedTag];

    if (selectBtn === 'or' || selectBtn === 'and') {
      // 여러 태그 선택 버튼 클릭한 상태에서 항목 클릭 시
      if (selectTagList.includes(tag)) {
        selectTagList.splice(selectTagList.indexOf(tag), 1);
        // 선택한 태그가 하나도 없을 시 모든 대상자로 지정
        if (selectTagList.length < 1) {
          selectTagList = ['all-tag'];
        }
      } else {
        // 모든 대상자 또는 태그가 없는 대상자가 선택된 상태에서 태그 클릭 시 리셋하고 넣어줌
        if (
          selectTagList[0] === 'all-tag' ||
          selectTagList[0] === 'no-tag' ||
          tag === 'all-tag' ||
          tag === 'no-tag'
        ) {
          selectTagList = [];
        }
        selectTagList.push(tag);
      }
    } else {
      // 단일 태그 선택 시
      selectTagList = [tag];
    }

    dispatch(changeTagState({ key: 'selectedTag', value: selectTagList }));
  };

  // 태그 목록 열기/닫기
  const toggleOpenList = (tagName: string) => {
    let openTagList = [...tagData.openList];

    if (tagName === 'all') {
      openTagList = tagData.isAllOpen ? [] : Object.keys(tagData.tagInitialData);
      dispatch(changeTagState({ key: 'isAllOpen', value: !tagData.isAllOpen }));
    }

    if (openTagList.includes(tagName)) {
      openTagList.splice(openTagList.indexOf(tagName), 1);
    } else {
      openTagList.push(tagName);
    }

    dispatch(changeTagState({ key: 'openList', value: openTagList }));
  };

  // 필터 검색
  const handleSearch = () => {
    if (searchText) {
      tagData.param.filter.tagName = searchText;
    } else {
      // 검색어 없을 시 tagName 파라미터 제거
      delete tagData.param.filter.tagName;
    }

    dispatch(changeTagState({ key: 'param', value: { filter: tagData.param.filter } }));
  };

  // 필터 검색 취소
  const handleCancelSearch = () => {
    setSearchText('');
    delete tagData.param.filter.tagName;
    dispatch(changeTagState({ key: 'param', value: { filter: tagData.param.filter } }));
  };

  // 태그명 수정
  const onEditTagName = async (tagName: any) => {
    // 변경 사항 없을 경우 리턴
    if (tagName === editData.tagName) return;

    try {
      const params = {
        tagNo: editData.tagNo,
        tagName: tagName,
        tagInitial: getInitialTag(tagName),
        beforeTagInitial: getInitialTag(editData.tagName),
      };
      const response: any = await dispatch(putTag(params));
      if (response?.data) {
        openNotification(
          `'${editData.tagName}'${formatMessage({
            id: 'Tag_48',
            defaultMessage: '에서',
          })} '${tagName}'${formatMessage({ id: 'Tag_49', defaultMessage: '로 수정' })}`,
        );
        setEditData({});
      }
    } catch (error) {
      console.log('TagSidebar onEditTagName', error);
    }
  };

  // 태그 색상 수정
  const onEditColor = async (color: string) => {
    try {
      const params = {
        tagNo: editData.tagNo,
        color: color,
      };
      const response: any = await dispatch(putTag(params));
      if (response?.data) {
        openNotification(
          `'${editData.tagName}' ${formatMessage({
            id: 'Tag_5',
            defaultMessage: '태그 색상 수정',
          })}`,
        );
        setEditData({});
      }
    } catch (error) {
      console.log('TagSidebar onEditColor', error);
    }
    setEditData({ ...editData, color: color });
  };

  // 모달 닫기(초기화)
  const onCloseModal = () => {
    setOpenModal('');
    dispatch(initForm());
  };

  // 태그 만들기
  const onAddTag = async () => {
    const { tagName } = getValues();

    try {
      // 태그명 중복체크
      const res: any = await dispatch(checkTag({ filter: { tagNameExact: tagName } }));
      if (res?.data?.list?.length > 0) {
        setError('tagName', {
          type: 'duplicate',
          message: formatMessage({ id: 'Tag_56', defaultMessage: '이미 등록된 태그명입니다.' }),
        });
      } else {
        const params = {
          tagName: tagName,
          tagInitial: getInitialTag(tagName),
          color: color,
        };
        const response: any = await dispatch(addTag(params));
        if (response?.data?.error?.length > 0) {
          setError('tagName', {
            type: 'notAllowed',
            message: formatMessage({
              id: 'Tag_53',
              defaultMessage: '입력 값(태그)이 올바르지 않습니다.',
            }),
          });
        } else {
          openNotification(
            `'${tagName}' ${formatMessage({ id: 'Tag_6', defaultMessage: '태그 등록' })}`,
          );
          onCloseModal();
          setColor('red');
        }
      }
    } catch (error) {
      console.log('TagSidebar onAddTag', error);
    }
  };

  // 태그 삭제
  const onDeleteTag = async () => {
    try {
      const params = {
        tagNo: editData.tagNo,
      };
      const response: any = await dispatch(deleteTag(params));
      if (response?.data) {
        dispatch(changeTagState({ key: 'selectedTag', value: ['all-tag'] }));
        openNotification(
          `'${editData.tagName}' ${formatMessage({ id: 'Tag_7', defaultMessage: '태그 삭제' })}`,
        );
        onCloseModal();
      }
    } catch (error) {
      console.log('TagSidebar onDeleteTag', error);
    }
  };

  // 모든 태그 삭제
  const onDeleteAllTag = async () => {
    try {
      const response: any = await dispatch(deleteAllTag());
      if (response?.data) {
        openNotification(formatMessage({ id: 'Tag_7', defaultMessage: '태그 삭제' }));
        onCloseModal();
      }
    } catch (error) {
      console.log('TagSidebar onDeleteAllTag', error);
    }
  };

  return (
    <div className="summary-list tag-sidebar">
      <div className="tag-header">
        <div className="bold">{formatMessage({ id: 'Tag_1', defaultMessage: '태그' })}</div>
        <div className="tag-header-btns">
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
          >
            <img
              src="/img/tag/ico_search.png"
              alt="search"
              className={`header-icon ${selectBtn === 'search' ? 'selected' : ''}`}
              onClick={() => clickHeaderBtn('search')}
              aria-hidden="true"
            />
          </Tooltip>
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({ id: 'Tag_33', defaultMessage: '태그를 여러개 선택(OR 조건)' })}
          >
            <img
              src="/img/tag/ico_tag_or.png"
              alt="or"
              className={`header-icon ${selectBtn === 'or' ? 'selected' : ''}`}
              onClick={() => clickHeaderBtn('or')}
              aria-hidden="true"
            />
          </Tooltip>

          {type !== 'declare' && (
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottom"
              title={formatMessage({
                id: 'Tag_34',
                defaultMessage: '태그를 여러개 선택(AND 조건)',
              })}
            >
              <img
                src="/img/tag/ico_tag_and.png"
                alt="and"
                className={`header-icon ${selectBtn === 'and' ? 'selected' : ''}`}
                onClick={() => clickHeaderBtn('and')}
                aria-hidden="true"
              />
            </Tooltip>
          )}

          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({ id: 'Tag_4', defaultMessage: '태그 만들기' })}
          >
            <img
              src="/img/tag/ico_make.png"
              alt="add"
              className={`header-icon ${selectBtn === 'add' ? 'selected' : ''}`}
              onClick={() => setOpenModal('add')}
              aria-hidden="true"
            />
          </Tooltip>
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={formatMessage({ id: 'Tag_45', defaultMessage: '편집' })}
          >
            <img
              src="/img/tag/ico_modify.png"
              alt="edit"
              className={`header-icon ${selectBtn === 'edit' ? 'selected' : ''}`}
              onClick={() => clickHeaderBtn('edit')}
              aria-hidden="true"
            />
          </Tooltip>
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={
              tagData.isAllOpen
                ? formatMessage({ id: 'Exam_3', defaultMessage: '전체 닫기' })
                : formatMessage({ id: 'Exam_4', defaultMessage: '전체 열기' })
            }
          >
            <img
              src={tagData.isAllOpen ? '/img/tag/ico_close.png' : '/img/tag/ico_open.png'}
              alt="arrow"
              className="header-icon"
              onClick={() => toggleOpenList('all')}
              aria-hidden="true"
            />
          </Tooltip>
        </div>
      </div>
      {/* 검색 영역 */}
      {selectBtn === 'search' && (
        <div className="tag-search">
          <div className="summary-search-item active">
            <div className="summary-search-text">
              <input
                type="text"
                value={searchText}
                onChange={(e: any) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
                placeholder={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
              />
              <div className="summary-search-btns">
                <img
                  className="search-btn"
                  src="/img/exam/search.png"
                  alt="search"
                  onClick={handleSearch}
                  aria-hidden="true"
                  title={formatMessage({ id: 'Filter_8', defaultMessage: '검색' })}
                />
                <img
                  className="search-btn"
                  src="/img/exam/search_cancel.png"
                  alt="cancel"
                  onClick={handleCancelSearch}
                  aria-hidden="true"
                  title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {/* 태그 리스트 */}
      <div className={`tag-inner ${selectBtn === 'search' ? 'large' : ''}`}>
        <div
          className={`tag-list-btn ${tagData.selectedTag?.includes('all-tag') ? 'selected' : ''}`}
          onClick={() => clickTag('all-tag')}
          aria-hidden="true"
        >
          {formatMessage({ id: 'Tag_42', defaultMessage: '모든 대상자' })}
        </div>
        {Object.keys(tagData.tagInitialData)
          .sort()
          .map((tagInitial: any) => {
            return (
              <TagItem
                key={tagInitial}
                type={type}
                tagInitial={tagInitial}
                tagData={tagData.tagInitialData[tagInitial]}
                isOpen={tagData.openList.includes(tagInitial)}
                isEditMode={selectBtn === 'edit'}
                selectedTag={tagData.selectedTag}
                editData={editData}
                setEditData={setEditData}
                toggleOpenList={toggleOpenList}
                clickTag={clickTag}
                changeTagName={onEditTagName}
                changeTagColor={onEditColor}
                setModal={setOpenModal}
              />
            );
          })}
        <div
          className={`tag-list-btn ${tagData.selectedTag?.includes('no-tag') ? 'selected' : ''}`}
          onClick={() => clickTag('no-tag')}
          aria-hidden="true"
        >
          {formatMessage({ id: 'Tag_43', defaultMessage: '태그가 없는 대상자' })}
        </div>
        <div
          className="tag-list-btn delete"
          onClick={() => setOpenModal('deleteAll')}
          aria-hidden="true"
        >
          {formatMessage({ id: 'Tag_44', defaultMessage: '모든 태그 삭제' })}
        </div>
      </div>

      {/* 태그 만들기 모달 */}
      {openModal === 'add' && (
        <ModalTemplate
          visible={openModal === 'add'}
          className="modal-464"
          onOk={handleSubmit(onAddTag)}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_16', defaultMessage: '생 성' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          title={formatMessage({ id: 'Tag_4', defaultMessage: '태그 만들기' })}
        >
          <form autoComplete="off">
            <div className="input-title">
              {formatMessage({ id: 'Tag_2', defaultMessage: '태그명' })}
            </div>
            <FormTextField
              name="tagName"
              error={errors.tagName}
              register={register}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  name: (value: any) => valid.name(value),
                },
              }}
              arrowPosition="top"
            />

            <TagColor color={color} onSelectColor={setColor} className="tag-add-full" />
          </form>
        </ModalTemplate>
      )}

      {/* 태그 삭제 모달 */}
      {openModal === 'delete' && (
        <ModalTemplate
          className="modal-464"
          visible={openModal === 'delete'}
          title={formatMessage({ id: 'Tag_7', defaultMessage: '태그 삭제' })}
          onOk={onDeleteTag}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({ id: 'Tag_35', defaultMessage: '삭제한 태그는 복구할 수 없습니다.' })}
            </div>
            <div>{formatMessage({ id: 'Tag_50', defaultMessage: '다음 태그를 삭제합니까?' })}</div>
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">No</div>
              </li>
              <div>{editData.tagNo}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Tag_2', defaultMessage: '태그명' })}
                </div>
              </li>
              <div>{editData.tagName}</div>
            </ul>
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
                </div>
              </li>
              <div>{timeFormatFromUTCEpoch(editData.regEpoch)}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}

      {/* 모든 태그 삭제 모달 */}
      {openModal === 'deleteAll' && (
        <ModalTemplate
          className="modal-464"
          visible={openModal === 'deleteAll'}
          title={formatMessage({ id: 'Tag_44', defaultMessage: '모든 태그 삭제' })}
          onOk={onDeleteAllTag}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({ id: 'Tag_35', defaultMessage: '삭제한 태그는 복구할 수 없습니다.' })}
            </div>
            <div>{formatMessage({ id: 'Tag_23', defaultMessage: '모든 태그를 삭제합니까?' })}</div>
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Tag_3', defaultMessage: '태그 수' })}
                </div>
              </li>
              <div>{`${tagData.data.length}${formatMessage({
                id: 'StartExam_21',
                defaultMessage: '개',
              })}`}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}

export default TagSidebar;
