import { SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getExamSummary, changeSummaryParam } from 'store/exam';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import TableDatePicker from 'components/common/TableDatePicker';
import ExamIngSettings from 'components/branch/examTemplate/ExamIngSettings';
import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';

export default function ExamIngList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.exam.examSummary.param.sort,
      filter: state.exam.examSummary.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.exam.examSummary.needReload);
  const respData = useSelector((state: any) => state.exam.examSummary.data);
  const totalCount = useSelector((state: any) => state.exam.examSummary.totalCount);
  const totalPages = useSelector((state: any) => state.exam.examSummary.totalPages);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  useEffect(() => {
    onGetExamSummary();
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  //   const getEduTemplateData = async () => {
  //     if (!loading) {
  //       setLoading(true);

  //       try {
  //         const params = {
  //           filter: JSON.stringify(filter),
  //           sort: JSON.stringify(sort),
  //           offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
  //           limit: pageSize < 1 ? 20 : pageSize,
  //         };

  //         await dispatch(getEduTemplate(params));
  //       } catch (e) {
  //         console.log(e);
  //       }
  //     }

  //     setLoading(false);
  //   };

  // 훈련 목록 조회
  const onGetExamSummary = async () => {
    setLoading(true);
    try {
      const params = {
        filter: JSON.stringify({ ...filter, examIng: 1 }),
        sort: JSON.stringify(sort),
        offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
        limit: pageSize < 1 ? 20 : pageSize,
      };
      await dispatch(getExamSummary(params));
      setLoading(false);
    } catch (error) {
      console.log('ExamIngList getExamSummary', error);
    }
  };

  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    examNo: 'No',
    examName: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
  };

  // 필터
  const filterOption: { [key: string]: any } = {};

  // 테이블
  const baseColumns = [
    {
      Header: <SettingOutlined />,
      accessor: 'setting',
      resizable: false,
      sortable: false,
      width: 100,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center settings',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return <ExamIngSettings isAdmin={isAdmin} dataInfo={props.row.original} />;
      },
    },
    {
      Header: 'No',
      accessor: 'examNo',
      resizable: false,
      sortable: true,
      width: 80,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
      accessor: 'serviceNo',
      resizable: false,
      sortable: true,
      width: 80,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
      accessor: 'examName',
      resizable: true,
      sortable: true,
      width: 250,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' }),
      accessor: 'templateName',
      resizable: true,
      sortable: true,
      width: 250,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Period_3', defaultMessage: '훈련 기간' }),
      accessor: 'examStartEpoch',
      resizable: true,
      sortable: true,
      width: 130,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        const {
          value,
          row: { original },
        } = props;
        return `${timeFormatFromUTCEpoch(value, 3)} ~ ${timeFormatFromUTCEpoch(
          original.examEndEpoch,
          3,
        )}`;
      },
    },
    {
      Header: formatMessage({ id: 'Template_28', defaultMessage: '진행률' }),
      accessor: 'examEndEpoch',
      resizable: false,
      sortable: true,
      width: 80,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        const {
          value,
          row: { original },
        } = props;

        // 진행률
        let progress = 0;
        if (original.examStartEpoch <= nowEpoch()) {
          progress = Math.round(
            ((nowEpoch() - Number(original.examStartEpoch)) /
              (Number(value) - Number(original.examStartEpoch))) *
              100,
          );

          if (progress < 1) {
            progress = 1;
          } else if (progress > 100) {
            progress = 100;
          }
        }

        return `${progress}%`;
      },
    },
    {
      Header: (
        <TableDatePicker
          dateType="RegEpoch"
          title={formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
          filter={filter}
          actionParam={changeSummaryParam}
        />
      ),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 150,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
  ];

  const columns: Array<any> = useMemo(() => baseColumns, [respData]);

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeSummaryParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData}
        changeParam={changeSummaryParam}
        loading={loading}
        disableKey="isEnable"
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
