import { apiUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const CHANGE_PASSWORD = 'user/CHANGE_PASSWORD';
const SET_USER = 'user/SET_USER';
const MENU_ONOFF = 'user/MENU_ONOFF';
const GET_USER = 'user/GET_PHISHING';
const UPDATE_USER = 'user/UPDATE_USER';
const CHANGE_PARAM = 'user/CHANGE_PARAM';
const CHANGE_EMAIL_CANCEL = 'user/CHANGE_EMAIL_CANCEL';
const RELOAD_USER = 'user/RELOAD_USER';
const CHANGE_STATE = 'user/CHANGE_STATE';

// Actions
export const initSetting = (params: any) =>
  actionFormat(CHANGE_PASSWORD, params, 'put', `${apiUrl}User/InitSetting`);
export const getUser = (params?: { [key: string]: any }) =>
  actionFormat(GET_USER, params, 'get', `${apiUrl}User`);
export const addUser = (params?: { [key: string]: any }) =>
  actionFormat(RELOAD_USER, params, 'post', `${apiUrl}User`);
export const updateUser = (params?: { [key: string]: any }) =>
  actionFormat(UPDATE_USER, params, 'put', `${apiUrl}User`);
export const deleteUser = (params?: { [key: string]: any }) =>
  actionFormat(RELOAD_USER, params, 'delete', `${apiUrl}User`);
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');
export const setUser = (params: any) => actionFormat(SET_USER, params);
export const menuOnoff = () => actionFormat(MENU_ONOFF);
export const editPassword = (params: any) =>
  actionFormat(null, params, 'put', `${apiUrl}User/EditPasswd`);
export const changeEmailCancel = (params?: { [key: string]: any }) =>
  actionFormat(CHANGE_EMAIL_CANCEL, params, 'put', `${apiUrl}User/ChangeEmailCancel`);
export const changeEmailResend = (params?: { [key: string]: any }) =>
  actionFormat(null, params, 'put', `${apiUrl}User/ChangeEmailReSend`);
export const accessUserPut = (params?: { [key: string]: any }) =>
  actionFormat(null, params, 'get', `${apiUrl}User/AccessUserPut`);
export const changeState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_STATE, params, null, '');

// initial state
interface stateType {
  adminMenu: boolean;
  user: any;
  isAdmin: number;
  sessionTimeout: number;
  data: { [key: string]: any };
  param: { [key: string]: any };
  needReload: boolean;
  totalPages: number;
  totalCount: number;
}

// Initial state
const initialState: stateType = {
  adminMenu: true,
  user: null,
  isAdmin: 0,
  sessionTimeout: 0,
  data: {
    result: -1,
    success: [],
    fail: [],
  },
  param: {
    filter: {},
    sort: [{ field: 'userNo', order: 'DESC' }],
  },
  needReload: false,
  totalPages: 0,
  totalCount: 0,
};

// Reducers
export default function (state: any = initialState, action: any) {
  switch (action.type) {
    case CHANGE_PASSWORD:
      return state;
    case GET_USER:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.data = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        state.needReload = false;
        state.totalPages = totalPages;
        state.totalCount = totalCount;
      }
      return state;
    case UPDATE_USER:
      if (action.payload) {
        const { list } = action.payload.data;
        if (list) {
          state.user = { ...state.user, ...list[0] };
          state.needReload = true;
        }
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    case SET_USER:
      if (action.payload) {
        const { user, sessionTimeout } = action.payload;
        state.user = user;
        state.isAdmin = user.isAdmin;
        state.sessionTimeout = sessionTimeout;
      }
      return state;
    case MENU_ONOFF:
      state.adminMenu = !state.adminMenu;
      return state;
    case CHANGE_EMAIL_CANCEL:
      if (action.payload) {
        state.user = { ...state.user, userNewEmail: null };
      }
      return state;
    case RELOAD_USER:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case CHANGE_STATE:
      if (action.payload) {
        const { name, value } = action.payload;
        state[name] = value;
      }
      return state;
    default:
      return state;
  }
}
