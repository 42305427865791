import React from 'react';
import { useIntl } from 'react-intl';
import { Progress, Dropdown, Menu, Tooltip } from 'antd';
import {
  EditFilled,
  DeleteFilled,
  PauseCircleFilled,
  PlayCircleFilled,
  CloseCircleFilled,
  WarningFilled,
  ExclamationCircleFilled,
  RedoOutlined,
} from '@ant-design/icons';
import { nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';

// import listOpenImage from 'img/exam/list_open.png';
// import listCloseImage from 'img/exam/list_close.png';
// import settingImage from 'img/exam/setting.png';
// import phishingRedImage from 'img/exam/phishing_red.png';
// import infectionRedImage from 'img/exam/infection_red.png';
// import reserveImage from 'img/exam/send_reserve.png';
// import stopImage from 'img/exam/send_stop.png';

function ExamSidebarItem(props: any) {
  const { formatMessage } = useIntl();
  const {
    summaryInfo,
    isOpen,
    selectedItem,
    toggleOpen,
    onClickItem,
    onClickDetailRow,
    onClickDropdownMenu,
  } = props;
  const {
    examNo,
    examName,
    examStartEpoch,
    examEndEpoch,
    parentsExamNo,
    parentExamCount,
    target,
    send,
    sendError,
    read,
    download,
    infection,
    cure,
    isSuspend, // 정지 상태 여부
    templateName,
    templatePick,
    examType,
    linkAttach,
    attachData,
  } = summaryInfo;

  // 진행률(%)
  let progress = 1;
  // 훈련 종료 여부
  let isEnd = true;
  // 예약 훈련
  let isReserve = false;
  // 진행중 여부
  let isIng = false;

  // 진행률
  if (examStartEpoch > nowEpoch()) {
    progress = 0;
    isReserve = true;
  } else {
    progress = Math.round(((nowEpoch() - examStartEpoch) / (examEndEpoch - examStartEpoch)) * 100);

    if (progress < 1) {
      progress = 1;
    } else if (progress >= 100) {
      progress = 100;
      isEnd = true;
    }

    if (progress > 0 && progress < 100) {
      isIng = true;
      isEnd = false;
    }
  }

  // 일시정지 여부
  const isSendPause = !isEnd && target > send + sendError ? true : false;

  // 설정 드롭다운 메뉴 데이터
  const settingMenuItem = [
    {
      show: isIng,
      type: 'examEdit',
      icon: <EditFilled />,
      text: formatMessage({ id: 'Button_14', defaultMessage: '수 정' }),
    },
    {
      show: true,
      type: 'examDelete',
      icon: <DeleteFilled />,
      text: formatMessage({ id: 'Button_15', defaultMessage: '삭 제' }),
    },
    {
      show: isSendPause && isSuspend === 0,
      type: 'sendStop',
      icon: <PauseCircleFilled />,
      text: formatMessage({ id: 'Send_10', defaultMessage: '메일 발송 일시정지' }),
    },
    {
      show: isSendPause && isSuspend === 1,
      type: 'sendRestart',
      icon: <PlayCircleFilled />,
      text: formatMessage({ id: 'Send_11', defaultMessage: '메일 발송 재시작' }),
    },
    {
      show: isReserve,
      type: 'examExecute',
      icon: <PlayCircleFilled />,
      text: formatMessage({ id: 'Exam_10', defaultMessage: '훈련 즉시 실행' }),
    },
    {
      show: !isEnd,
      type: 'examEnd',
      icon: <CloseCircleFilled />,
      text: formatMessage({ id: 'Exam_11', defaultMessage: '훈련 즉시 종료' }),
    },
    {
      show: isIng && !(isSendPause && isSuspend === 1),
      type: 'examResend',
      icon: <WarningFilled />,
      text: formatMessage({ id: 'Resend_3', defaultMessage: '훈련 메일 재전송' }),
    },
    {
      show: isIng && !(isSendPause && isSuspend === 1),
      type: 'failTargetExamResend',
      icon: <WarningFilled />,
      text: formatMessage({ id: 'Resend_4', defaultMessage: '실패한 메일 재전송' }),
    },
    {
      show: true,
      type: 'retraining',
      icon: <RedoOutlined />,
      text: formatMessage({ id: 'StartExam_71', defaultMessage: '재훈련' }),
    },
  ];

  // 설정 드롭다운 메뉴
  let settingMenu = null;
  settingMenu = (
    <Menu>
      {settingMenuItem.map((menu: any) => {
        return (
          menu.show && (
            <Menu.Item
              key={menu.type}
              onClick={() => {
                onClickDropdownMenu(examNo, menu.type);
              }}
            >
              {menu.icon}
              {menu.text}
            </Menu.Item>
          )
        );
      })}
    </Menu>
  );

  // 훈련 유형
  let templateExamType = '';
  if (examType === 1 && attachData.attachExamType === 1) {
    templateExamType = 'warning';
  } else if (examType === 1 && attachData.attachExamType === 2) {
    templateExamType = 'file';
  } else if (examType === 1 && attachData.attachExamType === 3) {
    templateExamType = 'research';
  } else if (examType === 4) {
    templateExamType = 'phishing';
  }

  // 발송 메시지
  let sendText = isSuspend
    ? formatMessage({
        id: 'Send_11',
        defaultMessage: '메일 발송 일시정지',
      })
    : '';
  if (sendError) {
    if (sendText) sendText += ' / ';
    sendText += `${formatMessage({
      id: 'Send_5',
      defaultMessage: '발송 실패',
    })}: ${sendError}${formatMessage({
      id: 'StartExam_21',
      defaultMessage: '개',
    })}`;
  }
  if (sendText) {
    sendText = ` - ${sendText}`;
  }

  const examItemComp = (
    <div className="summary-item-inner">
      <div className="inner-top">
        {/* 감염 아이콘 */}
        {infection > 0 && (
          <Tooltip
            overlayClassName="black-tooltip"
            placement="top"
            title={
              templateExamType === 'phishing'
                ? formatMessage({ id: 'Infection_27', defaultMessage: '정보 유출 있음' })
                : formatMessage({ id: 'Infection_8', defaultMessage: '감염 대상 있음' })
            }
          >
            <div className="infection-icon">
              {templateExamType === 'phishing' ? (
                <img src="/img/exam/phishing_red.png" alt="phishing" />
              ) : (
                <img src="/img/exam/infection_red.png" alt="infection" />
              )}
            </div>
          </Tooltip>
        )}
        {/* 메일 발송 일시정지 아이콘 */}
        {isSuspend === 1 && (
          <Tooltip
            overlayClassName="black-tooltip"
            placement="top"
            title={formatMessage({ id: 'Send_11', defaultMessage: '메일 발송 일시정지' })}
          >
            <div className="send-icon">
              <img src="/img/exam/send_stop.png" alt="stop" />
            </div>
          </Tooltip>
        )}
        {/* 예약 훈련 아이콘 */}
        {isReserve && (
          <Tooltip
            overlayClassName="black-tooltip"
            placement="top"
            title={formatMessage({ id: 'Exam_9', defaultMessage: '예약 훈련' })}
          >
            <div className="send-icon">
              <img src="/img/exam/send_reserve.png" alt="reserve" />
            </div>
          </Tooltip>
        )}
        {/* 그룹 개수 */}
        {parentsExamNo && <div className="group-count">{parentExamCount}</div>}
        {/* 훈련명 */}
        <div className={`exam-text ${isOpen ? '' : 'ellipsis'}`}>{examName}</div>
        {/* 우측 버튼 */}
        <div
          className="exam-buttons"
          onClick={(e: any) => {
            // 훈련이 선택되지 않도록 하기 위함
            e.stopPropagation();
          }}
          aria-hidden="true"
        >
          {/* 설정 */}
          <div>
            <Dropdown
              getPopupContainer={(triggerNode: any) => triggerNode.parentElement} // 부모 노드와 함께 움직이도록 설정
              overlayClassName="summary-dropdown"
              overlay={settingMenu}
              trigger={['click']}
              placement="bottomRight"
            >
              <img className="icon setting" src="/img/exam/setting.png" alt="setting" />
            </Dropdown>
          </div>
          {/* 열기/닫기 */}
          <div
            onClick={() => {
              toggleOpen(examNo);
            }}
            aria-hidden="true"
          >
            {isOpen ? (
              <img src="/img/exam/list_close.png" alt="arrow" />
            ) : (
              <img src="/img/exam/list_open.png" alt="arrow" />
            )}
          </div>
        </div>
      </div>
      <div className="inner-bottom">
        {isOpen && (
          <div className="summary-detail">
            <div className="detail-bg-box">
              <div className="flex">
                <span className="detail-title">
                  {formatMessage({ id: 'Period_1', defaultMessage: '기간' })}
                </span>
                <span className="detail-value">{`${timeFormatFromUTCEpoch(
                  examStartEpoch,
                  3,
                )} ~ ${timeFormatFromUTCEpoch(examEndEpoch, 3)}`}</span>
              </div>
              <Tooltip
                className="flex"
                overlayClassName="black-tooltip"
                placement="bottom"
                title={
                  <div>
                    <div>
                      {`${
                        templatePick
                          ? formatMessage({ id: 'User_1', defaultMessage: '사용자' })
                          : formatMessage({ id: 'Template_14', defaultMessage: '기 본' })
                      } / `}
                      {templateExamType === 'warning' &&
                        formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
                      {templateExamType === 'file' &&
                        formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
                      {templateExamType === 'research' &&
                        formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
                      {templateExamType === 'phishing' &&
                        formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
                      {` / `}
                      {linkAttach === 1 &&
                        formatMessage({ id: 'Template_15', defaultMessage: '첨부 파일' })}
                      {linkAttach === 2 &&
                        formatMessage({ id: 'Template_16', defaultMessage: '링크 첨부' })}
                      {linkAttach === 3 &&
                        formatMessage({ id: 'Template_17', defaultMessage: '링크 + 첨부' })}
                    </div>
                    <div>{` / ${templateName}`}</div>
                  </div>
                }
              >
                <span className="detail-title">
                  {formatMessage({ id: 'Template_27', defaultMessage: '템플릿' })}
                </span>
                <span className="detail-value ellipsis">{templateName}</span>
              </Tooltip>
            </div>

            <div className="detail-content">
              {/* 훈련 대상 */}
              <div className="detail-row target">
                <div className="detail-title">
                  {formatMessage({ id: 'Target_1', defaultMessage: '훈련 대상' })}
                </div>
                <div className="detail-content-value">{target}</div>
              </div>

              {/* 메일 발송 */}
              <Tooltip
                overlayClassName="black-tooltip"
                placement="bottom"
                title={`${formatMessage({
                  id: 'Exam_12',
                  defaultMessage: '메일이 발송된 대상만 보기',
                })}${sendText}`}
              >
                <div
                  className="detail-row"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onClickDetailRow(examNo, 'send');
                  }}
                  aria-hidden="true"
                >
                  <div
                    className={`detail-title ${sendError ? 'color-red' : ''} ${
                      isSuspend === 1 ? 'color-orange' : ''
                    }`}
                  >
                    {formatMessage({ id: 'Send_2', defaultMessage: '메일 발송' })}
                    {/* 메일 일시정지 */}
                    {isSuspend === 1 && <PauseCircleFilled className="color-orange" />}
                    {/* 일부 진행중일 때 */}
                    {send + sendError < target && send + sendError !== 0 && (
                      <div>
                        <span>{`(${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })} ${
                          send === 0 ? '0' : ((send / target) * 100).toFixed(0)
                        }%)`}</span>
                      </div>
                    )}
                    {/* 발송 실패 */}
                    {!!sendError && <ExclamationCircleFilled className="color-red" />}
                  </div>
                  <div
                    className={`detail-content-value ${sendError ? 'color-red' : ''} ${
                      isSuspend === 1 ? 'color-orange' : ''
                    }`}
                  >
                    {send}
                  </div>
                </div>
              </Tooltip>

              {/* 메일 열람 */}
              <Tooltip
                overlayClassName="black-tooltip"
                placement="bottom"
                title={formatMessage({
                  id: 'Exam_13',
                  defaultMessage: '메일을 열람한 대상만 보기',
                })}
              >
                <div
                  className="detail-row"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onClickDetailRow(examNo, 'read');
                  }}
                  aria-hidden="true"
                >
                  <div className={`detail-title ${read ? 'color-orange' : ''}`}>
                    {formatMessage({ id: 'Read_2', defaultMessage: '메일 열람' })}
                  </div>
                  <div className={`detail-content-value ${read ? 'color-orange' : ''}`}>{read}</div>
                </div>
              </Tooltip>

              {/* 파일 다운 / 피싱 접속 */}
              <Tooltip
                overlayClassName="black-tooltip"
                placement="bottom"
                title={`${
                  templateExamType === 'phishing'
                    ? formatMessage({
                        id: 'Exam_17',
                        defaultMessage: '피싱 페이지에 접속한 대상 보기',
                      })
                    : formatMessage({
                        id: 'Exam_15',
                        defaultMessage: '파일을 다운로드한 대상만 보기',
                      })
                }`}
              >
                <div
                  className="detail-row"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onClickDetailRow(
                      examNo,
                      templateExamType === 'phishing' ? 'connect' : 'fileDown',
                    );
                  }}
                  aria-hidden="true"
                >
                  <div className="detail-title">
                    {templateExamType === 'phishing'
                      ? formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })
                      : formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' })}
                  </div>
                  <div className="detail-content-value">{download}</div>
                </div>
              </Tooltip>

              {/* 감염 / 정보 유출 */}
              <Tooltip
                overlayClassName="black-tooltip"
                placement="bottom"
                title={`${
                  templateExamType === 'phishing'
                    ? formatMessage({
                        id: 'Exam_18',
                        defaultMessage: '정보 유출한 대상 보기',
                      })
                    : formatMessage({
                        id: 'Exam_14',
                        defaultMessage: '메일의 첨부파일을 실행(감염)한 대상만 보기',
                      })
                }`}
              >
                <div
                  className="detail-row"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    onClickDetailRow(
                      examNo,
                      templateExamType === 'phishing' ? 'phishing' : 'fileInfection',
                    );
                  }}
                  aria-hidden="true"
                >
                  <div className="detail-title">
                    {templateExamType === 'phishing'
                      ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
                      : formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}
                  </div>
                  <div className="detail-content-value">{infection}</div>
                </div>
              </Tooltip>

              {/* 치료 */}
              {templateExamType === 'file' && (
                <Tooltip
                  overlayClassName="black-tooltip"
                  placement="bottom"
                  title={formatMessage({
                    id: 'Exam_16',
                    defaultMessage: '악성파일을 치료한 대상 보기',
                  })}
                >
                  <div
                    className="detail-row"
                    onClick={(e: any) => {
                      e.stopPropagation();
                      onClickDetailRow(examNo, 'cure');
                    }}
                    aria-hidden="true"
                  >
                    <div className="detail-title">
                      {formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
                    </div>
                    <div className="detail-content-value">{cure}</div>
                  </div>
                </Tooltip>
              )}
            </div>
          </div>
        )}

        <div className="summary-progress">
          <Tooltip
            className="flex"
            overlayClassName="black-tooltip"
            placement="bottom"
            title={
              isReserve
                ? formatMessage({ id: 'Exam_9', defaultMessage: '예약 훈련' })
                : `${formatMessage({
                    id: 'Template_28',
                    defaultMessage: '진행률',
                  })}: ${progress}%`
            }
          >
            <Progress
              percent={progress}
              status="active"
              showInfo={false}
              className={`${isReserve ? 'disabled' : ''}`}
              strokeColor={progress === 100 ? '#14ae96' : '#ee4f4f'}
              strokeWidth={isOpen ? 21 : 6}
            />
            {isOpen && (
              <div className="progress-status" style={{ width: `${progress}%` }}>
                <div
                  className="status-text"
                  style={{ width: `${progress}%` }}
                >{`${progress}%`}</div>
              </div>
            )}
          </Tooltip>
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`summary-item-wrap summary-item ${selectedItem === examNo ? 'selected' : ''} ${
        parentsExamNo ? 'group' : ''
      }`}
      onClick={() => onClickItem(examNo)}
      aria-hidden="true"
    >
      {examItemComp}
    </div>
  );
}

export default ExamSidebarItem;
