import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Progress, Tag, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { getExamTarget } from 'store/exam';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import ListStep from 'components/common/ListStep';
import ModalTemplate from 'components/common/ModalTemplate';
import ExamListExtra from 'components/branch/exam/ExamListExtra';

// import targetImage from 'img/exam/step/target.png';
// import sendImage from 'img/exam/step/send.png';
// import readImage from 'img/exam/step/read.png';
// import phishingImage from 'img/exam/step/phishing.png';
// import accessImage from 'img/exam/step/access.png';
// import downImage from 'img/exam/step/down.png';
// import infectionImage from 'img/exam/step/infection.png';
// import cureImage from 'img/exam/step/cure.png';

// import targetActiveImage from 'img/exam/step/target_a.png';
// import sendActiveImage from 'img/exam/step/send_a.png';
// import sendFailImage from 'img/exam/step/send_fail_a.png';
// import sendReserveImage from 'img/exam/step/send_reserve_a.png';
// import sendStopImage from 'img/exam/step/send_stop_a.png';
// import readActiveImage from 'img/exam/step/read_a.png';
// import readMobileImage from 'img/exam/step/read_a_mobile.png';
// import phishingActiveImage from 'img/exam/step/phishing_a.png';
// import accessActiveImage from 'img/exam/step/access_a.png';
// import downActiveImage from 'img/exam/step/down_a.png';
// import infectionActiveImage from 'img/exam/step/infection_a.png';
// import cureActiveImage from 'img/exam/step/cure_a.png';

// import lineSendImage from 'img/exam/step/line_send.png';
// import lineReadImage from 'img/exam/step/line_read.png';
// import lineDownImage from 'img/exam/step/line_down.png';
// import lineInfectionImage from 'img/exam/step/line_infection.png';
// import lineCureImage from 'img/exam/step/line_cure.png';

// import circleTargetImage from 'img/exam/step/circle_target.png';
// import circleSendImage from 'img/exam/step/circle_send.png';
// import circleReadImage from 'img/exam/step/circle_read.png';
// import circleDownImage from 'img/exam/step/circle_down.png';
// import circleInfectionImage from 'img/exam/step/circle_infection.png';
// import circleCureImage from 'img/exam/step/circle_cure.png';

interface EachProps {
  data: any;
  summaryData: any;
  type: string;
}

function ExamItemEach({ data, summaryData, type }: EachProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [failVisible, setFailVisible] = useState(false);
  const [errorLog, setErrorLog] = useState('');

  // 대상 리스트 조회(발송 실패 로그 확인)
  const onGetExamTarget = async () => {
    try {
      const params = {
        examNo: data.examNo,
        targetNo: data.targetNo,
      };
      const response: any = await dispatch(getExamTarget(params));
      if (response?.data?.list) {
        setErrorLog(response.data.list[0].sendLog);
      }
    } catch (error) {
      console.log('ExamItemEach onGetExamTarget', error);
    }
  };

  // 피싱 유도
  const isPhishing = useMemo(() => summaryData?.examType === 4, [summaryData?.examType]);
  // 메일 발송 실패
  const sendFail = useMemo(() => data.sendError, [data.sendError]);
  // 메일 나눔 발송
  const sendReserve = useMemo(() => data.sendReserve && !data.send, [data.sendReserve, data.send]);
  // 메일 발송 일시 정지
  const sendStop = useMemo(() => data.isSuspend && !data.send, [data.isSuspend, data.send]);
  // 치료
  const isCure = useMemo(
    () => summaryData?.examType === 1 && summaryData?.attachData.attachExamType === 2,
    [data.isSuspend, data.send],
  );

  // 대상 메시지
  const targetMsg = formatMessage({ id: 'Target_2', defaultMessage: '대상' });
  // 열람 메시지
  const readMsg = formatMessage({ id: 'Read_1', defaultMessage: '열람' });
  // 다운/접속 메시지
  const downMsg = isPhishing
    ? formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })
    : formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' });
  // 감염/유출 메시지
  const infectionMsg = isPhishing
    ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
    : formatMessage({ id: 'Infection_1', defaultMessage: '감염' });
  // 치료 메시지
  const cureMsg = formatMessage({ id: 'Cure_1', defaultMessage: '치료' });

  // 발송 툴팁 메시지
  let sendTooltip = formatMessage({ id: 'Send_1', defaultMessage: '발송' });
  let sendIcon = '/img/exam/step/send.png';
  if (type === 'examTarget') {
    if (sendFail) {
      sendTooltip = formatMessage({ id: 'Send_5', defaultMessage: '발송 실패' });
      sendIcon = '/img/exam/step/send_fail_a.png';
    } else if (sendStop) {
      sendTooltip = `${
        sendReserve
          ? `(${formatMessage({
              id: 'Send_8',
              defaultMessage: '발송 일시 정지',
            })}}) ${formatMessage({
              id: 'Send_4',
              defaultMessage: '발송 예약',
            })}: ${timeFormatFromUTCEpoch(data.sendReserve)}`
          : formatMessage({ id: 'Send_8', defaultMessage: '발송 일시 정지' })
      }`;
      sendIcon = sendReserve
        ? '/img/exam/step/send_reserve_a.png'
        : '/img/exam/step/send_stop_a.png';
    } else if (sendReserve && !sendStop) {
      sendTooltip = `${formatMessage({
        id: 'Send_4',
        defaultMessage: '발송 예약',
      })}: ${timeFormatFromUTCEpoch(data.sendReserve)}`;
      sendIcon = '/img/exam/step/send_stop_a.png';
    } else if (data.send) {
      sendTooltip += `: ${timeFormatFromUTCEpoch(data.send)}`;
    }
  } else {
    sendTooltip += `: ${data.send}${
      sendFail
        ? ` / ${formatMessage({ id: 'Send_14', defaultMessage: '실패' })}: ${data.sendError}`
        : ''
    }`;
  }

  // 열람 툴팁 메시지
  let readTooltip = readMsg;
  if (type === 'examTarget') {
    readTooltip += data.read ? `: ${timeFormatFromUTCEpoch(data.read)}` : '';
    readTooltip += data.readDeviceInfo ? ` [${data.readDeviceInfo}]` : '';
    readTooltip += data.readIp ? ` ${data.readIp}` : '';
  } else {
    readTooltip += `: ${data.read}`;
  }

  // 다운/접속 툴팁 메시지
  let downTooltip = downMsg;
  if (type === 'examTarget') {
    downTooltip += data.download ? `: ${timeFormatFromUTCEpoch(data.download)}` : '';
    downTooltip += data.downloadDeviceInfo ? ` [${data.downloadDeviceInfo}]` : '';
    downTooltip += data.downloadIp ? ` ${data.downloadIp}` : '';
  } else {
    downTooltip += `: ${data.download}`;
  }

  let downIcon = null;
  let infectionIcon = null;
  if (type === 'examTarget') {
    downIcon = isPhishing ? '/img/exam/step/access.png' : '/img/exam/step/down.png';
    infectionIcon = isPhishing ? '/img/exam/step/phishing.png' : '/img/exam/step/infection.png';
  } else {
    downIcon = isPhishing ? '/img/exam/step/access_a.png' : '/img/exam/step/down_a.png';
    infectionIcon = isPhishing ? '/img/exam/step/phishing_a.png' : '/img/exam/step/infection_a.png';
  }

  // 훈련 상태 표시 데이터
  const stepData = [
    {
      view: true,
      icon: {
        stepIcon: '/img/exam/step/target.png',
        active: true,
        activeIcon: '/img/exam/step/target_a.png',
        circleIcon: type === 'examTarget' ? '/img/exam/step/circle_target.png' : null,
      },
      tooltipTitle: type === 'examTarget' ? targetMsg : `${targetMsg}: ${data.target}`,
      count: type === 'examTarget' ? 0 : data.target,
    },
    {
      view: true,
      icon: {
        stepIcon: sendIcon,
        active: data.send || sendReserve || sendFail,
        activeIcon: sendStop || sendFail || sendReserve ? sendIcon : '/img/exam/step/send_a.png',
        lineIcon: type === 'examTarget' ? '/img/exam/step/line_send.png' : null,
        circleIcon: type === 'examTarget' ? '/img/exam/step/circle_send.png' : null,
      },
      tooltipTitle: sendTooltip,
      count: type === 'examTarget' ? 0 : data.send,
      countColor: sendFail ? 'color-red' : '',
    },
    {
      view: true,
      icon: {
        stepIcon: type === 'examTarget' ? '/img/exam/step/read.png' : '/img/exam/step/read_a.png',
        active: data.read,
        activeIcon:
          data.readDeviceType && data.readDeviceType !== 1
            ? '/img/exam/step/read_a_mobile.png'
            : '/img/exam/step/read_a.png',
        lineIcon: type === 'examTarget' ? '/img/exam/step/line_read.png' : null,
        circleIcon: type === 'examTarget' ? '/img/exam/step/circle_read.png' : null,
      },
      tooltipTitle: readTooltip,
      count: type === 'examTarget' ? 0 : data.read,
      countColor: 'color-orange',
    },
    {
      view: true,
      icon: {
        stepIcon: downIcon,
        active: data.download || data.infection,
        activeIcon: isPhishing ? '/img/exam/step/access_a.png' : '/img/exam/step/down_a.png',
        lineIcon: type === 'examTarget' ? '/img/exam/step/line_down.png' : null,
        circleIcon: type === 'examTarget' ? '/img/exam/step/circle_down.png' : null,
      },
      tooltipTitle: downTooltip,
      count: type === 'examTarget' ? 0 : data.download,
      countColor: 'color-down',
    },
    {
      view: true,
      icon: {
        stepIcon: infectionIcon,
        active: data.infection,
        activeIcon: isPhishing ? '/img/exam/step/phishing_a.png' : '/img/exam/step/infection_a.png',
        lineIcon: type === 'examTarget' ? '/img/exam/step/line_infection.png' : null,
        circleIcon: type === 'examTarget' ? '/img/exam/step/circle_infection.png' : null,
      },
      tooltipTitle:
        type === 'examTarget'
          ? `${infectionMsg}${data.infection ? `: ${timeFormatFromUTCEpoch(data.infection)}` : ''}`
          : `${infectionMsg}: ${data.infection}`,
      count: type === 'examTarget' ? 0 : data.infection,
      countColor: 'color-red',
    },
    {
      view: isCure,
      icon: {
        stepIcon: type === 'examTarget' ? '/img/exam/step/cure.png' : '/img/exam/step/cure_a.png',
        active: data.cure,
        activeIcon: '/img/exam/step/cure_a.png',
        lineIcon: type === 'examTarget' ? '/img/exam/step/line_cure.png' : null,
        circleIcon: type === 'examTarget' ? '/img/exam/step/circle_cure.png' : null,
      },
      tooltipTitle:
        type === 'examTarget'
          ? `${cureMsg}${data.cure ? `: ${timeFormatFromUTCEpoch(data.cure)}` : ''}`
          : `${cureMsg}: ${data.cure}`,
      count: type === 'examTarget' ? 0 : data.cure,
      countColor: 'color-cure',
    },
  ];

  let statusMsg = '';
  let statusColor = '';
  // 대상 탭
  if (isCure && data.cure) {
    statusMsg = cureMsg;
    statusColor = 'color-cure';
  } else if (data.infection) {
    statusMsg = infectionMsg;
    statusColor = 'color-red';
  } else if ((data.linkAttach === 2 || isPhishing) && data.download) {
    statusMsg = downMsg;
    statusColor = 'color-down';
  } else if (data.read) {
    statusMsg = readMsg;
    statusColor = 'color-orange';
  } else if (sendFail) {
    statusMsg = formatMessage({ id: 'Send_5', defaultMessage: '발송 실패' });
    statusColor = 'color-red';
  } else if (sendStop) {
    statusMsg = formatMessage({ id: 'Send_8', defaultMessage: '발송 일시 정지' });
  } else if (sendReserve) {
    statusMsg = formatMessage({ id: 'Send_4', defaultMessage: '발송 예약' });
  } else if (data.send) {
    statusMsg = formatMessage({ id: 'Exam_61', defaultMessage: '안전' });
  } else if (data.target) {
    statusMsg = formatMessage({ id: 'Exam_62', defaultMessage: '준비' });
  }

  // 감염/유출 퍼센트
  let infectionPercent = Number(((data.infection / data.target) * 100).toFixed(0));
  if (infectionPercent === 0 && data.infection > 0) {
    infectionPercent = 1;
  }

  return (
    <div
      className={`list-item exam-list-item ${
        type === 'examTarget' && data.isEnable !== 1 ? 'deleted' : ''
      }`}
    >
      {/* 대상자명(대상자이메일) */}
      {type === 'examTarget' ? (
        <div className="email-column">
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={`${
              data.isEnable !== 1
                ? formatMessage({ id: 'Exam_60', defaultMessage: '[삭제된 대상자]' })
                : ''
            } ${data.targetName} (${data.targetEmail})`}
          >
            <div className="full-area">
              <img src="/img/exam/step/target.png" alt="target" />
              <div className="target-name ellipsis">{`${data.targetName} (${data.targetEmail})`}</div>
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className="name-column">
          <Tooltip overlayClassName="black-tooltip" placement="bottom" title={data.tagName}>
            <Tag className={`color-tag tag-label-${data.color} small ellipsis`}>{data.tagName}</Tag>
          </Tooltip>
        </div>
      )}

      {/* 소속/직급 */}
      {type === 'examTarget' && (
        <div className="list-column">
          <Tooltip
            overlayClassName="black-tooltip"
            placement="bottom"
            title={`${formatMessage({ id: 'Division_1', defaultMessage: '소속' })}: ${
              data.targetDivision || '-'
            } / ${formatMessage({ id: 'Position_1', defaultMessage: '직급' })}: ${
              data.targetPosition || '-'
            } `}
          >
            <div className="target-company ellipsis">{`${data.targetDivision || '-'} / ${
              data.targetPosition || '-'
            }`}</div>
          </Tooltip>
        </div>
      )}

      {/* 훈련 상태 */}
      <div className="status-column">
        {/* 훈련 상태 메시지 */}
        {type === 'examTarget' &&
          (data.sendLog ? (
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottom"
              title={formatMessage({
                id: 'Send_6',
                defaultMessage: "메일 발송 실패 이유 'click'",
              })}
            >
              <div
                onClick={(e: any) => {
                  e.stopPropagation();
                  onGetExamTarget();
                  setFailVisible(true);
                }}
                aria-hidden="true"
              >
                <div className={`status-text ${statusColor}`}>
                  <QuestionCircleOutlined />
                  {statusMsg}
                </div>
              </div>
            </Tooltip>
          ) : (
            <div className={`status-text ${type === 'examTarget' ? statusColor : ''} `}>
              {statusMsg}
            </div>
          ))}

        {type === 'examTag' &&
          (data.infection > 0 ? (
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottom"
              title={`${
                isPhishing
                  ? formatMessage({ id: 'Template_20', defaultMessage: '유출률' })
                  : formatMessage({ id: 'Template_19', defaultMessage: '감염률' })
              }: ${infectionPercent.toFixed(1)}%`}
            >
              <div>
                <span className="color-red">{infectionPercent.toFixed(1)}%</span>
                <Progress
                  className="exam-item-progress"
                  percent={infectionPercent}
                  status="active"
                  showInfo={false}
                  strokeColor="#db2828"
                  strokeWidth={6}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              overlayClassName="black-tooltip"
              placement="bottom"
              title={
                isPhishing
                  ? formatMessage({ id: 'Exam_63', defaultMessage: '안전 (유출 대상 없음)' })
                  : formatMessage({ id: 'Exam_64', defaultMessage: '안전 (감염 대상 없음)' })
              }
            >
              <div className="status-text">
                <span className="color-brand-green">
                  {formatMessage({ id: 'Exam_61', defaultMessage: '안전' })}
                </span>
                <Progress
                  className="exam-item-progress"
                  percent={100}
                  status="active"
                  showInfo={false}
                  strokeColor="#14ae96"
                  strokeWidth={6}
                />
              </div>
            </Tooltip>
          ))}

        {/* step 영역 */}
        {stepData.map(
          (item: any) =>
            item.view && (
              <ListStep
                tooltipTitle={item.tooltipTitle}
                icon={item.icon}
                count={item.count}
                countColor={item.countColor}
                key={item.tooltipTitle}
              />
            ),
        )}
      </div>

      {/* 감염pc, 신고, 교육 */}
      <ExamListExtra data={data} declareType={type === 'examTarget' ? 'date' : 'count'} />

      {/* 발송 실패 사유 모달 */}
      <ModalTemplate
        className="send-fail-modal modal-464"
        isCloseBlack
        visible={failVisible}
        onCancel={() => setFailVisible(false)}
        centered
        footer={null}
        transitionName=""
        maskTransitionName=""
      >
        <div className="bold">
          {formatMessage({ id: 'Send_7', defaultMessage: '발송 실패 사유' })}
        </div>
        <div className="fail-content">{errorLog}</div>
      </ModalTemplate>
    </div>
  );
}

export default ExamItemEach;
