import { Select, Switch } from 'antd';
import { Controller } from 'react-hook-form';
import './FormField.scss';

export default function FormSwitchField(props: any) {
  //   const [showPassword, setShowPassword] = useState(false);
  //   const [isError, setIsError] = useState(false);
  //   const [message, setMessage] = useState('');
  const {
    className,
    name,
    value,
    defaultValue,
    control,
    setValue,
    handleOnChange
  } = props;

  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        render={(field: any) => 
          setValue ? (
            <Switch
              checked={field.value}
              onChange={
                (data) => {
                  setValue(name, data)
                }}
            />
          ) : (
              <Switch
                defaultChecked={defaultValue}
                onChange={
                  (data) => {
                    handleOnChange(name, data ? 1 : 0)
                  }}
              />
            )
        }
      />
    </div>
  )
}
