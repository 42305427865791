import { useIntl } from 'react-intl';
import { Modal, Tooltip, Popover } from 'antd';

// import checkImage from 'img/exam/startExam/ico_check.png';
// import infoImage from 'img/exam/startExam/ico_info.png';
// import failImage from 'img/exam/startExam/ico_fail.png';
// import arrowImage from 'img/exam/startExam/btn_arrow.png';
// import qmarkImage from 'img/exam/startExam/btn_qmark.png';

function StartExamStep1(props: any) {
  const { prepareData, isFail, moveRouter } = props;
  const { formatMessage } = useIntl();

  // 환경 검사 확인 사항
  const smtpCheck = prepareData?.mailSmtpTested === '1';
  const targetCheck = prepareData?.targetCount > 0;
  const tagCheck = prepareData?.tagCount > 0;
  const templateCheck = prepareData?.templateCheckCount > 0;
  const licenseCheck = !!prepareData?.licenseDetailEnc;

  const prepareTextList = [
    //  SMTP 설정 체크
    {
      icon: smtpCheck ? '/img/exam/startExam/ico_check.png' : '/img/exam/startExam/ico_fail.png',
      text: {
        main: smtpCheck
          ? formatMessage({ id: 'StartExam_14', defaultMessage: 'SMTP 설정 및 테스트 완료' })
          : formatMessage({
              id: 'StartExam_15',
              defaultMessage: 'SMTP 설정 및 테스트 미수행',
            }),
        sub: '',
      },
      extra: {
        arrow: {
          link: smtpCheck ? '' : '/mypage/smtp',
          text: smtpCheck
            ? ''
            : formatMessage({ id: 'StartExam_16', defaultMessage: 'SMTP 설정으로 이동' }),
        },
        qmark: {
          text: smtpCheck
            ? formatMessage({
                id: 'StartExam_14',
                defaultMessage: 'SMTP 설정 및 테스트 완료',
              })
            : formatMessage({
                id: 'StartExam_30',
                defaultMessage:
                  '대상자 메일의 SMTP 서버 정보를 입력하고 제공된 동작 테스트를 해보세요.',
              }),
        },
      },
    },
    // 훈련 대상자 체크
    {
      icon: targetCheck ? '/img/exam/startExam/ico_check.png' : '/img/exam/startExam/ico_fail.png',
      text: {
        main: targetCheck
          ? formatMessage({ id: 'StartExam_17', defaultMessage: '훈련 대상자 있음' })
          : formatMessage({ id: 'StartExam_18', defaultMessage: '훈련 대상자 없음' }),
        sub: targetCheck
          ? `(${prepareData?.targetCount}${formatMessage({
              id: 'StartExam_35',
              defaultMessage: '명',
            })})`
          : '',
      },
      extra: {
        arrow: {
          link: targetCheck ? '' : '/mypage/target',
          text: targetCheck
            ? ''
            : formatMessage({ id: 'StartExam_19', defaultMessage: '대상 관리로 이동' }),
        },
        qmark: {
          text: formatMessage({
            id: 'StartExam_31',
            defaultMessage:
              '훈련 대상자를 등록하세요. 단일 대상자를 입력하여 등록하거나 지정된 형식의 엑셀로 일괄 등록이 가능합니다.',
          }),
        },
      },
    },
    // 태그 체크
    {
      icon: tagCheck ? '/img/exam/startExam/ico_check.png' : '/img/exam/startExam/ico_info.png',
      text: {
        main: tagCheck
          ? formatMessage({
              id: 'StartExam_20',
              defaultMessage: '훈련 대상 태그 있음',
            })
          : formatMessage({ id: 'StartExam_22', defaultMessage: '훈련 대상 태그 없음' }),
        sub: tagCheck
          ? `(${prepareData?.tagCount}${formatMessage({
              id: 'StartExam_21',
              defaultMessage: '개',
            })})`
          : '',
      },
      extra: {
        arrow: {
          link: tagCheck ? '' : '/mypage/target',
          text: tagCheck
            ? ''
            : formatMessage({ id: 'StartExam_19', defaultMessage: '대상 관리로 이동' }),
        },
        qmark: {
          text: formatMessage({
            id: 'StartExam_32',
            defaultMessage:
              '훈련 대상자에 태그를 부여하면 태그를 이용하여 한번에 여러명의 대상자를 선택할 수 있습니다.',
          }),
        },
      },
    },
    // 훈련 양식 체크
    {
      icon: templateCheck
        ? '/img/exam/startExam/ico_check.png'
        : '/img/exam/startExam/ico_info.png',
      text: {
        main: templateCheck
          ? formatMessage({
              id: 'StartExam_23',
              defaultMessage: '성공적으로 발송된 훈련 양식(템플릿)이 있음',
            })
          : formatMessage({
              id: 'StartExam_24',
              defaultMessage: '성공적으로 발송된 훈련 양식(템플릿)이 없음',
            }),
        sub: templateCheck
          ? `(${prepareData?.templateCheckCount}${formatMessage({
              id: 'StartExam_21',
              defaultMessage: '개',
            })})`
          : '',
      },
      extra: {
        arrow: {
          link: templateCheck ? '' : '/template/examtemplate',
          text: templateCheck
            ? ''
            : formatMessage({ id: 'StartExam_25', defaultMessage: '템플릿 관리로 이동' }),
        },
        qmark: {
          text: formatMessage({
            id: 'StartExam_33',
            defaultMessage:
              '훈련 메일이 스팸 차단 솔루션 등에 의해 차단될 수 있습니다. 미리 본인 메일로 발송하여 정상 수신 여부를 확인해 보세요.',
          }),
        },
      },
    },
    // 라이선스 체크
    {
      icon: licenseCheck ? '/img/exam/startExam/ico_check.png' : '/img/exam/startExam/ico_fail.png',
      text: {
        main: licenseCheck
          ? formatMessage({
              id: 'StartExam_26',
              defaultMessage: '서비스 이용 라이선스 있음',
            })
          : formatMessage({
              id: 'StartExam_27',
              defaultMessage: '서비스 이용 라이선스 없음',
            }),
            
        sub: '',
        
        // sub: licenseCheck
        //   ? ''
        //   : `(${formatMessage({
        //       id: 'StartExam_28',
        //       defaultMessage: '발송 대상 5명으로 제한됨',
        //     })})`,
      },
      extra: {
        arrow: {
          link: licenseCheck ? '' : '/mypage/license',
          text: licenseCheck
            ? ''
            : formatMessage({ id: 'StartExam_29', defaultMessage: '라이선스 구매로 이동' }),
        },
        qmark: {
          text: formatMessage({
            id: 'StartExam_34',
            defaultMessage:
              '라이선스를 구매하면 보다 많은 인원을 대상으로 무제한 훈련이 가능합니다.',
          }),
        },
      },
    },
  ];

  return (
    <div className="step1-content">
      <div className={`prepare-summary ${isFail ? 'failed' : ''}`}>
        {isFail
          ? formatMessage({
              id: 'StartExam_13',
              defaultMessage: '훈련이 가능하지만 아래 사항을 먼저 확인하는 것이 좋습니다.',
            })
          : formatMessage({
              id: 'StartExam_12',
              defaultMessage: '훈련에 필요한 모든 사항이 올바르게 설정되었습니다.',
            })}
      </div>
      <div className="prepare-content">
        {prepareTextList.map((item: any) => {
          return (
            <div className="prepare-row" key={item.text.main}>
              <img className="prepare-check" src={item.icon} alt="check" />
              <div className="prepare-text">
                <span className="main-text">{item.text.main}</span>
                <span className="sub-text">{item.text.sub}</span>
              </div>
              <div className="prepare-extra">
                {!!item.extra.arrow.text && (
                  <Tooltip
                    overlayClassName="black-tooltip"
                    placement="top"
                    title={item.extra.arrow.text}
                  >
                    <img
                      src="/img/exam/startExam/btn_arrow.png"
                      alt="move"
                      onClick={() => moveRouter(item.extra.arrow.link)}
                      aria-hidden="true"
                    />
                  </Tooltip>
                )}
                {!!item.extra.qmark.text && (
                  <Popover placement="right" content={item.extra.qmark.text}>
                    <img src="/img/exam/startExam/btn_qmark.png" alt="help" />
                  </Popover>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default StartExamStep1;
