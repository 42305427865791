import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox, Popover, Radio, Select, Tag } from 'antd';
import {
  makeTemplateNo,
  deleteTemplateNo,
  uploadExamTemplateBodyImage,
  editTemplate,
} from 'store/template';
import { fitTwoDigit } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import * as valid from 'utils/validation';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import Loading from 'components/common/Loading';
import FormButtonGroup from 'components/common/FormButtonGroup';
import FormSelectField from 'components/common/FormSelectField';
import CustomEditor from 'components/common/CustomEditor';
import './ExamTemplate.scss';

interface ExamTemplateProps {
  visible: boolean;
  isAdmin: boolean; // 사용자, 관리자 구분
  isAddMode: boolean; // 등록, 수정 구분
  templateInfo: any; // 템플릿 정보
  toggleModal: (open: boolean) => void;
}

function ExamTemplateAddEdit({
  visible,
  isAdmin,
  isAddMode,
  templateInfo,
  toggleModal,
}: ExamTemplateProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    watch,
    errors,
    control,
  } = useForm({
    mode: 'all',
    defaultValues: {
      ...templateInfo,
      attachInfoNo: templateInfo.attachInfoNo === 0 ? -1 : templateInfo.attachInfoNo,
      outlookPreview: isAddMode ? 1 : templateInfo.outlookPreview,
    },
  });

  const examAttachData = useSelector((state: any) => {
    return {
      attachData: state.attach.data.success,
      attachDataByNo: state.attach.listIndexByNo,
    };
  });
  const phishingData = useSelector((state: any) => state.phishing.data.success);

  const [loading, setLoading] = useState(false);
  const [toolbarDisable, setToolbarDisable] = useState({ convertHtml: true, screenHtml: true });
  const [curTemplateNo, setCurTemplateNo] = useState(0);
  // 메일 본문 에디터 내용
  const [convertHtml, setConvertHtml] = useState('');
  // 잠금 화면 에디터 내용
  const [screenHtml, setScreenHtml] = useState('');
  const watchValues = watch();

  const {
    serviceTemplateNo,
    serviceNo,
    templateNo,
    attachInfoNo,
    phishingNo,
    attachCompressName,
    attachName,
  } = templateInfo;

  useEffect(() => {
    if (isAddMode) {
      onMakeTemplateNo();
    } else {
      setCurTemplateNo(templateInfo.templateNo);
    }

    if (templateInfo.examType === 4) {
      changePhishingContents(templateInfo.phishingNo);
    } else {
      setConvertHtml(templateInfo.body || '');
      setScreenHtml(templateInfo.attachScreenContent || '');
    }

    let compName = '';
    let attName = '';
    if (attachCompressName) {
      const index = attachCompressName.indexOf('.');
      compName = attachCompressName.substring(0, index);
      setValue('attachCompressName', compName);
    }

    if (attachName) {
      const index = attachName.indexOf('.');
      attName = attachName.substring(0, index);
      setValue('attachName', attName);
    }
  }, []);

  // 템플릿 저장
  const onSaveTemplate = async () => {
    const params: any = {};

    try {
      let changed = false;
      Object.keys(watchValues).forEach((formKey: any) => {
        if (watchValues[formKey] !== templateInfo[formKey]) {
          if (formKey === 'attachName' && watchValues.attachName) {
            // 원본파일명에 선택한 첨부파일 확장자 추가
            const ext = examAttachData.attachData.filter(
              (data: any) => data.attachInfoNo === watchValues.attachInfoNo,
            )[0].fileExt;
            params[formKey] = watchValues.attachName.concat(`.${ext}`);
            if (params[formKey] !== templateInfo[formKey]) {
              changed = true;
            }
          } else if (formKey === 'attachCompressName') {
            // 압축파일명에 선택한 압축파일 종류 확장자 추가
            const ext = watchValues.attachCompress === 1 ? '.zip' : '.7z';
            params[formKey] = watchValues.attachCompressName.concat(ext);
            if (params[formKey] !== templateInfo[formKey]) {
              changed = true;
            }
          } else if (formKey === 'attachInfoNo' && watchValues[formKey] === -1) {
            // "첨부 없음" 선택 시 값을 0으로 변경하여 보내줌
            changed = true;
            params[formKey] = 0;
          } else if (formKey === 'outlookPreview' && (watchValues[formKey] !== 0 || isAddMode)) {
            // 등록일 때는 무조건, 수정일 때는 기본(0)이 아닐때만 보냄
            changed = true;
            params[formKey] = watchValues[formKey];
          } else {
            changed = true;
            params[formKey] = watchValues[formKey];
          }
        }
      });

      if (convertHtml !== templateInfo.body) {
        changed = true;
        params.body = convertHtml;
      }

      if (screenHtml !== templateInfo.attachScreenContent) {
        changed = true;
        params.attachScreenContent = screenHtml;
      }

      if (changed) {
        setLoading(true);
        if (Object.keys(params).length > 0) {
          if (!templateInfo.serviceTemplateNo && isAdmin) {
            params.examTemplateNo = curTemplateNo;
          } else {
            params.serviceTemplateNo = templateInfo.serviceTemplateNo;
          }
        }

        const response: any = await dispatch(editTemplate(params));
        if (!response?.data?.error) {
          setLoading(false);
          closeModal();
        }
      } else {
        closeModal();
      }
    } catch (error) {
      console.log('ExamTemplateAddEdit onSaveTemplate', error);
    }
  };

  // 템플릿 번호 생성
  const onMakeTemplateNo = async () => {
    try {
      const response: any = await dispatch(makeTemplateNo());
      if (response?.data?.list) {
        setCurTemplateNo(response.data.list[0].templateNo);
      }
    } catch (error) {
      console.log('ExamTemplateAddEdit onMakeTemplateNo', error);
    }
  };

  // 생성된 템플릿 번호 삭제
  const onDeleteTemplateNo = async () => {
    try {
      const params = {
        examTemplateNo: curTemplateNo,
      };
      await dispatch(deleteTemplateNo(params));
    } catch (error) {
      console.log('ExamTemplateAddEdit onDeleteTemplateNo', error);
    }
  };

  // 모달 닫기
  const closeModal = () => {
    toggleModal(false);

    if (isAddMode) {
      onDeleteTemplateNo();
    }
  };

  // 입력값 변경 시
  const changeFormValue = (type: string, value: any) => {
    setValue(type, value);

    if (type === 'phishingNo') {
      changePhishingContents(value);
    }
  };

  // 템플릿 부제목
  let headerSubText: any = '';
  if (serviceTemplateNo) {
    headerSubText = `${formatMessage({
      id: 'Template_36',
      defaultMessage: '사용자 템플릿',
    })} (No. ${serviceTemplateNo}) / ${formatMessage({
      id: 'Service_8',
      defaultMessage: '서비스No',
    })}. ${serviceNo})`;
  } else {
    headerSubText = formatMessage({ id: 'Template_38', defaultMessage: '기본 템플릿' });
    if (isAddMode) {
      headerSubText += ` ${formatMessage({ id: 'Button_22', defaultMessage: '등록' })}`;
    } else {
      headerSubText += isAdmin
        ? ` (No. ${templateNo}) ${formatMessage({
            id: 'Target_18',
            defaultMessage: '수정',
          })}`
        : ` ${formatMessage({
            id: 'Template_37',
            defaultMessage: '상세 보기 (읽기전용)',
          })}`;
    }
  }

  // 선택한 첨부파일의 정보
  const selectedAttachInfo =
    examAttachData.attachData[examAttachData.attachDataByNo[watchValues?.attachInfoNo]] || null;

  // 첨부파일 가져오기 드롭다운 메뉴
  const attachInfoOption: Array<any> = [];
  if (examAttachData?.attachData.length > 0) {
    examAttachData.attachData.forEach((data: any) => {
      // 공개인 템플릿이거나 현재 템플릿이 가지고 있는 첨부파일이 있을 때
      if (data.isPublic === 1 || (!data.isPublic && data.attachInfoNo === attachInfoNo)) {
        attachInfoOption.push(
          <Select.Option key={data.attachInfoNo} value={data.attachInfoNo}>
            <>
              <div className="attach-no">{data.attachInfoNo}</div>
              <div className="attach-value">
                <img
                  src={`/fdata/attachFile/${data.attachInfoNo}/${data.fileIcon}`}
                  alt="icon"
                  className="attach-icon"
                />
              </div>

              <div className="attach-value">
                {/* 구분 */}
                {data.isBasic === 1 ? (
                  <Tag className="temp-type-label color-temp-system">
                    {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
                  </Tag>
                ) : (
                  <Tag className="temp-type-label color-temp-user">
                    {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
                  </Tag>
                )}

                {/* 첨부유형 */}
                {data.attachCategory === 1 && (
                  <Tag className="temp-type-label color-attach-exe">
                    {formatMessage({ id: 'Attach_7', defaultMessage: '실행파일' })}
                  </Tag>
                )}
                {data.attachCategory === 2 && (
                  <Tag className="temp-type-label color-attach-lnk">
                    {formatMessage({ id: 'Attach_8', defaultMessage: '바로가기' })}
                  </Tag>
                )}
                {data.attachCategory === 3 && (
                  <Tag className="temp-type-label color-attach-doc">
                    {formatMessage({ id: 'Attach_9', defaultMessage: '문서파일' })}
                  </Tag>
                )}
                {data.attachCategory === 4 && (
                  <Tag className="temp-type-label color-attach-html">
                    {formatMessage({ id: 'Attach_10', defaultMessage: '웹문서' })}
                  </Tag>
                )}

                {/* 훈련 유형 */}
                {data.attachExamType === 1 && (
                  <Tag className="temp-type-label color-temp-warning">
                    {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
                  </Tag>
                )}
                {data.attachExamType === 2 && (
                  <Tag className="temp-type-label color-temp-file">
                    {formatMessage({ id: 'Template_11', defaultMessage: '모의 악성파일' })}
                  </Tag>
                )}
                {data.attachExamType === 3 && (
                  <Tag className="temp-type-label color-temp-research">
                    {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
                  </Tag>
                )}
                {data.attachExamType === 4 && (
                  <Tag className="temp-type-label color-temp-phishing">
                    {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
                  </Tag>
                )}
              </div>

              {/* 파일 확장자 */}
              <div className="attach-value">{data.fileExt}</div>

              <div className="attach-value">
                {/* 부가기능 */}
                {data.attachFunction &&
                  Object.keys(JSON.parse(data.attachFunction))?.map((attachKey: string) => {
                    return (
                      <Tag className="attach-label color-bg-attachFunction" key={attachKey}>
                        {attachKey}
                      </Tag>
                    );
                  })}

                {/* 제약사항 */}
                {data.attachRestrict &&
                  Object.keys(JSON.parse(data.attachRestrict))?.map((attachKey: string) => {
                    return (
                      <Tag className="attach-label color-bg-attachRestrict" key={attachKey}>
                        {attachKey}
                      </Tag>
                    );
                  })}
              </div>

              <div className="attach-value">{data.attachSubject}</div>
            </>
          </Select.Option>,
        );
      }
    });

    // 첨부 없음 항목 추가
    attachInfoOption.push(
      <Select.Option key={-1} value={-1}>
        {formatMessage({ id: 'Template_18', defaultMessage: '첨부 없음' })}
      </Select.Option>,
    );
  }

  // 피싱 유도 설정 드롭다운 메뉴
  const phishingMenu: Array<any> = [];
  if (phishingData?.length > 0) {
    phishingData.forEach((data: any) => {
      if (data.isPublic === 1 || (!data.isPublic && data.phishingNo === phishingNo)) {
        phishingMenu.push({
          key: data.phishingNo,
          value: data.phishingNo,
          text: data.phishingName,
        });
      }
    });
  }

  //  피싱 유도 변경 시 메일 본문, 결과 화면 변경
  const changePhishingContents = (value: any) => {
    const phishingInfo = phishingData.filter((data: any) => data.phishingNo === value)[0];
    if (phishingInfo) {
      const date = new Date();
      const month = date.getMonth();

      let parsedHtml = '';
      let parsedJs = '';
      let parsedCss = '';

      if (document.getElementById(`phishing-body-iframe`)) {
        let iframe: any = null;
        iframe = document.getElementById(`phishing-body-iframe`);
        parsedHtml = phishingInfo.phishingMailbody
          ? phishingInfo.phishingMailbody
              .replace(/\$\{SERVER_ROOT\}\$/g, window.location.origin)
              .replace(/\$\{DATE_YM\}\$/g, date.getFullYear() + fitTwoDigit(month))
          : '';

        const source = `<html>
            <head>
              <style>${parsedCss}</style>
            </head>
            <body>
              ${parsedHtml}
              <script src="${window.location.origin}/jquery-1.10.2.min.js"></script>
              <script>${parsedJs}</script>
            </body>
          </html>`;

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(source);
        iframe.contentWindow.document.close();
      }

      if (document.getElementById(`phishing-result-iframe`)) {
        let iframe: any = null;
        iframe = document.getElementById(`phishing-result-iframe`); // .contentWindow?.document;
        parsedHtml = phishingInfo.phishingHtml3
          ? phishingInfo.phishingHtml3
              .replace(/\$\{SERVER_ROOT\}\$/g, window.location.origin)
              .replace(/\$\{DATE_YM\}\$/g, date.getFullYear() + fitTwoDigit(month))
          : '';
        parsedCss = phishingInfo.phishingCss3
          ? phishingInfo.phishingCss3
              .replace(/\$\{SERVER_ROOT\}\$/g, window.location.origin)
              .replace(/\$\{DATE_YM\}\$/g, date.getFullYear() + fitTwoDigit(month))
          : '';
        parsedJs = phishingInfo.phishingJs3
          ? phishingInfo.phishingJs3
              .replace(/\$\{SERVER_ROOT\}\$/g, window.location.origin)
              .replace(/\$\{DATE_YM\}\$/g, date.getFullYear() + fitTwoDigit(month))
          : '';

        const source = `<html>
          <head>
            <style>${parsedCss}</style>
          </head>
          <body>
            ${parsedHtml}
            <script src="${window.location.origin}/jquery-3.4.1.min.js"></script>
            <script>${parsedJs}</script>
          </body>
        </html>`;

        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(source);
        iframe.contentWindow.document.close();
      }
    }
  };

  // 이미지 업로드 이벤트
  const onImageUploadEditor = async (fileList: any, insertImage: any) => {
    try {
      const file = fileList[0];
      const extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
      if (!extension.match(/bmp|jpg|jpeg|png|gif/)) return;

      const data = new FormData();
      if (!serviceTemplateNo) {
        data.append('examTemplateNo', curTemplateNo.toString());
      } else {
        data.append('serviceTemplateNo', serviceTemplateNo);
      }
      data.append('file', file);
      const response: any = await dispatch(uploadExamTemplateBodyImage(data));
      if (response?.data?.link) {
        insertImage(response.data.link);
      }
    } catch (error) {
      console.log('ExamTemplateAddEdit onImageUploadEditor', error);
    }
  };

  // 에디터 내용 변경 이벤트
  const onChangeEditor = (contents: any, target: any) => {
    const parsedHTML = contents
      ? contents.replace(/\$\{SERVER_ROOT\}\$/g, window.location.origin)
      : '';

    // 메일 본문
    if (target === 'convertHtml') {
      setConvertHtml(parsedHTML);
    }

    // 잠금 화면
    if (target === 'screenHtml') {
      setScreenHtml(parsedHTML);
    }
  };

  // 에디터 영역 클릭 시 에디터의 메뉴바 활성화
  const onFocusEditor = (target: any) => {
    setToolbarDisable({ ...toolbarDisable, [target]: false });
  };

  // 예약어 목록
  const reserveList = [
    {
      title: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
      reservation: '{TARGET_NAME}',
    },
    {
      title: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
      reservation: '{TARGET_EMAIL}',
    },
    {
      title: formatMessage({ id: 'Phishing_14', defaultMessage: '아이디' }),
      reservation: '{TARGET_ID}',
    },
    {
      title: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
      reservation: '{TARGET_PHONE}',
    },
    {
      title: formatMessage({ id: 'Division_1', defaultMessage: '소속' }),
      reservation: '{TARGET_DIVISION}',
    },
    {
      title: formatMessage({ id: 'Position_1', defaultMessage: '직급' }),
      reservation: '{TARGET_POSITION}',
    },
  ];

  const reserveComponent = reserveList.map((item) => {
    return (
      <>
        <div key={item.title} className="reserve-text">
          <span
            className={`reserve-title ${localStorage.getItem('language') === 'ja' ? 'ja' : ''}`}
          >
            {item.title}
          </span>
          <span className="reserve-content">{`$${item.reservation}$`}</span>
        </div>
      </>
    );
  });

  const linkStr = '{LINK_TEXT}';
  const targetStr = '{TARGET_NAME}';

  // 메일 제목 본문에 대상자 치환 정보
  const InfoComponent = (
    <div className="info-wrap">
      <div className="info-title">
        {formatMessage({
          id: 'Template_78',
          defaultMessage: '대상별 정보 입력',
        })}
      </div>
      <div className="info-content">
        <div>
          <div>
            {formatMessage({
              id: 'Template_79',
              defaultMessage:
                '제목과 본문에 개별 대상자의 정보를 입력해서 메일을 발송 할 수 있습니다.',
            })}
          </div>
          <div>
            {`ex > $${targetStr}$`}
            {formatMessage({
              id: 'Template_80',
              defaultMessage: '님 안녕하세요',
            })}
          </div>
          <div style={{ marginLeft: '28px' }}>
            {formatMessage({
              id: 'Template_81',
              defaultMessage: '-> 김성희님 안녕하세요.',
            })}
          </div>
        </div>
        <div className="reserve-column-list">{reserveComponent}</div>
      </div>
      <div className="info-title">
        {formatMessage({
          id: 'Template_82',
          defaultMessage: '링크 첨부 다운로드 버튼 위치',
        })}
      </div>
      <b>{`$${linkStr}$`}</b>
      <div className="info-content">
        {formatMessage({
          id: 'Template_83',
          defaultMessage:
            'link_text를 입력 하여 메일 본문에 원하는 위치에 "다운로드 버튼"을 등록 할 수 있습니다. (단 첨부 유형이 링크 첨부)',
        })}
      </div>
    </div>
  );

  return (
    <div className="examtemplate-add-edit">
      <ModalTemplate
        className="examtemplate-add-edit-modal modal-965"
        visible={visible}
        title={
          <div className="modal-template-header">
            <div className="title">
              {isAddMode
                ? formatMessage({ id: 'Template_4', defaultMessage: '훈련 양식(템플릿) 등록' })
                : formatMessage({ id: 'Template_5', defaultMessage: '훈련 양식(템플릿) 수정' })}
            </div>
            <div className="sub-title">{headerSubText}</div>
          </div>
        }
        onOk={handleSubmit(onSaveTemplate)}
        onCancel={closeModal}
        okText={formatMessage({ id: 'Button_21', defaultMessage: '저 장' })}
        cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
        loading={loading}
        disabled={!serviceTemplateNo && !isAdmin}
      >
        <Loading loading={loading} />

        <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
          {/* 템플릿 */}
          <div className="modal-item">
            <div className="title">
              {formatMessage({ id: 'Template_27', defaultMessage: '템플릿' })}
            </div>

            <div className="text-field-title">
              {formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' })}
            </div>
            <FormTextField
              name="templateName"
              error={errors.templateName}
              value={watchValues.templateName}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  templateName: (value: any) => valid.templateName(value),
                },
              }}
              disabled={!serviceTemplateNo && !isAdmin}
              onChange={(e: any) => changeFormValue('templateName', e.target.value)}
            />

            {!serviceTemplateNo && !!isAdmin && (
              <>
                <div className="text-field-title">
                  {formatMessage({ id: 'Template_35', defaultMessage: '공개 여부' })}
                </div>
                <FormButtonGroup
                  control={control}
                  name="templatePublic"
                  error={errors.templatePublic}
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value === 0 ? '0' : value),
                    },
                  }}
                  buttonList={[
                    {
                      visible: true,
                      text: formatMessage({ id: 'Template_26', defaultMessage: '공개' }),
                      value: 1,
                    },
                    {
                      visible: true,
                      text: formatMessage({ id: 'Template_39', defaultMessage: '비공개' }),
                      value: 0,
                    },
                  ]}
                  defaultValue={isAddMode ? null : watchValues.templatePublic}
                  handleOnChange={(value: any) => changeFormValue('templatePublic', value)}
                />
              </>
            )}
          </div>

          {/* 템플릿 유형 */}
          <div className="modal-item">
            <div className="title">
              {formatMessage({ id: 'Template_40', defaultMessage: '템플릿 유형' })}
            </div>
            <div className="text-field-title">
              {formatMessage({ id: 'Template_23', defaultMessage: '훈련 유형' })}
            </div>
            <FormButtonGroup
              control={control}
              name="examType"
              error={errors.examType}
              defaultValue={templateInfo.examType}
              key="key1"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
              customButton={
                <Popover
                  placement="topLeft"
                  content={formatMessage({
                    id: 'Template_49',
                    defaultMessage: "첨부파일 정보에서 가져온 파일의 '훈련 유형'이 선택됩니다.",
                  })}
                >
                  <Radio.Button value={1} className="examtype-button multiple">
                    <div
                      className={`examtype-item ${
                        watchValues.examType === 1 && selectedAttachInfo?.attachExamType === 1
                          ? 'active'
                          : ''
                      }`}
                    >
                      <i
                        className="examtype-icon"
                        style={{
                          background:
                            watchValues.examType === 1 && selectedAttachInfo?.attachExamType === 1
                              ? 'url(/img/exam/template/exam_warning_w.png) no-repeat center'
                              : 'url(/img/exam/template/exam_warning.png) no-repeat center',
                        }}
                      />
                      <div className="examtype-title">
                        {formatMessage({ id: 'Template_10', defaultMessage: '경고 안내' })}
                      </div>
                      <div className="examtype-sub-title">
                        {formatMessage({
                          id: 'Template_41',
                          defaultMessage: '훈련임을 안내',
                        })}
                      </div>
                      <div
                        className={`examtype-content ${
                          localStorage.getItem('language') === 'ja' ? 'ja' : ''
                        }`}
                      >
                        {formatMessage({
                          id: 'Template_42',
                          defaultMessage:
                            'PC 잠금 화면이 실행되어 대상자에게 경고 문구를 안내해주는 훈련입니다.',
                        })}
                      </div>
                    </div>
                    <div
                      className={`examtype-item ${
                        watchValues.examType === 1 && selectedAttachInfo?.attachExamType === 2
                          ? 'active'
                          : ''
                      }`}
                    >
                      <i
                        className="examtype-icon"
                        style={{
                          background:
                            watchValues.examType === 1 && selectedAttachInfo?.attachExamType === 2
                              ? 'url(/img/exam/template/exam_file_w.png) no-repeat center'
                              : 'url(/img/exam/template/exam_file.png) no-repeat center',
                        }}
                      />
                      <div className="examtype-title">
                        {formatMessage({
                          id: 'Template_11',
                          defaultMessage: '모의 악성파일',
                        })}
                      </div>
                      <div className="examtype-sub-title">
                        {formatMessage({ id: 'Template_43', defaultMessage: 'PC 잠금' })}
                      </div>
                      <div
                        className={`examtype-content ${
                          localStorage.getItem('language') === 'ja' ? 'ja' : ''
                        }`}
                      >
                        {formatMessage(
                          {
                            id: 'Template_44',
                            defaultMessage:
                              'PC 잠금 화면이 실행되어 대상자가 PC를 사용할 수 없게 되는 훈련입니다.',
                          },
                          { service: Option.serviceName },
                        )}
                      </div>
                    </div>
                    <div
                      className={`examtype-item ${
                        watchValues.examType === 1 && selectedAttachInfo?.attachExamType === 3
                          ? 'active'
                          : ''
                      }`}
                    >
                      <i
                        className="examtype-icon"
                        style={{
                          background:
                            watchValues.examType === 1 && selectedAttachInfo?.attachExamType === 3
                              ? 'url(/img/exam/template/exam_cheat_w.png) no-repeat center'
                              : 'url(/img/exam/template/exam_cheat.png) no-repeat center',
                        }}
                      />
                      <div className="examtype-title">
                        {formatMessage({ id: 'Template_12', defaultMessage: '실태 조사' })}
                      </div>
                      <div className="examtype-sub-title">
                        {formatMessage({
                          id: 'Template_45',
                          defaultMessage: '훈련임을 숨김',
                        })}
                      </div>
                      <div
                        className={`examtype-content ${
                          localStorage.getItem('language') === 'ja' ? 'ja' : ''
                        }`}
                      >
                        {formatMessage({
                          id: 'Template_46',
                          defaultMessage:
                            '첨부파일의 형태로 실행되어 대상자가 훈련임을 알 수 없는 훈련입니다.',
                        })}
                        <div>
                          {formatMessage({
                            id: 'Template_47',
                            defaultMessage: 'ex) excel이 실행됨',
                          })}
                        </div>
                      </div>
                    </div>
                  </Radio.Button>
                  <Radio.Button value={4} className="examtype-button">
                    <div className={`examtype-item ${watchValues.examType === 4 ? 'active' : ''}`}>
                      <i
                        className="examtype-icon"
                        style={{
                          background:
                            watchValues.examType === 4
                              ? 'url(/img/exam/template/exam_phishing_w.png) no-repeat center'
                              : 'url(/img/exam/template/exam_phishing.png) no-repeat center',
                        }}
                      />
                      <div className="examtype-title">
                        {formatMessage({ id: 'Template_13', defaultMessage: '피싱 유도' })}
                      </div>
                      <div className="examtype-sub-title">
                        {formatMessage({ id: 'Phishing_1', defaultMessage: '피싱 페이지' })}
                      </div>
                      <div
                        className={`examtype-content ${
                          localStorage.getItem('language') === 'ja' ? 'ja' : ''
                        }`}
                      >
                        {formatMessage({
                          id: 'Template_48',
                          defaultMessage:
                            '피싱 유도 링크가 메일에 삽입되어 대상자가 정보를 입력하는 형태의 훈련입니다.',
                        })}
                      </div>
                    </div>
                  </Radio.Button>
                </Popover>
              }
              disabled={!serviceTemplateNo && !isAdmin}
              // defaultValue={isAddMode ? null : templateInfo.examType}
              handleOnChange={(value: any) => changeFormValue('examType', value)}
            />
          </div>

          {/* 첨부파일 정보 */}
          {watchValues.examType === 1 && (
            <div className="modal-item">
              <div className="title">
                {formatMessage({ id: 'Attach_2', defaultMessage: '첨부파일 정보' })}
              </div>
              <div className="text-field-title">
                {formatMessage({ id: 'Template_50', defaultMessage: '첨부파일 가져오기' })}
              </div>
              <div>
                <div className="flex">
                  <Popover
                    overlayClassName="template-attach-popover"
                    placement="left"
                    content={
                      <>
                        <div>
                          <div className="attach-item">
                            <div className="attach-title">
                              {formatMessage({
                                id: 'Template_76',
                                defaultMessage: '부가 기능',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachFunction.indexOf('PG') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_16',
                                defaultMessage: 'PG(PC Gather) - PC 정보 수집 기능',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachFunction.indexOf('LG') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_17',
                                defaultMessage: 'LG(LeakFile Gather) - PC의 파일 정보 수집 기능',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachFunction.indexOf('PL') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_18',
                                defaultMessage: 'PL(PC Lock) - PC 잠금(모의악성) 기능',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachFunction.indexOf('VS') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_19',
                                defaultMessage: 'VS(Vaccine Step) - 백신 단계 기능',
                              })}
                            </div>
                          </div>
                          <div className="attach-item">
                            <div className="attach-title">
                              {formatMessage({ id: 'Template_77', defaultMessage: '제약 사항' })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachRestrict.indexOf('UAC') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_20',
                                defaultMessage:
                                  'UAC(User Account Control) - Windows 사용자 계정 컨트롤 탐지 가능',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachRestrict.indexOf('SS') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_21',
                                defaultMessage:
                                  'SS(Smart Screen) - Windows 스마트 스크린에 탐지 가능',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachRestrict.indexOf('SF') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_22',
                                defaultMessage: 'SF(Spam Filter) - 메일 스팸 필터에 탐지 가능',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachRestrict.indexOf('VD') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_23',
                                defaultMessage:
                                  'VD(Vaccine Detect) - 백신 탐지 가능함(V3, 알약···)',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachRestrict.indexOf('LC') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_24',
                                defaultMessage:
                                  'LC(Link Click) - 문서 안에 링크를 클릭해야 감염 처리',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachRestrict.indexOf('UC') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_25',
                                defaultMessage: 'UC(User Control) - 사용자의 동의가 필요함',
                              })}
                            </div>
                            <div
                              className={`${
                                selectedAttachInfo?.attachRestrict.indexOf('DW') > -1
                                  ? 'brand-color'
                                  : ''
                              }`}
                            >
                              {formatMessage({
                                id: 'Attach_26',
                                defaultMessage:
                                  'DW(Download Warning) - 메일에서 파일 다운로드시 경고 발생',
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    }
                  >
                    <div style={{ width: '100%' }}>
                      <FormSelectField
                        className="attach-select-menu"
                        name="attachInfoNo"
                        control={control}
                        error={errors.attachInfoNo}
                        value={watchValues.attachInfoNo}
                        option={attachInfoOption}
                        disabled={!serviceTemplateNo && !isAdmin}
                        handleOnChange={(name: any, data: any) =>
                          changeFormValue('attachInfoNo', data)
                        }
                        validation={{
                          validate: {
                            required: (value: any) =>
                              watchValues.examType === 1 ? valid.required(value) : undefined,
                          },
                        }}
                        listHeight={500}
                      />
                    </div>
                  </Popover>

                  {!!selectedAttachInfo && (
                    <div className="attach-result">
                      <Popover
                        overlayClassName="result-image"
                        placement="topLeft"
                        content={
                          <img
                            src={`/fdata/attachFile/${selectedAttachInfo?.attachInfoNo}/${selectedAttachInfo?.actionImage}`}
                            alt="icon"
                          />
                        }
                      >
                        <div className="attach-result-button">
                          {formatMessage({
                            id: 'Template_51',
                            defaultMessage: '실행 결과 이미지',
                          })}
                        </div>
                      </Popover>
                    </div>
                  )}
                </div>

                {!!selectedAttachInfo && (
                  <>
                    <div className="text-field-title">
                      {formatMessage({ id: 'Download_3', defaultMessage: '다운로드 유형' })}
                    </div>
                    <FormButtonGroup
                      control={control}
                      name="linkAttach"
                      error={errors.linkAttach}
                      validation={{
                        validate: {
                          required: (value: any) => valid.required(value),
                        },
                      }}
                      buttonList={[
                        {
                          visible: true,
                          text: formatMessage({
                            id: 'Template_52',
                            defaultMessage: '링크 첨부 (메일 본문에 다운로드 버튼)',
                          }),
                          value: 2,
                        },
                        {
                          visible: true,
                          text: formatMessage({
                            id: 'Template_53',
                            defaultMessage: '첨부 파일로 다운로드',
                          }),
                          value: 1,
                        },
                      ]}
                      disabled={!serviceTemplateNo && !isAdmin}
                      defaultValue={isAddMode ? null : watchValues.linkAttach}
                      handleOnChange={(value: any) => changeFormValue('linkAttach', value)}
                    />
                  </>
                )}

                {!!selectedAttachInfo && (
                  <>
                    <div className="text-field-title">
                      {formatMessage({ id: 'Attach_3', defaultMessage: '첨부 유형' })}
                    </div>
                    <FormButtonGroup
                      control={control}
                      name="attachType"
                      error={errors.attachType}
                      validation={{
                        validate: {
                          required: (value: any) => valid.required(value),
                        },
                      }}
                      buttonList={[
                        {
                          visible: selectedAttachInfo.fileExt !== 'lnk',
                          text: formatMessage({
                            id: 'Attach_4',
                            defaultMessage: '원본',
                          }),
                          value: 1,
                        },
                        {
                          visible: true,
                          text: formatMessage({
                            id: 'Attach_30',
                            defaultMessage: '압축 파일',
                          }),
                          value: 2,
                        },
                        {
                          visible: true,
                          text: formatMessage({
                            id: 'Attach_31',
                            defaultMessage: '비밀번호 설정된 압축 파일',
                          }),
                          value: 3,
                        },
                      ]}
                      disabled={!serviceTemplateNo && !isAdmin}
                      defaultValue={isAddMode ? null : watchValues.attachType}
                      handleOnChange={(value: any) => changeFormValue('attachType', value)}
                    />
                  </>
                )}

                {selectedAttachInfo && watchValues.attachInfoNo && watchValues.attachType > 1 && (
                  <div className="input-row">
                    <div className="input-block flex-large">
                      {/* 압축 파일명 */}
                      <div className="text-field-title">
                        {formatMessage({
                          id: 'Attach_32',
                          defaultMessage:
                            '압축 파일명(메일에 첨부되는 압축 파일의 파일 이름을 지정할 수 있습니다.)',
                        })}
                      </div>
                      <FormTextField
                        name="attachCompressName"
                        error={errors.attachCompressName}
                        value={watchValues.attachCompressName}
                        register={register}
                        arrowPosition="top"
                        validation={{
                          validate: {
                            required: (value: any) => valid.required(value),
                            windowsFileName: (value: any) => valid.windowsFileName(value),
                          },
                        }}
                        disabled={!serviceTemplateNo && !isAdmin}
                        onChange={(e: any) => changeFormValue('attachCompressName', e.target.value)}
                      />
                    </div>

                    {/* 압축 비밀번호 */}
                    {watchValues.attachType === 3 && (
                      <div className="input-block">
                        <div className="text-field-title">
                          {formatMessage({ id: 'Attach_33', defaultMessage: '압축 비밀번호' })}
                        </div>
                        <FormTextField
                          name="attachCompressPasswd"
                          error={errors.attachCompressPasswd}
                          value={watchValues.attachCompressPasswd}
                          register={register}
                          arrowPosition="top"
                          validation={{
                            validate: {
                              required: (value: any) => valid.required(value),
                              range: (value: any) => valid.range(value, 0, 50),
                              compressPW: (value: any) => valid.compressPW(value),
                            },
                          }}
                          disabled={!serviceTemplateNo && !isAdmin}
                          onChange={(e: any) =>
                            changeFormValue('attachCompressPasswd', e.target.value)
                          }
                        />
                      </div>
                    )}

                    <div className="input-block">
                      {/* 압축 파일 종류 */}
                      <div className="text-field-title">
                        {formatMessage({ id: 'Attach_34', defaultMessage: '압축 파일 종류' })}
                      </div>
                      <FormSelectField
                        className="attach-select-menu"
                        name="attachCompress"
                        control={control}
                        error={errors.attachCompress}
                        defaultValue={watchValues.attachCompress}
                        menuList={[
                          {
                            key: 1,
                            value: 1,
                            text: 'Zip',
                          },
                          {
                            key: 2,
                            value: 2,
                            text: formatMessage({
                              id: 'Attach_36',
                              defaultMessage: '7z (원본 파일 숨김)',
                            }),
                          },
                        ]}
                        disabled={!serviceTemplateNo && !isAdmin}
                        handleOnChange={(name: any, data: any) =>
                          changeFormValue('attachCompress', data)
                        }
                        validation={{
                          validate: {
                            required: (value: any) =>
                              watchValues.attachInfoNo && watchValues.attachType > 1
                                ? valid.required(value)
                                : undefined,
                          },
                        }}
                      />
                    </div>
                  </div>
                )}

                {selectedAttachInfo && watchValues.attachType > 0 && (
                  <div className="file-name">
                    {/* 원본 파일명 */}
                    <div className="text-field-title">
                      {watchValues.attachType > 1
                        ? formatMessage({
                            id: 'Attach_37',
                            defaultMessage:
                              '원본 파일명(메일에 첨부되는 압축 파일 안의 파일 이름을 지정할 수 있습니다.)',
                          })
                        : formatMessage({
                            id: 'Attach_35',
                            defaultMessage:
                              '원본 파일명(메일에 첨부되는 파일 이름을 지정할 수 있습니다.)',
                          })}
                    </div>
                    <FormTextField
                      name="attachName"
                      error={errors.attachName}
                      value={watchValues.attachName}
                      register={register}
                      arrowPosition="top"
                      validation={{
                        validate: {
                          required: (value: any) => valid.required(value),
                          windowsFileName: (value: any) => valid.windowsFileName(value),
                          attachName: (value: any) => valid.attachName(value),
                        },
                      }}
                      disabled={!serviceTemplateNo && !isAdmin}
                      onChange={(e: any) => changeFormValue('attachName', e.target.value)}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* 피싱 유도 */}
          {watchValues.examType === 4 && (
            <div className="modal-item">
              <div className="title">
                {formatMessage({ id: 'Template_58', defaultMessage: '피싱 유도 설정' })}
              </div>
              <FormSelectField
                className="attach-select-menu phishing"
                name="phishingNo"
                control={control}
                error={errors.phishingNo}
                defaultValue={watchValues.phishingNo}
                menuList={phishingMenu}
                disabled={!serviceTemplateNo && !isAdmin}
                handleOnChange={(name: any, data: any) => changeFormValue('phishingNo', data)}
                validation={{
                  validate: {
                    required: (value: any) =>
                      watchValues.examType === 4 ? valid.required(value) : undefined,
                  },
                }}
                listHeight={500}
              />
            </div>
          )}

          {/* 발송 정보 */}
          <div className="modal-item">
            <div className="title">
              {formatMessage({ id: 'Send_12', defaultMessage: '발송 정보' })}
            </div>

            <div className="input-row">
              <div className="input-block">
                <div className="text-field-title">
                  {formatMessage({ id: 'Name_9', defaultMessage: '발송자' })}
                </div>
                <FormTextField
                  name="senderName"
                  error={errors.senderName}
                  value={watchValues.senderName}
                  register={register}
                  arrowPosition="top"
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value),
                      name: (value: any) => valid.name(value),
                    },
                  }}
                  disabled={!serviceTemplateNo && !isAdmin}
                  onChange={(e: any) => changeFormValue('senderName', e.target.value)}
                />
              </div>

              <div className="input-block flex-large">
                <div className="text-field-title">
                  {formatMessage({ id: 'Email_14', defaultMessage: '발송 이메일' })}
                </div>
                <FormTextField
                  name="senderEmail"
                  error={errors.senderEmail}
                  value={watchValues.senderEmail}
                  register={register}
                  arrowPosition="top"
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value),
                      email: (value: any) => valid.email(value),
                    },
                  }}
                  disabled={!serviceTemplateNo && !isAdmin}
                  onChange={(e: any) => changeFormValue('senderEmail', e.target.value)}
                />
              </div>

              <div className="input-block flex-large">
                <div className="text-field-title">
                  {formatMessage({ id: 'Email_15', defaultMessage: '봉투 발송 이메일' })}
                </div>
                <FormTextField
                  name="envelopSenderEmail"
                  error={errors.envelopSenderEmail}
                  value={watchValues.envelopSenderEmail}
                  register={register}
                  arrowPosition="top"
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value),
                      email: (value: any) => valid.email(value),
                    },
                  }}
                  disabled={!serviceTemplateNo && !isAdmin}
                  onChange={(e: any) => changeFormValue('envelopSenderEmail', e.target.value)}
                />
                <div className="send-msg">
                  *
                  {formatMessage({
                    id: 'Send_16',
                    defaultMessage: '도메인이 존재하지 않으면 메일 발송이 실패 할 수 있습니다.',
                  })}
                </div>
              </div>
            </div>
          </div>

          {/* 메일 내용 */}
          <div className="modal-item">
            <Popover
              overlayClassName="template-body-popover"
              placement="left"
              content={InfoComponent}
            >
              <div>
                <div className="title">
                  {formatMessage({ id: 'Template_54', defaultMessage: '메일 내용' })}
                </div>

                <div className="text-field-title mail-title">
                  {formatMessage({ id: 'Email_20', defaultMessage: '메일 제목' })}

                  {/* outlook 미리보기에 제목 설정(일본 제외) */}
                  {Option.isJapan !== 1 && (
                    <Controller
                      control={control}
                      name="outlookPreview"
                      render={({ onChange, value }) => (
                        <Checkbox
                          className="modal-checkbox"
                          onChange={(e) => {
                            onChange(e.target.checked);
                            setValue('outlookPreview', e.target.checked === true ? 1 : 2);
                          }}
                          checked={value === 2 ? false : true}
                          disabled={!serviceTemplateNo && !isAdmin}
                        >
                          {formatMessage({
                            id: 'Template_89',
                            defaultMessage: '메일 본문 내 제목 포함',
                          })}
                        </Checkbox>
                      )}
                    />
                  )}
                </div>
                <FormTextField
                  name="subject"
                  error={errors.subject}
                  value={watchValues.subject}
                  register={register}
                  arrowPosition="top"
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value),
                      templateName: (value: any) => valid.templateName(value),
                    },
                  }}
                  disabled={!serviceTemplateNo && !isAdmin}
                  onChange={(e: any) => changeFormValue('subject', e.target.value)}
                />

                <div className="text-field-title">
                  {formatMessage({ id: 'Template_55', defaultMessage: '메일 본문' })}
                </div>
                {!!curTemplateNo && watchValues.examType !== 4 && (
                  <div>
                    <CustomEditor
                      onImageUploadEvt={onImageUploadEditor}
                      onFocusEvt={() => onFocusEditor('convertHtml')}
                      onChangeEvt={(contents: any) => onChangeEditor(contents, 'convertHtml')}
                      onChangeCodeviewEvt={(contents: any) =>
                        onChangeEditor(contents, 'convertHtml')
                      }
                      className={toolbarDisable.convertHtml ? 'disable-toolbar' : ''}
                      disabled={!serviceTemplateNo && !isAdmin}
                      value={convertHtml}
                    />
                  </div>
                )}

                {/* 피싱 유도 유형 메일 본문 */}
                <iframe
                  id="phishing-body-iframe"
                  className={`phishing-body-iframe ${watchValues.examType === 4 ? 'on' : ''}`}
                  title="iframe"
                  frameBorder="0"
                />
              </div>
            </Popover>
          </div>

          {/* PC 잠금 화면 설정 */}
          {!!curTemplateNo &&
            watchValues.examType !== 4 &&
            (selectedAttachInfo?.attachExamType === 1 ||
              selectedAttachInfo?.attachExamType === 2) && (
              <div className="modal-item">
                <div className="title">
                  {formatMessage({ id: 'Template_59', defaultMessage: 'PC 잠금 화면 설정' })}
                </div>

                {selectedAttachInfo?.attachExamType === 2 && (
                  <>
                    <div className="text-field-title">
                      {formatMessage({ id: 'Template_61', defaultMessage: '잠금 화면 타입' })}
                    </div>
                    <FormButtonGroup
                      control={control}
                      name="attachScreenType"
                      error={errors.attachScreenType}
                      validation={{
                        validate: {
                          required: (value: any) => valid.required(value),
                        },
                      }}
                      buttonList={[
                        {
                          visible: true,
                          text: formatMessage({
                            id: 'Template_63',
                            defaultMessage: '닫기 버튼 클릭',
                          }),
                          value: 1,
                        },
                        {
                          visible: true,
                          text: formatMessage({
                            id: 'Template_64',
                            defaultMessage: '비밀번호 입력',
                          }),
                          value: 2,
                        },
                      ]}
                      defaultValue={
                        isAddMode
                          ? null
                          : watchValues.attachScreenType || templateInfo.attachScreenType
                      }
                      handleOnChange={(value: any) => changeFormValue('attachScreenType', value)}
                    />

                    {(selectedAttachInfo?.attachCategory === 1 ||
                      selectedAttachInfo?.attachCategory === 2) &&
                      watchValues.attachScreenType === 2 && (
                        <>
                          <div className="text-field-title">
                            {formatMessage({
                              id: 'Template_62',
                              defaultMessage: 'PC 잠금 해제 비밀번호',
                            })}
                          </div>
                          <FormTextField
                            name="attachScreenPassword"
                            error={errors.attachScreenPassword}
                            value={watchValues.attachScreenPassword}
                            register={register}
                            arrowPosition="top"
                            validation={{
                              validate: {
                                required: (value: any) => valid.required(value),
                              },
                            }}
                            disabled={!serviceTemplateNo && !isAdmin}
                            onChange={(e: any) =>
                              changeFormValue('attachScreenPassword', e.target.value)
                            }
                          />
                        </>
                      )}
                  </>
                )}

                <div className="text-field-title">
                  {formatMessage({ id: 'Template_60', defaultMessage: 'PC 잠금 화면 내용' })}
                </div>
                <CustomEditor
                  onImageUploadEvt={onImageUploadEditor}
                  onFocusEvt={() => onFocusEditor('screenHtml')}
                  onChangeEvt={(contents: any) => onChangeEditor(contents, 'screenHtml')}
                  onChangeCodeviewEvt={(contents: any) => onChangeEditor(contents, 'screenHtml')}
                  className={toolbarDisable.screenHtml ? 'disable-toolbar' : ''}
                  // className={`summernote-editor'${bodyDisable ? 'disable-toolbar' : ''}`}
                  disabled={!serviceTemplateNo && !isAdmin}
                  value={screenHtml}
                />
              </div>
            )}

          {/*  피싱 결과 화면 내용 */}
          {watchValues.examType === 4 &&
          phishingData?.filter((data: any) => data.phishingNo === watchValues?.phishingNo)[0]
            ?.phishingScreenEnable ? (
            <div className="modal-item">
              <div className="title">
                {formatMessage({ id: 'Template_56', defaultMessage: '피싱 결과 화면 내용' })}
              </div>

              <iframe
                id="phishing-result-iframe"
                className={`phishing-result-iframe ${watchValues.examType === 4 ? 'on' : ''}`}
                title="iframe"
                frameBorder="0"
              />
            </div>
          ) : (
            <></>
          )}
        </form>
      </ModalTemplate>
    </div>
  );
}

export default ExamTemplateAddEdit;
