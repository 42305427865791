import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Tooltip } from 'antd';
import {
  changeListState,
  getExamList,
  deleteExamSummary,
  getExamChart,
  addTag,
  getExamRandomList,
  changeRandomListState,
} from 'store/exam';
import ContentList from 'components/common/ContentList';
import ExamItemAll from 'components/branch/exam/ExamItemAll';
import SearchFilter from 'components/common/SearchFilter';
import ModalTemplate from 'components/common/ModalTemplate';
import ExamItemEach from 'components/branch/exam/ExamItemEach';
import ExamChart from 'components/branch/exam/ExamChart';
import FormTextField from 'components/common/FormTextField';
import ExamRandomChart from 'components/branch/exam/ExamRandomChart';
import * as valid from 'utils/validation';
import { openNotification, getInitialTag } from 'utils/commonFunctions';
import { getTag } from 'store/tag';

function Pi() {
  const [loading, setLoading] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { errors, register, handleSubmit, setError } = useForm();
  const examSummary = useSelector((state: any) => {
    return {
      data: state.exam.examSummary.data,
      dataByExamNo: state.exam.examSummary.dataByExamNo,
      param: state.exam.examSummary.param,
      selectedExamNo: state.exam.examSummary.selectedExamNo,
      needReload: state.exam.examSummary.needReload,
      selectedRandomGroupNo: state.exam.examSummary.selectedRandomGroupNo,
    };
  }, shallowEqual);
  const examList = useSelector((state: any) => {
    return {
      data: state.exam.examList.data,
      dataByExamNo: state.exam.examList.dataByExamNo,
      param: state.exam.examList.param,
      needReload: state.exam.examList.needReload,
      page: state.exam.examList.page,
      totalPage: state.exam.examList.totalPage,
      selectedList: state.exam.examList.selectedList,
      selectType: state.exam.examList.selectType,
    };
  }, shallowEqual);
  const randomList = useSelector((state: any) => {
    return {
      data: state.exam.randomList.data,
      dataByExamNo: state.exam.randomList.dataByExamNo,
      param: state.exam.randomList.param,
      needReload: state.exam.randomList.needReload,
      page: state.exam.randomList.page,
      totalPage: state.exam.randomList.totalPage,
      selectedList: state.exam.randomList.selectedList,
    };
  }, shallowEqual);
  const [deleteModal, setDeleteModal] = useState(false);
  const [tagModal, setTagModal] = useState(false);

  useEffect(() => {
    if (examSummary.selectedRandomGroupNo) {
      // 랜덤발송 그룹 선택 시
      onGetExamRandomList(true);
    } else {
      // 개별 훈련 선택 시
      onGetExamList(true);
      if (examSummary.selectedExamNo !== 'all') {
        onGetExamChart();
      }
    }

    // unmount 시 선택한 리스트 초기화
    return () => {
      dispatch(changeListState({ key: 'selectedList', value: [] }));
    };
  }, [
    examSummary.param,
    examSummary.selectedExamNo,
    examSummary.needReload,
    examList.param,
    randomList.param,
    examSummary.selectedRandomGroupNo,
  ]);

  // 훈련 리스트 조회
  const onGetExamList = async (refresh = false) => {
    if (loading) return;
    setLoading(true);

    try {
      const params: any = {
        examNo: examSummary.selectedExamNo,
        subTab: 'pi',
        filter: JSON.stringify(examList.param.filter),
        sort: JSON.stringify(examList.param.sort),
        offset: 0,
        limit: examList.param.limit,
      };

      if (Object.keys(examSummary?.param?.filter).length > 0) {
        params.examFilter = JSON.stringify(examSummary.param.filter);
      }

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = examList.param.limit * examList.page;
      }

      await dispatch(getExamList(params));
      setLoading(false);
    } catch (error) {
      console.log('Pi onGetExamList', error);
    }
  };

  // 랜덤발송 훈련 리스트 조회
  const onGetExamRandomList = async (refresh = false) => {
    if (loading) return;
    setLoading(true);

    try {
      const params: any = {
        parentsExamNo: examSummary.selectedRandomGroupNo,
        filter: JSON.stringify(randomList.param.filter),
        sort: JSON.stringify(randomList.param.sort),
        offset: 0,
        limit: randomList.param.limit,
      };

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = randomList.param.limit * randomList.page;
      }

      await dispatch(getExamRandomList(params));
      setLoading(false);
    } catch (error) {
      console.log('Pi onGetExamRandomList', error);
    }
  };
  // 차트 데이터 조회
  const onGetExamChart = async () => {
    try {
      setLoading(true);
      const params = {
        examNo: examSummary.selectedExamNo,
        subTab: 'pi',
      };

      await dispatch(getExamChart(params));
      setLoading(false);
    } catch (error) {
      console.log('Pi onExamChart', error);
    }
  };

  // 선택한 훈련 데이터
  const examData = examSummary.dataByExamNo[examSummary.selectedExamNo];

  // 훈련 리스트 데이터 키 목록
  const dataKeyList: number[] = [];
  Object.keys(examList.dataByExamNo).forEach((key: any) => {
    dataKeyList.push(parseInt(key, 10));
  });

  // 훈련 체크박스 선택
  const onSelectCheck = useCallback(
    (type: string, examNo: number) => {
      let newSelectList = [...examList.selectedList];
      // 타입 변경 시 기존 값 초기화
      if (type !== examList.selectType) {
        newSelectList = [];
      }

      if (newSelectList.includes(examNo)) {
        newSelectList.splice(newSelectList.indexOf(examNo), 1);
      } else {
        newSelectList.push(examNo);
      }
      dispatch(changeListState({ key: 'selectType', value: 'check' }));
      dispatch(changeListState({ key: 'selectedList', value: newSelectList }));
    },
    [examList.selectType, examList.selectedList],
  );

  // 훈련 선택
  const onSelectItem = useCallback(
    (type: string, no: number) => {
      // 랜덤발송
      if (examSummary.selectedRandomGroupNo) {
        let select = [...randomList.selectedList];
        select = randomList.selectedList.includes(no) ? [] : [no];
        // 랜덤발송 훈련에 속한 대상자 선택
        dispatch(changeRandomListState({ key: 'selectedList', value: select }));
      } else {
        // 일반 훈련
        let select = [...examList.selectedList];
        if (type !== examList.selectType) {
          // 타입 변경 시 현재 선택한 값 저장
          select = [no];
        } else {
          select = examList.selectedList.includes(no) ? [] : [no];
        }

        // 일반 훈련 대상자 선택
        dispatch(changeListState({ key: 'selectType', value: 'select' }));
        dispatch(changeListState({ key: 'selectedList', value: select }));
      }
    },
    [
      examList.selectType,
      examList.selectedList,
      examSummary.selectedRandomGroupNo,
      randomList.selectedList,
    ],
  );

  //  훈련 삭제
  const onDeleteExam = async () => {
    setLoading(true);
    try {
      await dispatch(deleteExamSummary({ examNoArray: JSON.stringify(examList.selectedList) }));

      dispatch(changeListState({ key: 'selectedList', value: [] }));
      setDeleteModal(false);
      setLoading(false);
    } catch (error) {
      console.log('Pi onDeleteExam', error);
    }
  };

  // 태그 만들기
  const onAddTag = async (formData: any) => {
    try {
      // 태그명 중복체크
      const res: any = await dispatch(getTag({ filter: { tagNameExact: formData.tagName } }));
      if (res?.data?.list?.length > 0) {
        setError('tagName', {
          type: 'duplicate',
          message: formatMessage({ id: 'Tag_56', defaultMessage: '이미 등록된 태그명입니다.' }),
        });
      } else {
        setLoading(true);

        const params = {
          examNo: examSummary.selectedExamNo,
          tagName: formData.tagName,
          tagInitial: getInitialTag(formData.tagName),
          filter: examList.param.filter,
          subTab: 'pi',
        };

        const response: any = await dispatch(addTag(params));
        if (response?.data?.list) {
          setLoading(false);
          setTagModal(false);
          openNotification(
            `'${response.data.list[0].tagName}' ${formatMessage({
              id: 'Tag_40',
              defaultMessage: '태그 생성',
            })}`,
          );
        }
      }
    } catch (error) {
      console.log('Pi onAddTag', error);
    }
  };

  // 훈련 삭제 버튼
  const deleteExamBtn = (
    <Tooltip
      overlayClassName="black-tooltip full"
      placement="bottom"
      title={
        examList.selectedList.length > 0
          ? formatMessage({ id: 'Exam_38', defaultMessage: '선택한 훈련 삭제하기' })
          : formatMessage({ id: 'Exam_39', defaultMessage: '훈련을 선택하세요.' })
      }
    >
      <div
        className={`round-grey-button ${examList.selectedList.length < 1 ? 'disabled' : ''}`}
        onClick={() => setDeleteModal(true)}
        aria-hidden="true"
      >
        {formatMessage({ id: 'Exam_20', defaultMessage: '훈련 삭제' })}
      </div>
    </Tooltip>
  );

  // 태그 추가
  const addTagBtn = (
    <Tooltip
      overlayClassName="black-tooltip full"
      placement="topLeft"
      title={formatMessage({
        id: 'Tag_38',
        defaultMessage: '현재 훈련의 필터링된 대상자를 태그로 등록합니다.',
      })}
    >
      <div className="round-grey-button tag" onClick={() => setTagModal(true)} aria-hidden="true">
        {/* <PlusSquareFilled /> */}
        <div className="plus-icon" />
        {formatMessage({ id: 'Tag_4', defaultMessage: '태그 만들기' })}
      </div>
    </Tooltip>
  );

  // 훈련 요약에 있는 데이터와 비교하여 같은 것만 리스트에 추가
  const examDataList = useMemo(() => {
    const data = examSummary.selectedRandomGroupNo ? randomList.data : examList.data;
    return data.filter((item: any) =>
      Object.keys(examSummary.dataByExamNo).includes(item.examNo.toString()),
    );
  }, [examList.data, examList.param, examSummary.dataByExamNo, randomList.data, examSummary.selectedRandomGroupNo]);

  // 메시지 데이터
  const messageData: any = {
    infection: formatMessage({ id: 'Target_4', defaultMessage: '위험 대상자' }),
    send: formatMessage({ id: 'Send_1', defaultMessage: '발송' }),
    sendReserve: formatMessage({ id: 'Send_4', defaultMessage: '발송 예약' }),
    sendError: formatMessage({ id: 'Send_5', defaultMessage: '발송 실패' }),
    read: formatMessage({ id: 'Read_1', defaultMessage: '열람' }),
    connect: formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' }),
    phishing: formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' }),
    fileDown: formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' }),
    fileInfection: formatMessage({ id: 'Infection_1', defaultMessage: '감염' }),
    infectionPc: formatMessage({ id: 'Infection_3', defaultMessage: '감염 PC' }),
    cure: formatMessage({ id: 'Cure_1', defaultMessage: '치료' }),
    targetEmail: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
    targetName: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
    leakFileCount: formatMessage({ id: 'Infection_30', defaultMessage: 'PC 파일' }),
    leakCertCount: formatMessage({ id: 'Infection_31', defaultMessage: '인증서' }),
    leakEmailCount: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
  };

  const filterNames: string[] = [];
  if (examList.param.filter) {
    const filters = Object.keys(examList.param.filter);
    filters.forEach((item: any) => {
      if (item !== 'filterType' && item !== 'searchAll') {
        filterNames.push(messageData[item]);
      }
    });
  }

  // 전체 훈련 선택 - 훈련 탭 정렬
  const allExamSort = {
    infection: messageData.infection,
    send: messageData.send,
    sendReserve: messageData.sendReserve,
    read: messageData.read,
    connect: messageData.connect,
    phishing: messageData.phishing,
    fileDown: messageData.fileDown,
    fileInfection: messageData.fileInfection,
    infectionPc: messageData.infectionPc,
    cure: messageData.cure,
  };

  // 전체 훈련 선택 - 훈련 탭 필터
  const allExamFilter = {
    examResultArray: {
      name: formatMessage({ id: 'Exam_37', defaultMessage: '훈련 결과' }),
      child: [
        { label: messageData.infection, value: 'infection' },
        { label: messageData.send, value: 'send' },
        { label: messageData.sendReserve, value: 'sendReserve' },
        { label: messageData.sendError, value: 'sendError' },
        { label: messageData.read, value: 'read' },
        { label: messageData.connect, value: 'connect' },
        { label: messageData.phishing, value: 'phishing' },
        { label: messageData.fileDown, value: 'fileDown' },
        { label: messageData.fileInfection, value: 'fileInfection' },
        { label: messageData.cure, value: 'cure' },
      ],
    },
    infectionArray: {
      name: formatMessage({ id: 'Infection_12', defaultMessage: '감염 정보' }),
      child: [
        { label: messageData.infectionPc, value: 'infectionPc' },
        { label: messageData.leakFileCount, value: 'leakFileCount' },
        { label: messageData.leakCertCount, value: 'leakCertCount' },
        { label: messageData.leakEmailCount, value: 'leakEmailCount' },
      ],
    },
  };

  // 개별 훈련 선택 - 대상 탭 정렬
  let targetSort = {};
  // 개별 훈련 선택 - 대상 탭 필터
  let targetFilter = {};
  if (examSummary.selectedRandomGroupNo) {
    // 랜덤발송일 때
    targetSort = {
      targetName: messageData.targetName,
      targetEmail: messageData.targetEmail,
      send: messageData.send,
      sendReserve: messageData.sendReserve,
      read: messageData.read,
      fileDown: messageData.fileDown,
      fileInfection: messageData.fileInfection,
      cure: messageData.cure,
      connect: messageData.connect,
      phishing: messageData.phishing,
    };

    targetFilter = {
      targetExamArray: {
        name: formatMessage({ id: 'Exam_37', defaultMessage: '훈련 결과' }),
        child: [
          { label: messageData.send, value: 'send' },
          { label: messageData.sendReserve, value: 'sendReserve' },
          { label: messageData.sendError, value: 'sendError' },
          { label: messageData.read, value: 'read' },
          { label: messageData.connect, value: 'connect' },
          { label: messageData.phishing, value: 'phishing' },
          { label: messageData.fileDown, value: 'download' },
          { label: messageData.fileInfection, value: 'infection' },
          { label: messageData.cure, value: 'cure' },
        ],
      },
    };
  } else {
    targetSort =
      examData?.examType === 4
        ? {
            send: messageData.send,
            sendReserve: messageData.sendReserve,
            read: messageData.read,
            connect: messageData.connect,
            phishing: messageData.phishing,
            targetEmail: messageData.targetEmail,
            targetName: messageData.targetName,
          }
        : {
            send: messageData.send,
            sendReserve: messageData.sendReserve,
            read: messageData.read,
            fileDown: messageData.fileDown,
            fileInfection: messageData.fileInfection,
            infectionPc: messageData.infectionPc,
            cure: messageData.cure,
            targetEmail: messageData.targetEmail,
            targetName: messageData.targetName,
          };

    targetFilter =
      examData?.examType === 4
        ? {
            targetExamArray: {
              name: formatMessage({ id: 'Exam_37', defaultMessage: '훈련 결과' }),
              child: [
                { label: messageData.send, value: 'send' },
                { label: messageData.sendReserve, value: 'sendReserve' },
                { label: messageData.sendError, value: 'sendError' },
                { label: messageData.read, value: 'read' },
                { label: messageData.connect, value: 'connect' },
                { label: messageData.phishing, value: 'phishing' },
              ],
            },
          }
        : {
            targetExamArray: {
              name: formatMessage({ id: 'Exam_37', defaultMessage: '훈련 결과' }),
              child: [
                { label: messageData.send, value: 'send' },
                { label: messageData.sendReserve, value: 'sendReserve' },
                { label: messageData.sendError, value: 'sendError' },
                { label: messageData.read, value: 'read' },
                { label: messageData.fileDown, value: 'fileDown' },
                { label: messageData.fileInfection, value: 'fileInfection' },
                { label: messageData.cure, value: 'cure' },
              ],
            },
            infectionArray: {
              name: formatMessage({ id: 'Infection_12', defaultMessage: '감염 정보' }),
              child: [
                { label: messageData.infectionPc, value: 'infectionPc' },
                { label: messageData.leakFileCount, value: 'leakFileCount' },
                { label: messageData.leakCertCount, value: 'leakCertCount' },
                { label: messageData.leakEmailCount, value: 'leakEmailCount' },
              ],
            },
          };
  }

  // 선택한 대상자 회색 처리
  const listActive = (no: number) => {
    if (examSummary.selectedRandomGroupNo) {
      return randomList.selectedList.includes(no) ? 'selected' : '';
    }
    return examList.selectType === 'select' && examList.selectedList.includes(no) ? 'selected' : '';
  };

  return (
    <div className="exam-content-wrap pi">
      {/* 차트 영역 */}
      {examSummary.selectedExamNo !== 'all' &&
        (examSummary.selectedRandomGroupNo ? <ExamRandomChart /> : <ExamChart chartType="pi" />)}

      {/* 검색 영역 */}
      <SearchFilter
        sortMenu={examSummary.selectedExamNo === 'all' ? allExamSort : targetSort}
        filterMenu={examSummary.selectedExamNo === 'all' ? allExamFilter : targetFilter}
        filterType="array"
        param={examSummary.selectedRandomGroupNo ? randomList.param : examList.param}
        paramAction={examSummary.selectedRandomGroupNo ? changeRandomListState : changeListState}
        dataList={dataKeyList}
        selectedList={examSummary.selectedExamNo === 'all' && examList.selectedList}
        selectedType={examSummary.selectedExamNo === 'all' && examList.selectType}
        allCheckText={
          examSummary.selectedExamNo === 'all' && {
            allText: formatMessage({ id: 'Filter_14', defaultMessage: '전체선택' }),
            noneText: formatMessage({ id: 'Filter_15', defaultMessage: '전체해제' }),
          }
        }
        extraBtn={examSummary.selectedExamNo === 'all' ? deleteExamBtn : addTagBtn}
      />

      {/* 내용 리스트 */}
      <ContentList
        className={`${examSummary.selectedExamNo !== 'all' ? 'half' : ''} ${
          examSummary.selectedRandomGroupNo ? 'random' : ''
        }`}
        dataList={examDataList}
        onLoadData={onGetExamList}
        loading={loading}
        page={examSummary.selectedRandomGroupNo ? randomList.page : examList.page}
        totalPage={examSummary.selectedRandomGroupNo ? randomList.totalPage : examList.totalPage}
        noContent={{
          title: formatMessage({ id: 'Exam_35', defaultMessage: '훈련이 없습니다.' }),
          subTitle: formatMessage({ id: 'Exam_36', defaultMessage: '훈련을 실행해 보세요!' }),
        }}
      >
        {examDataList?.length > 0 &&
          examDataList.map((item: any, index: number) => {
            const rowData = examSummary.selectedRandomGroupNo
              ? randomList.data[index]
              : examList.data[index];

            let no = rowData.examNo;
            if (examSummary.selectedExamNo !== 'all') {
              if (pathname === '/exam/pi') {
                no = rowData.targetNo;
              } else if (pathname === '/exam/tag') {
                no = rowData.tagNo;
              }
            }

            return (
              <div
                className={`content-list-item ${listActive(no)}`}
                key={rowData.no}
                onClick={() => onSelectItem('select', no)}
                aria-hidden="true"
              >
                {examSummary.selectedExamNo === 'all' ? (
                  <ExamItemAll
                    data={rowData}
                    summaryData={examSummary.dataByExamNo[rowData.examNo.toString()]}
                    isChecked={examList.selectedList.includes(rowData.examNo)}
                    onSelectCheck={(check: any) => onSelectCheck('check', check)}
                    selectedType={examList.selectType}
                  />
                ) : (
                  <ExamItemEach
                    data={rowData}
                    summaryData={examSummary.dataByExamNo[rowData.examNo.toString()]}
                    type="examTarget"
                  />
                )}
              </div>
            );
          })}
      </ContentList>

      {/* 훈련 삭제 모달 */}
      {deleteModal && (
        <ModalTemplate
          className="exam-delete-modal modal-464"
          visible={deleteModal}
          title={formatMessage({ id: 'Exam_20', defaultMessage: '훈련 삭제' })}
          onOk={onDeleteExam}
          onCancel={() => setDeleteModal(false)}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({
                id: 'Exam_21',
                defaultMessage: '삭제한 훈련은 복구할 수 없습니다.',
              })}
            </div>
            <div>
              {formatMessage({
                id: 'Exam_40',
                defaultMessage: '다음 훈련을 삭제합니까?',
              })}
            </div>
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_22', defaultMessage: '훈련 번호' })}
                </div>
              </li>
              <div>{examList.selectedList.join(', ')}</div>
            </ul>
          </div>
        </ModalTemplate>
      )}

      {/* 태그 만들기 모달 */}
      {tagModal && (
        <ModalTemplate
          className="exam-add-tag-modal modal-464"
          visible={tagModal}
          title={formatMessage({ id: 'Tag_4', defaultMessage: '태그 만들기' })}
          onOk={handleSubmit(onAddTag)}
          onCancel={() => setTagModal(false)}
          okText={formatMessage({ id: 'Button_16', defaultMessage: '생 성' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'Tag_38',
              defaultMessage: '현재 훈련의 필터링된 대상자를 태그로 등록합니다.',
            })}
          </div>
          <div className="modal-border-box">
            <ul className="modal-item-list">
              <li>
                <div className="item-title">
                  {formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}
                </div>
              </li>
              <div>{examData?.examName}</div>
            </ul>
            {filterNames.length > 0 && (
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Filter_1', defaultMessage: '필터' })}
                  </div>
                </li>
                <div>{filterNames.join(', ')}</div>
              </ul>
            )}
            <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Tag_2', defaultMessage: '태그명' })}
                  </div>
                </li>
                <FormTextField
                  name="tagName"
                  error={errors.tagName}
                  arrowPosition="top"
                  register={register}
                  validation={{
                    validate: {
                      required: (value: any) => valid.required(value),
                      name: (value: any) => valid.name(value),
                    },
                  }}
                />
              </ul>
            </form>
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}

export default Pi;
