import { Tag } from 'antd';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getInfectionPcList, infectionPcChangeParam } from 'store/exam';
import { humanFileSize } from 'utils/commonFunctions';


export default function InfectionPcList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false)
  const [pageSize, setPageSize] = useState(tableRowCount)
  const [currentPage, setCurrentPage] = useState(0)
  const dispatch = useDispatch();
  const { sort, filter } = useSelector((state: any) => ({
    sort: state.exam.infectionPcList.param.sort,
    filter: state.exam.infectionPcList.param.filter
  }), shallowEqual)
  const needReload = useSelector((state: any) => state.exam.infectionPcList.needReload)
  const respData = useSelector((state: any) => state.exam.infectionPcList.data)
  const totalCount = useSelector((state: any) => state.exam.infectionPcList.totalCount)
  const totalPages = useSelector((state: any) => state.exam.infectionPcList.totalPages)
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin)

  useEffect(() => {
    getInfectionPcData();
  }, [sort, filter, pageSize, currentPage, needReload])

  // API 호출
  const getInfectionPcData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize
        }

        await dispatch(getInfectionPcList(params))
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  }

  // 검색 
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    infectionNo: "No",
    hostname: "PC",
    ip: "IP",
    targetName: formatMessage({ id: 'Name_4', defaultMessage: '대상자' }),
    targetEmail: formatMessage({ id: 'Email_10', defaultMessage: '대상자 이메일' }),
    examNo: formatMessage({ id: 'Exam_65', defaultMessage: '훈련No' }),
    examName: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
  }

  // 필터
  const filterOption: { [key: string]: any } = {}
  // 테이블 
  const baseColumns = [
    {
      Header: "No",
      accessor: 'infectionNo',
      resizable: false,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text'
    },
    {
      Header: "PC",
      accessor: 'hostname',
      sortable: true,
      width: 143,
      headerClassName: 'react-table-th',
      className: 'react-table-td'
    },
    {
      Header: "IP",
      accessor: 'ip',
      resizable: false,
      sortable: true,
      width: 125,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center'
    },
    {
      Header: formatMessage({ id: 'Name_4', defaultMessage: '대상자' }),
      accessor: 'targetName',
      resizable: false,
      sortable: true,
      width: 106,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
    },
    {
      Header: formatMessage({ id: 'Email_10', defaultMessage: '대상자 이메일' }),
      accessor: 'targetEmail',
      resizable: false,
      sortable: true,
      width: 199,
      headerClassName: 'react-table-th',
      className: 'react-table-td'
    },
    {
      Header: formatMessage({ id: 'Exam_65', defaultMessage: '훈련No' }),
      accessor: 'examNo',
      resizable: false,
      sortable: true,
      width: 99,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text'
    },
    {
      Header: formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' }),
      accessor: 'examName',
      resizable: false,
      sortable: true,
      width: 193,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text'
    },
    {
      Header: formatMessage({ id: 'Date_4', defaultMessage: '감염 일시' }),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return (
          <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>
        );
      }
    },
    {
      Header: formatMessage({ id: 'Infection_18', defaultMessage: '총 감염 개수' }),
      accessor: 'leakAllcount',
      resizable: false,
      sortable: true,
      width: 120,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right sub-text'
    },
    {
      Header: formatMessage({ id: 'Infection_19', defaultMessage: '총 감염 크기' }),
      accessor: 'leakAllsize',
      resizable: true,
      sortable: true,
      width: 120,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right sub-text',
      Cell: (props: {[key: string]: any}) => {
        return humanFileSize(props.value);
      }
    },
    {
      Header: formatMessage({ id: 'Infection_36', defaultMessage: '파일 개수' }),
      accessor: 'leakFileCount',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right sub-text'
    },
    {
      Header: formatMessage({ id: 'Infection_37', defaultMessage: '파일 크기' }),
      accessor: 'leakFileSize',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right sub-text',
      Cell: (props: {[key: string]: any}) => {
        return humanFileSize(props.value);
      }
    },
    {
      Header: formatMessage({ id: 'Infection_38', defaultMessage: '인증서 개수' }),
      accessor: 'leakCertCount',
      resizable: false,
      sortable: true,
      width: 120,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right sub-text'
    },
    {
      Header: formatMessage({ id: 'Infection_39', defaultMessage: '인증서 크기' }),
      accessor: 'leakCertSize',
      resizable: false,
      sortable: true,
      width: 120,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right sub-text',
      Cell: (props: {[key: string]: any}) => {
        return humanFileSize(props.value);
      }
    },
    {
      Header: formatMessage({ id: 'Infection_40', defaultMessage: '메일 개수' }),
      accessor: 'leakEmailCount',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right sub-text'
    },
    {
      Header: formatMessage({ id: 'Infection_41', defaultMessage: '메일 크기' }),
      accessor: 'leakEmailSize',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-right sub-text',
      Cell: (props: {[key: string]: any}) => {
        return humanFileSize(props.value);
      }
    }
  ]

  const columns: Array<any> = useMemo(() => baseColumns, [respData])

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={infectionPcChangeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={infectionPcChangeParam}
        loading={loading}
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
