import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { Popover, Tag, Tooltip } from 'antd';
import fileDownload from 'js-file-download';
import parse from 'html-react-parser';
import { CheckOutlined, CloseOutlined, DeleteFilled, InfoCircleFilled } from '@ant-design/icons';
import {
  getTarget,
  changeTargetState,
  addTarget,
  deleteTarget,
  exportTarget,
  uploadTargetExcel,
} from 'store/target';
import {
  fileSizeTransform,
  nowEpoch,
  openNotification,
  timeFormatFromUTCEpoch,
} from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import * as valid from 'utils/validation';
import Dimmer from 'components/common/Dimmer';
import ContentHeader from 'components/common/ContentHeader';
import SearchFilter from 'components/common/SearchFilter';
import ContentList from 'components/common/ContentList';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import FormSwitchField from 'components/common/FormSwitchField';
import TagSidebar from 'components/branch/tag/TagSidebar';
import TargetScrollItem from 'components/branch/target/TargetScrollItem';
import TargetDetail from 'components/branch/target/TargetDetail';
import './Target.scss';

// import downloadImage from 'img/file/download.png';
// import uploadImage from 'img/file/upload.png';
// import excelImage from 'img/file/excel_icon_01.png';

export const authLevel = 1;
function Target() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const tagData = useSelector((state: any) => {
    return {
      data: state.tag.data,
      dataByTagNo: state.tag.dataByTagNo,
      param: state.tag.param,
      needReload: state.tag.needReload,
      selectedTag: state.tag.selectedTag,
      multiType: state.tag.multiType,
    };
  }, shallowEqual);

  const targetData = useSelector((state: any) => {
    return {
      data: state.target.data,
      dataByTargetNo: state.target.dataByTargetNo,
      param: state.target.param,
      needReload: state.target.needReload,
      selectType: state.target.selectType,
      selectedList: state.target.selectedList,
      page: state.target.page,
      totalPages: state.target.totalPages,
      totalCount: state.target.totalCount,
      targetFilter: state.target.targetFilter,
      mergeDetail: state.target.mergeDetail,
      editInfo: state.target.editInfo,
      isAllTargetCheck: state.target.isAllTargetCheck,
    };
  }, shallowEqual);

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [isResurrection, setIsResurrection] = useState(0);
  const [fileData, setFileData]: any = useState(null);
  const { errors, control, register, getValues, handleSubmit, setError } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    onGetTarget(true);
  }, [
    tagData.selectedTag,
    tagData.needReload,
    tagData.multiType,
    targetData.param,
    targetData.needReload,
  ]);

  // 전체 태그 선택 여부
  const isAllTag = tagData.selectedTag[0] === 'all-tag';

  // 선택한 태그 정보
  const currentTag =
    !isAllTag && tagData.dataByTagNo && tagData.dataByTagNo[tagData.selectedTag[0]];

  // 상단 제목
  let headerTitle = null;
  // 태그 수
  let totalTagCount = tagData.selectedTag.length;
  if (tagData.selectedTag[0] === 'all-tag') {
    headerTitle = formatMessage({ id: 'Tag_42', defaultMessage: '모든 대상자' });
    totalTagCount = tagData.data.length;
  } else if (tagData.selectedTag[0] === 'no-tag') {
    headerTitle = formatMessage({ id: 'Tag_43', defaultMessage: '태그가 없는 대상자' });
    totalTagCount = 0;
  } else {
    headerTitle =
      currentTag &&
      (tagData.selectedTag.length > 1 ? (
        <Popover
          overlayClassName="between-popover"
          placement="bottomLeft"
          content={tagData.selectedTag.map((tagNo: any) => {
            return (
              <Tag
                className={`color-tag tag-label-${tagData.dataByTagNo[tagNo]?.color} tag-max-width`}
                key={tagData.dataByTagNo[tagNo]?.tagNo}
                title={tagData.dataByTagNo[tagNo]?.tagName}
              >
                <span className="ellipsis">{tagData.dataByTagNo[tagNo]?.tagName}</span>
              </Tag>
            );
          })}
        >
          <div className="flex">
            <Tag className={`color-tag tag-label-${currentTag.color} tag-max-width`}>
              <span className="ellipsis">{currentTag.tagName}</span>
            </Tag>
            <div>{` ${formatMessage({ id: 'Tag_51', defaultMessage: '외' })} ${
              tagData.selectedTag.length - 1
            }${formatMessage({ id: 'Tag_52', defaultMessage: '개 태그' })}`}</div>
          </div>
        </Popover>
      ) : (
        <Tag
          className={`color-tag tag-label-${currentTag.color} tag-max-width`}
          title={currentTag.tagName}
        >
          <span className="ellipsis">{currentTag.tagName}</span>
        </Tag>
      ));
  }

  // 상단 부제목
  const headerSubTitle = `${formatMessage({ id: 'Target_12', defaultMessage: '총 대상자' })}: ${
    targetData.totalCount
  }${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}, ${formatMessage({
    id: 'Tag_1',
    defaultMessage: '태그',
  })}: ${totalTagCount}${formatMessage({ id: 'Exam_26', defaultMessage: '개' })}`;

  // 선택한 대상 정보
  const currentTarget =
    targetData.selectedList && targetData.dataByTargetNo[targetData.selectedList[0]];

  // 정렬 메뉴
  const sortMenu = {
    targetNo: formatMessage({ id: 'Filter_16', defaultMessage: '등록순' }),
    targetName: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
    targetEmail: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
    targetDivision: formatMessage({ id: 'Division_1', defaultMessage: '소속' }),
    targetPosition: formatMessage({ id: 'Position_1', defaultMessage: '직급' }),
  };

  // 소속/직급 필터 메뉴
  const divisionArray: Array<any> = [];
  const positionArray: Array<any> = [];
  if (targetData.targetFilter && Object.keys(targetData.targetFilter).length > 0) {
    const { targetDivision, targetPosition } = targetData.targetFilter;
    if (targetDivision?.length > 0) {
      targetDivision.forEach((division: string) => {
        divisionArray.push({
          label: division,
          value: division,
        });
      });
    }
    if (targetPosition?.length > 0) {
      targetPosition.forEach((position: string) => {
        positionArray.push({
          label: position,
          value: position,
        });
      });
    }
  }

  // 필터 메뉴
  const filterMenu = {
    examInfoArray: {
      name: formatMessage({ id: 'Exam_72', defaultMessage: '최근 훈련 결과' }),
      child: [
        {
          label: formatMessage({ id: 'Infection_28', defaultMessage: '없음' }),
          value: 'noExam',
        },
        {
          label: formatMessage({ id: 'Status_10', defaultMessage: '양호' }),
          value: 'send',
        },
        {
          label: formatMessage({ id: 'Read_2', defaultMessage: '메일 열람' }),
          value: 'read',
        },
        {
          label: formatMessage({ id: 'Download_5', defaultMessage: '파일 다운/피싱 접속' }),
          value: 'download',
        },
        {
          label: formatMessage({ id: 'Infection_25', defaultMessage: 'PC 감염/정보 유출' }),
          value: 'infection',
        },
        {
          label: formatMessage({ id: 'Cure_1', defaultMessage: '치료' }),
          value: 'cure',
        },
      ],
    },
    targetDivisionArray: {
      name: formatMessage({ id: 'Division_1', defaultMessage: '소속' }),
      child: divisionArray,
    },
    targetPositionArray: {
      name: formatMessage({ id: 'Position_1', defaultMessage: '직급' }),
      child: positionArray,
    },
  };

  // 대상자 우측 상단 버튼
  const headerBtnComponent = (
    <div className="target-header-btns">
      <div className="target-btns">
        {/* 대상자 등록 */}
        <button
          className="round-grey-button"
          type="button"
          onClick={() => setOpenModal('targetAdd')}
        >
          {formatMessage({ id: 'Target_27', defaultMessage: '대상자 등록' })}
        </button>
        {/* 대상자 삭제 */}
        <button
          className={`round-grey-button ${
            targetData.selectedList?.length < 1 && !targetData.isAllTargetCheck ? 'disabled' : ''
          }`}
          type="button"
          onClick={() => setOpenModal('targetDelete')}
        >
          {formatMessage({ id: 'Target_28', defaultMessage: '대상자 삭제' })}
        </button>
      </div>
      <div className="target-btns">
        {/* 대상자 일괄 등록 */}
        <button
          className="round-grey-button"
          type="button"
          onClick={() => setOpenModal('targetAddExcel')}
        >
          {formatMessage({ id: 'Target_29', defaultMessage: '대상자 일괄 등록' })}
        </button>
        {/* 대상자 내보내기 */}
        <button
          className={`round-grey-button ${
            targetData.selectedList?.length < 1 && !targetData.isAllTargetCheck ? 'disabled' : ''
          }`}
          type="button"
          onClick={() => setOpenModal('targetExportExcel')}
        >
          {formatMessage({ id: 'Target_30', defaultMessage: '대상자 내보내기' })}
        </button>
      </div>
    </div>
  );
  // 리스트 데이터 키 목록
  const dataKeyList: number[] = [];
  Object.keys(targetData.dataByTargetNo).forEach((key: any) => {
    dataKeyList.push(parseInt(key, 10));
  });

  // 리스트 체크 이벤트
  const onSelectCheck = (type: string, targetNo: number) => {
    let newSelectList = [...targetData.selectedList];
    // 타입 변경 시 기존 값 초기화
    if (type !== targetData.selectType) {
      newSelectList = [];
    }

    if (newSelectList.includes(targetNo)) {
      newSelectList.splice(newSelectList.indexOf(targetNo), 1);
    } else {
      newSelectList.push(targetNo);
    }
    dispatch(changeTargetState({ key: 'selectType', value: 'check' }));
    dispatch(changeTargetState({ key: 'selectedList', value: newSelectList }));
  };

  // 대상자 선택 이벤트
  const onSelectItem = (type: string, targetNo: number) => {
    let select = [...targetData.selectedList];
    if (type !== targetData.selectType) {
      // 타입 변경 시 현재 선택한 값 저장
      select = [targetNo];
    } else {
      select = targetData.selectedList.includes(targetNo) ? [] : [targetNo];
    }

    dispatch(changeTargetState({ key: 'selectType', value: 'select' }));
    dispatch(changeTargetState({ key: 'selectedList', value: select }));
  };

  // 대상자 정보 조회
  const onGetTarget = async (refresh = false) => {
    setLoading(true);

    try {
      const params: any = {
        tagNoArray:
          tagData.selectedTag[0] === 'all-tag' || tagData.selectedTag[0] === 'no-tag'
            ? tagData.selectedTag[0]
            : JSON.stringify(tagData.selectedTag),
        filter: JSON.stringify(targetData.param.filter),
        sort: JSON.stringify(targetData.param.sort),
        offset: 0,
        limit: targetData.param.limit,
        multiType: tagData.multiType,
      };

      if (Object.keys(targetData?.param?.filter).length > 0) {
        params.examFilter = JSON.stringify(targetData.param.filter);
      }

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = targetData.param.limit * targetData.page;
      }

      await dispatch(getTarget(params));
      setLoading(false);
    } catch (error) {
      console.log('Target onGetTarget', error);
    }
  };

  // 모든 대상자 버튼 선택 이벤트
  const changeAllTargetCheck = () => {
    dispatch(
      changeTargetState({
        key: 'isAllTargetCheck',
        value: targetData.isAllTargetCheck ? 0 : 1,
      }),
    );
    dispatch(
      changeTargetState({
        key: 'selectedList',
        value: [],
      }),
    );
  };

  // 모달 닫기
  const onCloseModal = () => {
    setOpenModal('');
    if (isResurrection) {
      setIsResurrection(0);
    }
  };

  // 대상자 등록
  const onAddTarget = async () => {
    try {
      setLoading(true);

      const params: any = {};
      // 입력 값 있는 것만 파라미터에 담음
      const values = getValues();
      Object.keys(values).forEach((key: string) => {
        if (values[key]) {
          params[key] = values[key];
        }
      });

      // 기존에 삭제된 대상자 재등록
      if (isResurrection) {
        params.isResurrection = isResurrection;
      }

      const response: any = await dispatch(addTarget(params));
      setLoading(false);
      if (!response?.data?.error) {
        setOpenModal('');

        // 메시지 팝업
        openNotification(
          `'${values.targetName}' ${formatMessage({
            id: 'Target_27',
            defaultMessage: '대상자 등록',
          })}`,
        );
      } else if (response?.data?.error?.result === 'Duplicate Target Exist') {
        setError('targetEmail', {
          type: 'duplicate',
          message: response.data.error[0].result,
        });
      }
    } catch (error) {
      console.log('Target onAddTarget', error);
    }
  };

  // 대상자 삭제
  const onDeleteTarget = async () => {
    try {
      setLoading(true);

      const params: any = { isAllCheck: targetData.isAllTargetCheck ? 1 : 0 };
      if (targetData.isAllTargetCheck) {
        params.tagNoArray = tagData.selectedTag[0].toString();
      } else {
        params.targetNoArray = targetData.selectedList.join(',');
      }

      const response: any = await dispatch(deleteTarget(params));
      if (!response?.data?.error) {
        setLoading(false);
        setOpenModal('');

        // 메시지 팝업
        openNotification(
          `${
            targetData.isAllTargetCheck ? targetData.totalCount : targetData.selectedList.length
          }${formatMessage({
            id: 'Target_35',
            defaultMessage: '명의 대상자 삭제',
          })}`,
        );
      }
    } catch (error) {
      console.log('Target onDeleteTarget', error);
    }
  };

  // 대상자 내보내기
  const onExportTarget = async () => {
    try {
      setLoading(true);

      let tagNoArray: any = [];
      if (tagData.selectedTag[0] === 'all-tag') {
        tagNoArray = 'all-tag';
      } else if (tagData.selectedTag[0] === 'no-tag') {
        tagNoArray = 'no-tag';
      } else {
        tagNoArray = JSON.stringify(tagData.selectedTag);
      }

      const params: any = {
        tagNoArray: tagNoArray,
        sort: JSON.stringify(targetData.param.sort),
        filter: JSON.stringify(targetData.param.filter),
        isAllCheck: targetData.isAllTargetCheck ? 1 : 0,
        multiType: tagData.multiType,
      };

      if (targetData.selectedList.length > 0) {
        params.targetNoArray = JSON.stringify(targetData.selectedList);
      }

      const response: any = await dispatch(exportTarget(params));
      if (response?.data) {
        fileDownload(
          response.data,
          `${Option.customer === 'saxa' ? 'Maitra' : 'MudFix'}Target_${timeFormatFromUTCEpoch(
            nowEpoch(),
          )}.xlsx`,
        );

        setLoading(false);
        setOpenModal('');
      }
    } catch (error) {
      console.log('Target onDeleteTarget', error);
    }
  };

  // 엑셀양식 다운로드
  const onTargetExcelDownload = () => {
    window.location.href = `/fdata/serve/${
      Option.customer === 'saxa' ? 'Maitra' : 'MudFix'
    }TargetUpload_${Option.isJapan ? 'ja' : localStorage.getItem('language')}.xls`;
  };

  // 업로드할 파일 선택
  const selectExcelFile = (e: any) => {
    const file = e.target.files[0];

    if (file) {
      if (file.name.includes(`${Option.customer === 'saxa' ? 'Maitra' : 'MudFix'}TargetUpload`)) {
        setFileData({
          uploadResult: { isUpload: 0, resultData: {} },
          excelFile: { acceptFile: file, rejectFile: null },
        });
      } else {
        setFileData({
          uploadResult: { isUpload: 0, resultData: {} },
          excelFile: { acceptFile: null, rejectFile: file },
        });
      }
    } else {
      setFileData(null);
    }
  };

  // 대상자 엑셀 파일 업로드
  const onUploadExcel = async () => {
    try {
      setLoading(true);
      const { acceptFile } = fileData.excelFile;

      if (acceptFile) {
        const formData: any = new FormData();
        formData.append('name', acceptFile.name);
        formData.append('file', acceptFile);
        formData.append('isResurrection', isResurrection ? 1 : 0);

        const response: any = await dispatch(uploadTargetExcel(formData));
        if (response?.status === 200) {
          if (response?.data?.list) {
            const {
              successCount,
              editCount,
              errCount,
              editInfo,
              errInfo,
              detailInfo,
            } = response.data.list;

            // 업로드 성공
            if (successCount > 0 || editCount > 0) {
              if (errCount > 0) {
                // 일부만 업로드 성공
                setFileData({
                  ...fileData,
                  uploadResult: {
                    isUpload: 1,
                    resultData: {
                      successCount,
                      editCount,
                      editInfo,
                      errCount,
                      errInfo,
                      detailInfo,
                    },
                  },
                });
              } else {
                // 모두 업로드 성공
                setFileData({
                  ...fileData,
                  uploadResult: {
                    isUpload: 1,
                    resultData: { successCount, editCount, editInfo, errCount, detailInfo },
                  },
                });
              }
            } else {
              // 업로드 실패
              setFileData({
                ...fileData,
                uploadResult: {
                  isUpload: 2,
                  resultData: { editCount, editInfo, errCount, errInfo },
                },
              });
            }
          } else if (Array.isArray(response.data.error)) {
            // 업로드 실패 - 대상자 10,000명 이상 업로드 시
            setFileData({
              ...fileData,
              uploadResult: {
                isUpload: 4,
                resultData: { errCount: 0, errInfo: response.data.error[0].errInfo },
              },
            });
          }
        } else {
          // 서버 에러
          setFileData({
            ...fileData,
            uploadResult: {
              isUpload: 3,
              resultData: { errCount: 0, errInfo: response.data.error[0].errInfo },
            },
          });
        }
        setLoading(false);
      }
    } catch (error) {
      console.log('Target onUploadExcel', error);
    }
  };

  // 대상자 삭제 - 선택된 태그
  let deleteTagText = null;
  if (tagData.selectedTag[0] === 'all-tag') {
    deleteTagText = formatMessage({ id: 'Tag_21', defaultMessage: '모든 태그' });
  } else if (tagData.selectedTag[0] === 'no-tag') {
    deleteTagText = formatMessage({ id: 'Tag_43', defaultMessage: '태그가 없는 대상자' });
  } else if (tagData.selectedTag.length > 0) {
    deleteTagText = tagData.selectedTag.map((tagNo: any) => (
      <Tag
        className={`color-tag tag-label-${tagData.dataByTagNo[tagNo]?.color} small`}
        key={tagData.dataByTagNo[tagNo]?.tagNo}
      >
        {tagData.dataByTagNo[tagNo]?.tagName}
      </Tag>
    ));
  }

  // 업로드 결과 컴포넌트
  let resultComp = null;
  let successComp = null;
  let editComp = null;
  let errorComp = null;
  if (fileData?.uploadResult?.isUpload) {
    const { resultData, isUpload } = fileData.uploadResult;
    const { detailInfo, editInfo, errInfo, successCount, editCount, errCount } = resultData;

    // 업로드 성공 상세
    if (detailInfo && Object.keys(detailInfo)?.length > 0) {
      successComp = (
        <div className="result-area brand-color">
          <div className="result-title">
            {formatMessage({ id: 'Target_43', defaultMessage: '성공 내역' })}
          </div>
          <div className="result-content">
            {Object.keys(detailInfo).map((key: string) => {
              return (
                <div className="result-row" key={key}>
                  <div className="left">{`${key}${formatMessage({
                    id: 'Target_46',
                    defaultMessage: '행',
                  })}`}</div>
                  <div className="right">{detailInfo[key].targetEmail}</div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    // 수정 업로드 성공 상세
    if (editCount) {
      const editRowArray: any = [];
      Object.keys(editInfo).forEach((key: string) => {
        const editMessageArray: any = [];
        if (editInfo[key]) {
          const edit = editInfo[key];

          if (edit.targetName) {
            editMessageArray.push(formatMessage({ id: 'Name_1', defaultMessage: '이름' }));
          }
          if (edit.targetPhone) {
            editMessageArray.push(
              formatMessage({
                id: 'Contact_5',
                defaultMessage: '올바르지 않은 연락처 양식입니다.',
              }),
            );
          }
          if (edit.targetDivision) {
            editMessageArray.push(formatMessage({ id: 'Division_1', defaultMessage: '소속' }));
          }
          if (edit.targetPosition) {
            editMessageArray.push(formatMessage({ id: 'Position_1', defaultMessage: '직급' }));
          }
          if (edit.tagName) {
            const tagArray: Array<string> = [];
            edit.tagName.forEach((tag: any) => {
              if (tag.trim()) {
                tagArray.push(tag);
              }
            });

            editMessageArray.push(
              `${formatMessage({ id: 'Tag_1', defaultMessage: '태그' })}(${tagArray.join(', ')})`,
            );
          }
        }

        editRowArray.push(
          <div className="result-row" key={key}>
            <div className="left">{`${key}${formatMessage({
              id: 'Target_46',
              defaultMessage: '행',
            })}`}</div>
            <div className="right">{editMessageArray.join(', ')}</div>
          </div>,
        );
      });

      editComp = (
        <div className="result-area edit">
          <div className="result-title">
            {formatMessage({ id: 'Target_44', defaultMessage: '수정 내용' })}
          </div>
          <div className="result-content">{editRowArray}</div>
        </div>
      );
    }

    // 업로드 실패 상세
    if (errCount) {
      const errorRowArray: any = [];
      Object.keys(errInfo).forEach((key: string) => {
        const errorMessageArray: any = [];
        if (errInfo[key]) {
          const error = errInfo[key];
          if (error.duplicated) {
            errorMessageArray.push(
              formatMessage({ id: 'Email_9', defaultMessage: '이미 등록된 이메일입니다.' }),
            );
          } else if (error.targetEmail && error.targetName) {
            errorMessageArray.push(
              formatMessage({
                id: 'Name_15',
                defaultMessage: '필수 입력 값(이름/이메일)이 올바르지 않습니다.',
              }),
            );
          } else if (error.targetEmail) {
            errorMessageArray.push(
              formatMessage({
                id: 'Email_7',
                defaultMessage: '올바르지 않은 이메일 양식입니다.',
              }),
            );
          } else if (error.targetName) {
            errorMessageArray.push(
              formatMessage({
                id: 'Name_14',
                defaultMessage: '필수 입력 값(이름)이 올바르지 않습니다.',
              }),
            );
          } else if (error.targetPhone) {
            errorMessageArray.push(
              formatMessage({
                id: 'Contact_5',
                defaultMessage: '올바르지 않은 연락처 양식입니다.',
              }),
            );
          } else if (error.tagNameArray) {
            errorMessageArray.push(
              formatMessage({
                id: 'Tag_53',
                defaultMessage: '입력 값(태그)이 올바르지 않습니다.',
              }),
            );
          }
        }

        errorRowArray.push(
          <div className="result-row" key={key}>
            <div className="left">{`${key}${formatMessage({
              id: 'Target_46',
              defaultMessage: '행',
            })}`}</div>
            <div className="right">{errorMessageArray}</div>
          </div>,
        );
      });

      errorComp = (
        <div className="result-area color-red">
          <div className="result-title">
            {formatMessage({ id: 'Target_45', defaultMessage: '실패 사유' })}
          </div>
          <div className="result-content">{errorRowArray}</div>
        </div>
      );
    }

    // 업로드 결과 요약
    if (isUpload === 1) {
      // 업로드 성공
      resultComp = (
        <div className="file-upload-result">
          <div className="upload-summary-title brand-color">
            <CheckOutlined />
            {formatMessage({ id: 'Target_48', defaultMessage: '업로드 성공' })}
          </div>
          <div className="upload-summary-content">
            <div>
              {`${formatMessage({ id: 'Target_12', defaultMessage: '총 대상자' })}: ${
                (successCount || 0) + editCount + errCount
              }${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`}
            </div>
            <div className="brand-color">
              {!!successCount &&
                `${formatMessage({
                  id: 'Target_52',
                  defaultMessage: '성공',
                })}: ${successCount}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`}
            </div>
            <div className="brand-color">
              {!!editCount &&
                `${formatMessage({
                  id: 'Target_18',
                  defaultMessage: '수정',
                })}: ${editCount}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`}
            </div>
            <div className="color-red">
              {!!errCount &&
                `${formatMessage({
                  id: 'Send_14',
                  defaultMessage: '실패',
                })}: ${errCount}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`}
            </div>
          </div>

          <div className="upload-detail">
            {successComp}
            {editComp}
            {errorComp}
          </div>
        </div>
      );
    } else if (isUpload === 2) {
      // 업로드 실패
      resultComp = (
        <div className="file-upload-result">
          <div className="upload-summary-title color-red">
            <CloseOutlined />
            {formatMessage({ id: 'Target_49', defaultMessage: '업로드 실패' })}
          </div>
          <div className="upload-summary-content">
            {`${formatMessage({
              id: 'Target_12',
              defaultMessage: '총 대상자',
            })}: ${errCount}${formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}`}
          </div>
          <div className="upload-detail">{errorComp}</div>
        </div>
      );
    } else if (isUpload === 3) {
      // 업로드 실패 - 서버 에러
      resultComp = (
        <div className="file-upload-result">
          <div className="upload-summary-title color-red">
            <CloseOutlined />
            {formatMessage({ id: 'Target_50', defaultMessage: '서버 에러 발생' })}
            <div>{formatMessage({ id: 'Target_51', defaultMessage: '다시 시도하세요.' })}</div>
          </div>
        </div>
      );
    } else if (isUpload === 4) {
      // 업로드 실패 - 대상자 10,000명 이상 업로드
      resultComp = (
        <div className="file-upload-result">
          <div className="upload-summary-title color-red">
            <CloseOutlined />
            {formatMessage({ id: 'Target_49', defaultMessage: '업로드 실패' })}
            <div>{errInfo}</div>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="common-content-layout">
      <div className="common-left-side">
        <TagSidebar type="target" />
      </div>
      <div className="common-content">
        <div className="common-inner">
          <ContentHeader
            title={headerTitle}
            subTitle={headerSubTitle}
            buttons={headerBtnComponent}
            // onRefresh={() => onGetTarget(true)}
          />

          <div className="target-content-wrap pi">
            {/* 검색 영역 */}
            <SearchFilter
              sortMenu={sortMenu}
              filterMenu={filterMenu}
              filterType="object"
              param={targetData.param}
              paramAction={changeTargetState}
              dataList={dataKeyList}
              selectedList={targetData.selectedList}
              extraBtn={
                <Tooltip
                  overlayClassName="black-tooltip full"
                  placement="top"
                  title={formatMessage({
                    id: 'Target_26',
                    defaultMessage: '모든 대상자를 선택합니다.',
                  })}
                >
                  <div
                    className={`round-grey-button ${
                      targetData.isAllTargetCheck ? 'brand-green' : ''
                    }`}
                    onClick={changeAllTargetCheck}
                    aria-hidden="true"
                  >
                    <div className="all-icon">All</div>
                    {formatMessage({ id: 'Tag_42', defaultMessage: '모든 대상자' })}
                  </div>
                </Tooltip>
              }
            />

            <ContentList
              dataList={targetData.data}
              onLoadData={onGetTarget}
              loading={loading}
              page={targetData.page}
              totalPage={targetData.totalPages}
              noContent={{
                title: formatMessage({ id: 'Target_14', defaultMessage: '대상자가 없습니다.' }),
                subTitle: formatMessage({
                  id: 'Target_15',
                  defaultMessage: '대상자를 추가하세요.',
                }),
              }}
              showNoImage
            >
              {targetData.data.map((rowData: any) => {
                return (
                  <div
                    className={`content-list-item ${
                      targetData.selectType === 'select' &&
                      targetData.selectedList?.includes(rowData.targetNo)
                        ? 'selected'
                        : ''
                    } ${targetData.isAllTargetCheck ? 'block-click' : ''}`}
                    key={rowData.targetNo}
                    onClick={() => onSelectItem('select', rowData.targetNo)}
                    aria-hidden="true"
                  >
                    <TargetScrollItem
                      data={rowData}
                      dataByTagNo={tagData.dataByTagNo}
                      onSelectCheck={onSelectCheck}
                      isChecked={targetData.selectedList.includes(rowData.targetNo)}
                      selectedType={targetData.selectType}
                      isAllTargetCheck={targetData.isAllTargetCheck}
                    />
                  </div>
                );
              })}
            </ContentList>
          </div>
        </div>
        <div className="common-inner-right">
          {(targetData.data || currentTarget) &&
          (targetData.selectedList?.length > 0 || targetData.isAllTargetCheck) ? (
            <TargetDetail
              detailType={
                targetData.selectedList.length > 1 || targetData.isAllTargetCheck
                  ? 'multi'
                  : 'single'
              }
              detailData={targetData.selectedList.length > 1 ? targetData.data : currentTarget}
              editInfo={targetData.editInfo}
              selectTargetList={targetData.selectedList}
              isAllTargetCheck={targetData.isAllTargetCheck}
              dataByTagNo={tagData.dataByTagNo}
              tagData={tagData.data}
              selectTagList={tagData.selectedTag}
            />
          ) : (
            <Dimmer
              dimmerText={formatMessage({
                id: 'Target_13',
                defaultMessage: '대상자를 선택하세요.',
              })}
            />
          )}
        </div>
      </div>

      {/* 대상자 등록 모달 */}
      {openModal === 'targetAdd' && (
        <ModalTemplate
          visible={openModal === 'targetAdd'}
          className="modal-464"
          onOk={handleSubmit(onAddTarget)}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_10', defaultMessage: '등 록' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          title={formatMessage({ id: 'Target_27', defaultMessage: '대상자 등록' })}
        >
          <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
            <div className="input-title">
              {formatMessage({ id: 'Name_2', defaultMessage: '이름*' })}
            </div>
            <FormTextField
              name="targetName"
              error={errors.targetName}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  name: (value: any) => valid.name(value),
                },
              }}
            />

            <div className="text-field-title">
              {formatMessage({ id: 'Email_2', defaultMessage: '이메일*' })}
            </div>
            <FormTextField
              name="targetEmail"
              error={errors.targetEmail}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  email: (value: any) => valid.email(value),
                },
              }}
            />

            <div className="text-field-title">
              {formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}
            </div>
            <FormTextField
              name="targetPhone"
              error={errors.targetPhone}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  userPhone: (value: any) => valid.userPhone(value),
                },
              }}
            />

            <div className="text-field-title">
              {formatMessage({ id: 'Division_1', defaultMessage: '소속' })}
            </div>
            <FormTextField
              name="targetDivision"
              error={errors.targetDivision}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  name: (value: any) => valid.name(value),
                  targetPosition: (value: any) => valid.targetPosition(value),
                },
              }}
            />

            <div className="text-field-title">
              {formatMessage({ id: 'Position_1', defaultMessage: '직급' })}
            </div>
            <FormTextField
              name="targetPosition"
              error={errors.targetPosition}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  name: (value: any) => valid.name(value),
                  targetDivision: (value: any) => valid.targetPosition(value),
                },
              }}
            />

            <div className="text-field-title">
              {formatMessage({ id: 'Tag_2', defaultMessage: '태그명' })}
            </div>
            <FormTextField
              name="targetTag"
              error={errors.targetTag}
              register={register}
              arrowPosition="top"
              validation={{
                validate: {
                  name: (value: any) => valid.name(value),
                },
              }}
            />

            {/* 기존에 삭제된 대상자 재등록 */}
            <div className="switch-wrap">
              <FormSwitchField
                control={control}
                name="isResurrection"
                defaultValue={isResurrection}
                handleOnChange={(name: string, data: number) => setIsResurrection(data)}
              />
              <div className="input-title">
                {formatMessage({ id: 'Target_31', defaultMessage: '기존에 삭제된 대상자 재등록' })}
              </div>
            </div>
          </form>
        </ModalTemplate>
      )}

      {/* 대상자 삭제 모달 */}
      {openModal === 'targetDelete' && (
        <ModalTemplate
          className="modal-464"
          visible={openModal === 'targetDelete'}
          title={formatMessage({ id: 'Target_28', defaultMessage: '대상자 삭제' })}
          onOk={onDeleteTarget}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({
                id: 'Target_32',
                defaultMessage: '삭제한 대상자는 복구할 수 없습니다.',
              })}
            </div>
            <div>
              {formatMessage({
                id: 'Target_33',
                defaultMessage: '다음 대상자를 삭제합니까?',
              })}
            </div>
          </div>
          <div className="modal-border-box">
            {!!currentTarget && targetData.selectedList.length === 1 && (
              <>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">No</div>
                  </li>
                  <div>{targetData.selectedList[0]}</div>
                </ul>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">
                      {formatMessage({ id: 'Name_13', defaultMessage: '대상자명' })}
                    </div>
                  </li>
                  <div>{currentTarget.targetName}</div>
                </ul>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">
                      {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
                    </div>
                  </li>
                  <div>{timeFormatFromUTCEpoch(currentTarget.regEpoch)}</div>
                </ul>
              </>
            )}

            {!!targetData.isAllTargetCheck && (
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Tag_30', defaultMessage: '선택된 태그' })}
                  </div>
                </li>
                <div>{deleteTagText}</div>
              </ul>
            )}

            {(targetData.selectedList.length > 1 || !!targetData.isAllTargetCheck) && (
              <ul className="modal-item-list">
                <li>
                  <div className="item-title">
                    {formatMessage({ id: 'Target_34', defaultMessage: '총 대상자 수' })}
                  </div>
                </li>
                <div>
                  {targetData.isAllTargetCheck
                    ? targetData.totalCount
                    : targetData.selectedList.length}
                </div>
              </ul>
            )}
          </div>
        </ModalTemplate>
      )}

      {/* 대상자 일괄 등록 모달 */}
      {openModal === 'targetAddExcel' && (
        <ModalTemplate
          visible={openModal === 'targetAddExcel'}
          className="target-upload-modal modal-464"
          onOk={handleSubmit(onUploadExcel)}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_20', defaultMessage: '업로드' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          title={formatMessage({ id: 'Target_29', defaultMessage: '대상자 일괄 등록' })}
          disabled={
            !fileData || fileData?.excelFile?.rejectFile || fileData?.uploadResult?.isUpload
          }
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({
                id: 'Target_37',
                defaultMessage: '엑셀양식으로 한번에 여러 대상자를 추가합니다.',
              })}
            </div>
          </div>

          {/* 엑셀양식 다운로드 버튼 */}
          <div className="excel-format-download">
            <button type="button" onClick={onTargetExcelDownload}>
              <img src="/img/file/download.png" alt="download" />
              <span className="download-text">
                {formatMessage({ id: 'Target_38', defaultMessage: '엑셀양식 다운로드' })}
              </span>
            </button>
          </div>

          {/* 기존에 삭제된 대상자 재등록 */}
          <div className="switch-wrap">
            <FormSwitchField
              control={control}
              name="isResurrection"
              defaultValue={isResurrection}
              handleOnChange={(name: string, data: number) => setIsResurrection(data)}
            />
            <div className="input-title">
              {formatMessage({ id: 'Target_31', defaultMessage: '기존에 삭제된 대상자 재등록' })}
            </div>
          </div>

          {/* 파일 업로드 */}
          <div className="file-input-area">
            {fileData?.excelFile ? (
              <label className="file-label">
                {fileData.excelFile.acceptFile ? (
                  // 제공된 엑셀 양식인 경우
                  <div className="accept-file-area">
                    <img src="/img/file/excel_icon_01.png" alt="excel" />
                    <div className="file-info">
                      <div className="file-name">{fileData.excelFile.acceptFile.name}</div>
                      <div className="file-size">
                        {fileSizeTransform(fileData.excelFile.acceptFile.size).common}
                      </div>
                    </div>
                  </div>
                ) : (
                  // 제공된 엑셀 양식이 아닌 경우
                  <div className="reject-file-area">
                    <span className="color-red bold">{`'${fileData.excelFile.rejectFile.name}'`}</span>
                    {formatMessage({
                      id: 'Target_41',
                      defaultMessage: '은 대상자 일괄 등록 엑셀 양식이 아닙니다.',
                    })}
                    <div className="info-message">
                      <InfoCircleFilled />
                      {parse(
                        formatMessage({
                          id: 'Target_42',
                          defaultMessage:
                            "<b>'엑셀양식 다운로드'</b>로 작성한 파일만 등록이 가능합니다.",
                        }),
                      )}
                    </div>
                  </div>
                )}
              </label>
            ) : (
              <label className="upload-hint">
                <img src="/img/file/upload.png" alt="upload" />
                {formatMessage({
                  id: 'Target_39',
                  defaultMessage: 'Excel 파일을 끌어서 놓거나 클릭하세요.',
                })}
              </label>
            )}

            <input
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              type="file"
              onChange={(e: any) => selectExcelFile(e)}
              onClick={(e: any) => (e.target.value = null)} // eslint-disable-line
            />
          </div>

          {/* 파일 업로드 결과 */}
          {resultComp}
        </ModalTemplate>
      )}

      {/* 대상자 내보내기 모달 */}
      {openModal === 'targetExportExcel' && (
        <ModalTemplate
          className="modal-464"
          visible={openModal === 'targetExportExcel'}
          title={formatMessage({ id: 'Target_30', defaultMessage: '대상자 내보내기' })}
          onOk={onExportTarget}
          onCancel={onCloseModal}
          okText={formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          greyButton
        >
          <div className="modal-explain-text">
            <div>
              {formatMessage({
                id: 'Target_36',
                defaultMessage: '다음 대상자 정보를 내려받기 하시겠습니까?',
              })}
            </div>
          </div>
          <div className="modal-border-box">
            {!!currentTarget && targetData.selectedList.length === 1 && (
              <>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">No</div>
                  </li>
                  <div>{targetData.selectedList[0]}</div>
                </ul>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">
                      {formatMessage({ id: 'Name_13', defaultMessage: '대상자명' })}
                    </div>
                  </li>
                  <div>{currentTarget.targetName}</div>
                </ul>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">
                      {formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
                    </div>
                  </li>
                  <div>{timeFormatFromUTCEpoch(currentTarget.regEpoch)}</div>
                </ul>
              </>
            )}

            {(targetData.selectedList.length > 1 || !!targetData.isAllTargetCheck) && (
              <>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">
                      {formatMessage({ id: 'Tag_30', defaultMessage: '선택된 태그' })}
                    </div>
                  </li>
                  <div>{deleteTagText}</div>
                </ul>
                <ul className="modal-item-list">
                  <li>
                    <div className="item-title">
                      {formatMessage({ id: 'Target_34', defaultMessage: '총 대상자 수' })}
                    </div>
                  </li>
                  <div>
                    {targetData.isAllTargetCheck
                      ? targetData.totalCount
                      : targetData.selectedList.length}
                  </div>
                </ul>
              </>
            )}
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}
export default Target;
