import React from 'react';
import { useIntl } from 'react-intl';
import { MailOutlined } from '@ant-design/icons';
import { Option, updateVersion } from 'utils/commonValues';
import './Footer.scss';

// import logoImage from 'img/logo/m_logo.png';
// import sLogoImage from 'img/logo/s_logo.png';
// import companyImage from 'img/logo/company.png';
// import companyJJSImage from 'img/logo/jjs_company.png';

function Footer() {
  const { formatMessage } = useIntl();
  const { isJapan, customer } = Option;
  const mailKo = 'mudfix@jiran.com';
  const mailJa = 'support-m@jsecurity.co.jp';

  const companyInfo: any = { logo: '', subTitle: '', contents: <></>, corp: '' };
  if (Option.isJapan) {
    companyInfo.logo = '/img/logo/jjs_company.png';
    companyInfo.subTitle = '株式会社 JSecurity';
    companyInfo.contents = (
      <>
        〒105-5117 東京都港区浜松町2-4-1 世界貿易センタービルディング南館17階
        <br />
        TEL : 03-4567-2823　FAX : 03-4567-2824
      </>
    );
    companyInfo.corp = `© ${new Date().getFullYear()} 株式会社 JSecurity`;

    if (Option.customer === 'saxa') {
      companyInfo.subTitle = 'サクサ株式会社';
      companyInfo.contents = <></>;
      companyInfo.corp = (
        <a href="https://www.saxa.co.jp/">{`© ${new Date().getFullYear()} サクサ株式会社`}</a>
      );
    }
  } else {
    companyInfo.logo = '/img/logo/company.png';
    companyInfo.subTitle = formatMessage({ id: 'Company_1', defaultMessage: '㈜지란지교시큐리티' });
    companyInfo.contents = (
      <>
        {formatMessage({
          id: 'Company_2',
          defaultMessage: '사업자 등록 번호: 107-88-31670',
        })}
        {` | Tel: (02)569-6110 Fax: (02)569-6117`}
        <br />
        {formatMessage({
          id: 'Company_3',
          defaultMessage: '서울: (06187) 서울특별시 강남구 역삼로 542 신사S&G빌딩 5층',
        })}
        <br />
        {formatMessage({
          id: 'Company_4',
          defaultMessage: '대전: (34016) 대전광역시 유성구 테크노3로 65 한신S메카 518호',
        })}
        <br />
        {formatMessage({
          id: 'Company_5',
          defaultMessage: '부산: (48058) 부산광역시 해운대구 센텀서로 30 KNN빌딩 1501호',
        })}
      </>
    );
    companyInfo.corp = 'Copyright©JiranSecurity. All rights reserved.';
  }

  return (
    <div className="footer-area">
      <div className={`version ${Option.customer}`}>{updateVersion}</div>
      {customer === 'hyundai' ? (
        <div className="footer-corp">현대오토에버(주) HYUNDAI AUTOEVER Corp.</div>
      ) : (
        <div className="footer-contents">
          {Option.customer !== 'saxa' && (
            <div className="footer-row">
              {/* Service & About */}
              <div className="footer-item">
                <div className="left">
                  {/* <img src={Option.isSaxa === 1 ? sLogoImage : logoImage} alt="logo" className="logo" /> */}
                  <img src="/img/logo/m_logo.png" alt="logo" className="logo" />
                </div>
                <div className="right">
                  <div className="sub-item">
                    <div className="title">Service</div>
                    <div className="text">
                      {formatMessage({ id: 'Login_9', defaultMessage: '서비스' })}
                      <br />
                      FAQ
                    </div>
                  </div>
                  <div className="sub-item">
                    <div className="title">About</div>
                    <div className="text">
                      {formatMessage({ id: 'Header_1', defaultMessage: '훈련결과' })}
                      <br />
                      {formatMessage({ id: 'Header_4', defaultMessage: '대상관리' })}
                    </div>
                  </div>
                </div>
              </div>
              {/* Contact US */}
              <div className="footer-item">
                <div className="right">
                  <div>
                    <div className="title">Contact US</div>
                    <div className="text">
                      <MailOutlined />
                      <a href={`mailto:${isJapan ? mailJa : mailKo}`} className="brand-color">
                        {isJapan ? mailJa : mailKo}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="footer-row">
            {/* Company */}
            <div className="footer-item">
              <div className="left">
                {/* <img src={isJapan ? companyJJSImage : companyImage} alt="logo" /> */}
                <img src={companyInfo.logo} alt="logo" />
              </div>
              <div className="right">
                <div className="sub-item">
                  <div className="sub-title">{companyInfo.subTitle}</div>
                  {companyInfo.contents}
                </div>
              </div>
            </div>
            {/* etc */}
            <div className="footer-item">
              <div className="right">
                <div>
                  <div className="sub-title">
                    <a
                      href={
                        Option.customer === 'saxa'
                          ? 'https://www.saxa.co.jp/product/terms/pdf/aemt_policy.pdf'
                          : `/tos_${localStorage.getItem('language')}.html`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {formatMessage({ id: 'Service_12', defaultMessage: '서비스 이용약관' })}
                    </a>
                    <a
                      href={
                        Option.customer === 'saxa'
                          ? 'https://www.saxa.co.jp/privacy02.html'
                          : `/tos_${localStorage.getItem('language')}.html`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {Option.customer === 'saxa'
                        ? formatMessage({ id: 'Terms_6', defaultMessage: '개인정보보호방침' })
                        : formatMessage({ id: 'Terms_5', defaultMessage: '개인정보처리방침' })}
                    </a>
                  </div>
                  <div className="text">{companyInfo.corp}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
