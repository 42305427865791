import { Popover, Tag } from 'antd';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTarget, changeParam } from 'store/target';
import TagItem from 'components/branch/target/TagItem';
import TargetSettings from 'components/branch/target/TargetSettings';
import './TargetList.scss';
import { SettingOutlined } from '@ant-design/icons';

export default function TargetList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.target.param.sort,
      filter: state.target.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.target.needReload);
  const respData = useSelector((state: any) => state.target.data);
  const totalCount = useSelector((state: any) => state.target.totalCount);
  const totalPages = useSelector((state: any) => state.target.totalPages);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  useEffect(() => {
    getTargetData(true);
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  const getTargetData = async (refresh = false) => {
    if (!loading) {
      setLoding(true);

      try {
        const params: any = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize,
        };

        if (refresh) {
          params.refresh = true;
        }

        await dispatch(getTarget(params));
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  };

  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    serviceNo: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
    serviceName: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
    targetNo: 'No',
    targetName: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
    targetEmail: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
    targePhone: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
    targeDivisionArray: formatMessage({ id: 'Division_1', defaultMessage: '소속' }),
    targePositionArray: formatMessage({ id: 'Position_1', defaultMessage: '직급' }),
    tagList: formatMessage({ id: 'Tag_2', defaultMessage: '태그명' }),
  };

  // 필터
  const filterOption: { [key: string]: any } = {};

  // 테이블
  const baseColumns = [
    {
      Header: <SettingOutlined />,
      accessor: 'setting',
      resizable: false,
      sortable: false,
      width: 45,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center settings',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return <TargetSettings isAdmin={isAdmin} dataInfo={props.row.original} />;
      },
    },
    {
      Header: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
      accessor: 'serviceNo',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
    },
    {
      Header: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
      accessor: 'serviceName',
      resizable: false,
      sortable: true,
      width: 143,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center sub-text',
    },
    {
      Header: 'No',
      accessor: 'targetNo',
      resizable: false,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center number-text',
    },
    {
      Header: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
      accessor: 'targetName',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
    },
    {
      Header: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
      accessor: 'targetEmail',
      resizable: false,
      sortable: true,
      width: 199,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
      accessor: 'targetPhone',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Division_1', defaultMessage: '소속' }),
      accessor: 'targetDivision',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Position_1', defaultMessage: '직급' }),
      accessor: 'targetPosition',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Tag_2', defaultMessage: '태그명' }),
      accessor: 'tagList',
      resizable: false,
      sortable: true,
      width: 261,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td tag-cell',
      Cell: (props: { [key: string]: any }) => {
        const tagList = props.value;
        const printValue = <div className="text-align-center">-</div>;
        if (tagList) {
          const tagMap = tagList.map((tagData: any, index: number) => {
            return (
              <TagItem
                key={String(index)}
                tagItem={{
                  tagNo: tagData.tagNo,
                  tagName: tagData.tagName,
                  color: tagData.color,
                  // tagInitial: detailData.get('tagInitial')
                }}
                className="targetItem-tagItem"
                size="small"
              />
            );
          });
          return (
            <Popover
              placement="topLeft"
              content={
                <div className="target-tagPopup">
                  <div className="bold font-size-13">
                    <span>{formatMessage({ id: 'Tag_1', defaultMessage: '태그' })}</span>
                    <span className="dark-grey font-size-12 vertical-top">
                      {`${formatMessage({ id: 'Exam_25', defaultMessage: '총' })} ${
                        tagList.length
                      }${formatMessage({ id: 'StartExam_21', defaultMessage: '개' })})`}
                    </span>
                  </div>
                  <div className="cell-tag-inner">{tagMap}</div>
                </div>
              }
            >
              <div className="cell-tag-inner">{tagMap}</div>
            </Popover>
          );
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Login_10', defaultMessage: '등록한 계정' }),
      accessor: 'userEmail',
      resizable: false,
      sortable: true,
      width: 199,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
      Cell: (props: { [key: string]: any }) => {
        const { createUser } = props.row.original;
        let userName = '';

        if (createUser !== 9 && createUser !== 10) {
          userName = `${props.row.original.userName} (${props.row.original.userEmail})`;
        } else {
          userName = props.row.original.userEmail;
        }

        return userName;
      },
    },
    {
      Header: formatMessage({ id: 'Date_19', defaultMessage: '등록일' }),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
    {
      Header: formatMessage({ id: 'Date_21', defaultMessage: '마지막 수정일' }),
      accessor: 'modifyEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
  ];

  const columns: Array<any> = useMemo(() => baseColumns, [respData]);

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData}
        changeParam={changeParam}
        loading={loading}
        disableKey="isEnable"
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
